// @flow
import qs from 'qs';

export const getQueryParams = (location: {}) => {
    /* eslint-disable-next-line */
    let [questionMark, ...queryString] = location.search;
    const search = qs.parse(queryString.join(''));
    return search;
};

export const getQueryParamString = (queryParams: {}) => {
    let queryParamString = '';
    const keys = Object.keys(queryParams);
    keys.map(key => {
        const queryParam = queryParams[key];
        if (queryParam) {
            if (!queryParamString.length) {
                queryParamString += `?${key}=${queryParam}`;
            } else {
                queryParamString += `&${key}=${queryParam}`;
            }
        }
    });

    return queryParamString;
};
