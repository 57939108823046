import React from 'react';
import { Box, Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import HighlighterHeader, { HighlighterBackground } from './HighlighterHeader';
import PaymentSummary from './PaymentSummary';
import { UpgradePreview } from '../types';
import CardWithBoxShadow from '../../common/components/CardWithBoxShadow';
import Loading from '../../common/components/Loading';
import TertiaryButton from '../../common/components/TertiaryButton';
import PrimaryButton from '../../common/components/PrimaryButton';

export interface Props {
    maxAthleteCount: number;
    planPreview: UpgradePreview;
    isLoading: boolean;
}

export default function ReviewPurchasePage({ maxAthleteCount, planPreview, isLoading }: Props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box marginX={4}>
            <HighlighterHeader
                title="Review Purchase"
                highlighterBackground={HighlighterBackground.REVIEW_PURCHASE}
                width={350}
            />
            <Box display="flex" flex={1} justifyContent="center">
                <Grid display="flex" flex={1} container spacing={2} sx={{ maxWidth: 600 }}>
                    <Grid display="block" flex={1} container spacing={2}>
                        <CardWithBoxShadow>
                            <Box padding={2}>
                                <PaymentSummary
                                    maxAthleteCount={maxAthleteCount}
                                    planPreview={planPreview}
                                    showHeader={false}
                                />
                            </Box>
                            <Grid
                                container
                                display="flex"
                                flex={1}
                                spacing={2}
                                sx={{
                                    flexDirection: isSmallScreen ? 'column-reverse' : null,
                                    margin: '0 32px 32px'
                                }}
                            >
                                <Grid size={{ xs: 12, md: 5 }}>
                                    <TertiaryButton sx={{ width: isSmallScreen ? '100%' : null }}>Back</TertiaryButton>
                                </Grid>
                                <Grid size={{ xs: 12, md: 7 }}>
                                    <PrimaryButton sx={{ width: '100%' }}>Purchase</PrimaryButton>
                                </Grid>
                            </Grid>
                        </CardWithBoxShadow>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
