// @flow
import React from 'react';
import PrimaryButton from '../../common/components/PrimaryButton';
import useInviteCoachesFormManager from './hooks/useInviteCoachesFormManager';
import InviteCoachesModal from './InviteCoachesModal';

type Props = {
    buttonText: string,
    refreshList: () => {},
    disabled?: boolean
};

const ERROR_TEXT = 'Invalid email';

export default function InviteCoaches({
    buttonText,
    refreshList,
    disabled
}: Props) {
    const {
        isModalVisible,
        setIsModalVisible,
        emails,
        setEmails,
        emailsValidated,
        emailsErrorMessage,
        message,
        setMessage,
        showValidationErrors,
        isInProgress,
        inviteCoaches,
        inviteFormSuccessfulOrNotSubmitted
    } = useInviteCoachesFormManager(refreshList);

    const hasEmailsError = showValidationErrors && !emailsValidated;

    const emailsErrorText = inviteFormSuccessfulOrNotSubmitted
        ? emailsErrorMessage
        : ERROR_TEXT;

    return (
        <>
            <PrimaryButton
                disabled={disabled}
                onClick={() => setIsModalVisible(true)}
            >
                {buttonText}
            </PrimaryButton>
            <InviteCoachesModal
                isModalVisible={isModalVisible}
                inviteCoaches={inviteCoaches}
                handleClose={() => setIsModalVisible(false)}
                emails={emails}
                setEmails={setEmails}
                hasEmailsError={hasEmailsError}
                emailsErrorText={emailsErrorText}
                message={message}
                setMessage={setMessage}
                isInProgress={isInProgress}
            />
        </>
    );
}

InviteCoaches.defaultProps = {
    disabled: false
};
