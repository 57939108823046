import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PrimaryButton from './PrimaryButton';
import TertiaryButton from './TertiaryButton';
import useScreenDimensions from '../hooks/useScreenDimensions';
import { compareDeviceWidth } from '../utils/deviceSizes';

type Props = {
    title: string,
    subtext: string,
    isOpen: boolean,
    handleClose: () => void,
    proceed: () => void,
    proceedButtonText: string
};

export default function ConfirmDialog({ title, subtext, isOpen, handleClose, proceed, proceedButtonText }: Props) {
    const { width } = useScreenDimensions();
    const { isGreaterThan675 } = compareDeviceWidth(width);

    return (
        <Dialog
            fullScreen={!isGreaterThan675}
            data-testid="dialog"
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={isOpen}
        >
            <DialogTitle classes={{ root: 'failure-title' }}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText classes={{ root: 'failure-subtext' }}>{subtext}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
                <PrimaryButton onClick={proceed}>{proceedButtonText}</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}
