// @flow
import React, { useState } from 'react';
import Login from '../../account/components/Login';
import ForgotPassword from '../../account/components/ForgotPassword';
import CreateAthleteAccount from '../../account/components/CreateAthleteAccount';

type Props = {
    instructions?: string | null
};

export default function AssistantCoachLoginCreateAccount({
    instructions
}: Props) {
    const [showLogin, setShowLogin] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const toggleLogin = () => {
        setShowForgotPassword(false);
        setShowLogin(true);
    };

    const toggleForgotPassword = () => {
        setShowForgotPassword(true);
        setShowLogin(false);
    };

    const toggleCreateAccount = () => {
        setShowForgotPassword(false);
        setShowLogin(false);
    };

    if (showLogin) {
        return (
            <Login
                navigateToAccount={toggleCreateAccount}
                navigateToPasswordReset={toggleForgotPassword}
                onLogin={() => {}}
            />
        );
    }

    if (showForgotPassword) {
        return <ForgotPassword navigateToLogin={toggleLogin} />;
    }

    return (
        <CreateAthleteAccount
            navigateToLogin={toggleLogin}
            onAccountCreate={() => {}}
            instructions={
                instructions
                    ? instructions
                    : 'Please create your account to accept this assistant coach invitation'
            }
            style={instructions ? 'warning-text' : null}
        />
    );
}

AssistantCoachLoginCreateAccount.defaultProps = {
    instructions: null
};
