import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import CheckCircleIcon from '../assets/CheckCircleIcon';
import { PADDING_INCREMENT } from '../../account/constants';

const StyledBullet = styled(Box)({
    fontSize: 20,
    color: '#FFF',
    fontWeight: '500',
    textAlign: 'left',
    display: 'flex',
    flexDircetion: 'row'
});

const CheckMark = styled(CheckCircleIcon)({
    paddingRight: PADDING_INCREMENT,
    flexShrink: 0
});

interface Props {
    text: string;
}

export default function HighlightBullet({ text }: Props) {
    return (
        <StyledBullet>
            <CheckMark className="checkmark" />
            <Typography sx={{ textWrap: 'pretty' }}>{text}</Typography>
        </StyledBullet>
    );
}
