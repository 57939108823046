import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getInvoices from '../../api/getInvoices';

export type Invoice = {
    id: number;
    dateCreated: string;
    datePaid: string | null;
    amount: string;
    taxAmount: string;
    totalTransactionAmount: string;
    last4: string;
    isRefund: 0 | 1;
    note: string;
    status: string;
};

export default function useInvoices() {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const orgId = useSelector((state: any) => state.user.headCoach.orgId);

    useEffect(() => {
        const getInvoiceCall = async () => {
            const response = await getInvoices(orgId);
            setIsLoading(false);
            if (response && !response.status) {
                setInvoices(response);
            }
        };

        getInvoiceCall();
    }, [orgId]);

    return {
        invoices,
        isLoading
    };
}
