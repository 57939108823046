// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import Loading from '../../common/components/Loading';
import PlanSelectionWithPayment from './PlanSelectionWithPayment';
import AthleteCounts from './AthleteCounts';
import useSubscriptionPlansWithEmbeddedPayment from './hooks/useSubscriptionPlansWithEmbeddedPayment';
import SubscriptionPlanError from './SubscriptionPlanError';
import { navigateToCoachHeroic } from '../../state/navigation/navigation';
import Footer from '../../common/components/Footer';
import HighlighterHeader from './HighlighterHeader';

export default function SubscriptionPlansWithEmbeddedPayment() {
    const isHeadCoach = useSelector((state: any) => state.user.headCoach);

    const {
        isLoading,
        subscriptionOptions,
        currentPlan,
        selectedPlan,
        setSelectedPlan,
        errorLoadingOptions,
        setErrorLoadingOptions,
        fetchSubscriptionOptions
    } = useSubscriptionPlansWithEmbeddedPayment();

    if (!isHeadCoach) {
        navigateToCoachHeroic();
    }

    if (isLoading) {
        return <Loading />;
    }

    const { athleteCount, invitedAthleteCount, coachCount } = subscriptionOptions;

    return (
        <Box className="outer-container with-nav" display={'flex'} flexDirection={'column'}>
            <Container maxWidth="lg" sx={{ minHeight: '70vh' }}>
                <HighlighterHeader title="Plans for every coach" highlighterBackground="upgrade" width={400} />
                <Box className="current-plan-info">
                    <PlanSelectionWithPayment
                        subscriptionOptions={subscriptionOptions}
                        currentPlan={currentPlan}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        fetchSubscriptionOptions={fetchSubscriptionOptions}
                    />
                    <AthleteCounts
                        currentAthletes={athleteCount}
                        invitedAthletes={invitedAthleteCount}
                        coachCount={coachCount}
                    />
                </Box>
            </Container>
            <SubscriptionPlanError open={errorLoadingOptions} handleClose={() => setErrorLoadingOptions(false)} />
            <Footer />
        </Box>
    );
}
