import React from 'react';
import { useHistory } from 'react-router';
import { Card, CardHeader, CardActions, MenuItem, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FormSelectField from '../../common/components/FormSelectField';
import TertiaryButton from '../../common/components/TertiaryButton';
import usePlanSelection from './hooks/usePlanSelection';
import { COACH_LICENSE_TYPES } from '../constants';
import SecondaryButton from '../../common/components/SecondaryButton';
import { SubscriptionOptionsResponse, SubscriptionOption } from '../types';

interface Props {
    subscriptionOptions: SubscriptionOptionsResponse;
    currentPlan: SubscriptionOption;
    selectedPlan: SubscriptionOption;
    setSelectedPlan: (plan: SubscriptionOption) => void;
    handlePaymentPreview: (plan: SubscriptionOption) => void;
}

export default function PlanSelection({
    subscriptionOptions,
    currentPlan,
    selectedPlan,
    setSelectedPlan,
    handlePaymentPreview
}: Props) {
    const history = useHistory();
    const { menuOpen, setMenuOpen, isTrial, numDaysRemainingInTrial } = usePlanSelection();

    const {
        isMarketplaceCoach,
        isCancelPending,
        hasYearlyLicense,
        isLicenseOverdue,
        subscriptions,
        nextBillingDate
    } = subscriptionOptions;

    const { coachLicenseId, isUpgrade } = selectedPlan;

    const cancelChanges = () => {
        setSelectedPlan(currentPlan);
    };

    const generateButtonText = () => {
        let buttonText = 'Upgrade Plan';

        if (selectedPlan !== currentPlan) {
            buttonText = isUpgrade ? 'Upgrade' : 'Downgrade';
        }

        return buttonText;
    };

    const generatePlanOptions = () => {
        return (
            subscriptions?.length &&
            subscriptions.map(option => {
                const selected = coachLicenseId === option.coachLicenseId ? 'select-form-selected' : '';
                const disabled = option.isEnabled ? '' : 'option-disabled';

                return (
                    // @ts-ignore
                    <MenuItem key={option.coachLicenseId} value={option} style={{ flexDirection: 'row' }}>
                        <ListItemText
                            primary={option.title}
                            classes={{
                                primary: `${disabled} ${selected}`
                            }}
                        />
                        {menuOpen && coachLicenseId === option.coachLicenseId ? <CheckIcon /> : null}
                    </MenuItem>
                );
            })
        );
    };

    const isTrialOrBasic =
        currentPlan.coachLicenseId === COACH_LICENSE_TYPES.TRIAL ||
        currentPlan.coachLicenseId === COACH_LICENSE_TYPES.BASIC;

    const showCancelPlanButton = selectedPlan === currentPlan && !isTrialOrBasic && !isLicenseOverdue;

    const helperText =
        selectedPlan !== currentPlan ? `* ${selectedPlan.price} plus $9.99 per assistant coach (if applicable)` : '';

    const cardTitle = isTrial ? `You have ${numDaysRemainingInTrial} days remaining in your trial.` : 'Current Plan';

    return (
        <>
            <div className="current-plan">
                <Card
                    data-testid="card"
                    classes={{
                        root: 'card'
                    }}
                    elevation={2}
                >
                    <CardHeader
                        title={cardTitle}
                        classes={{
                            root: 'padding-desktop'
                        }}
                        slotProps={{ title: { variant: 'h5' } }}
                    />
                    <form className="subscription-plan-select">
                        <div>
                            {subscriptions?.length && (
                                <FormSelectField
                                    disabled={hasYearlyLicense || isLicenseOverdue}
                                    onClose={() => {
                                        setMenuOpen(false);
                                    }}
                                    onOpen={() => setMenuOpen(true)}
                                    open={menuOpen}
                                    required={true}
                                    value={selectedPlan}
                                    renderValue={(value: any) => <ListItemText primary={value.title} />}
                                    onChange={(event: React.ChangeEvent<{ value: SubscriptionOption }>) => {
                                        const option = event.target.value;
                                        if (option.isEnabled) {
                                            setSelectedPlan(option);
                                            setMenuOpen(false);
                                        }
                                    }}
                                    helperText={helperText}
                                >
                                    {generatePlanOptions()}
                                </FormSelectField>
                            )}
                        </div>
                    </form>
                    <CardActions
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            padding: '32px'
                        }}
                    >
                        <SecondaryButton
                            sx={{ flex: 1, marginRight: '8px' }}
                            disabled={hasYearlyLicense}
                            onClick={() =>
                                currentPlan === selectedPlan ? setMenuOpen(true) : handlePaymentPreview(selectedPlan)
                            }
                        >
                            {generateButtonText()}
                        </SecondaryButton>
                        {selectedPlan !== currentPlan ? (
                            <TertiaryButton sx={{ flex: 1, marginLeft: '8px' }} onClick={() => cancelChanges()}>
                                Cancel Changes
                            </TertiaryButton>
                        ) : null}
                        {showCancelPlanButton && (
                            <TertiaryButton
                                sx={{ flex: 1, marginLeft: '8px' }}
                                disabled={isCancelPending || isMarketplaceCoach}
                                onClick={() => {
                                    return history.push({
                                        pathname: '/subscription-plans/cancel',
                                        state: { nextBillingDate }
                                    });
                                }}
                            >
                                Cancel Plan
                            </TertiaryButton>
                        )}
                    </CardActions>
                </Card>
                {!isLicenseOverdue && hasYearlyLicense && (
                    <div className="sales-contact">
                        <p>
                            Please contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>{' '}
                            to upgrade your account.
                        </p>
                    </div>
                )}
                {isMarketplaceCoach && (
                    <div className="sales-contact">
                        <p>
                            Your account has active marketplace teams <br /> or programs. To cancel, contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>
                        </p>
                    </div>
                )}
                {!hasYearlyLicense && (
                    <div className="sales-contact">
                        <p>
                            Need more than 1000 athletes? <br />
                            Contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
}
