// @flow
import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function PrimaryButton(props: any) {
    const showActivityIndicator = props.showActivity ? (
        <CircularProgress classes={{ root: 'circular-progress-button' }} size={18} color="secondary" />
    ) : null;

    return (
        <Button
            href={props.href}
            target={props.target}
            rel="noopener noreferrer"
            variant="contained"
            color="primary"
            onClick={props.onClick}
            disabled={props.disabled}
            classes={props.classes}
            data-testid={props['data-testid']}
            {...props}
        >
            {props.children}
            {showActivityIndicator}
        </Button>
    );
}
