import { unauthenticatedFetch } from "../../api/request";

// @flow
const grantParentGuardianConsent = async (emailCommunicationKey: string, firstName: string, lastName: string, relationship: string) => {
    const path = `/public/v5/parentGuardianConsent/${emailCommunicationKey}/approve`;
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify({
            firstName,
            lastName,
            relationship
        }),
        headers: { 'Content-Type': 'application/json' }
    };
    return unauthenticatedFetch(path, requestOptions);
};

export default grantParentGuardianConsent;
