// @flow
import { authenticatedFetch } from '../../api/request';

const createZapierToken = async () => {
    const path = '/v5/integrations/zapier/manage';
    const requestOptions = {
        method: 'POST'
    };
    return authenticatedFetch(path, requestOptions);
};

export default createZapierToken;
