import React from 'react';
import { Box, Grid2, useMediaQuery, useTheme } from '@mui/material';
import HighlightBullet from './HighlightBullet';
import { coachPlanHighlights, coachPlanHighlightsSingleColumn } from '../../account/constants';

export default function HighlightsBullets() {
    const theme = useTheme();
    const isTwoColumn = useMediaQuery(theme.breakpoints.up('md'));
    const highlights = isTwoColumn ? coachPlanHighlights : coachPlanHighlightsSingleColumn;

    return (
        <Box justifyContent={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Grid2 container rowSpacing={2} columnSpacing={4} maxWidth={1060}>
                {highlights.map(highlight => (
                    <Grid2 size={{ xs: 12, md: 6 }} key={highlight}>
                        <HighlightBullet text={highlight} />
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
}
