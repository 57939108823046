import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { rollbar } from './rollbarConfig';
import RouteFactory from './routing/RouteFactory';
import { getHistory, getStore } from './state/store';

import Header from './common/components/Header';
import NavBar from './common/components/NavBar';
import defaultTheme from './DefaultTheme';
import './index.scss';
import Config from './api/config';
import Maintenance from './Maintenance';
import { unauthenticatedFetch } from './api/request';

(async () => {
    const response = await unauthenticatedFetch(Config.API);
    if (response.status === 503) {
        return ReactDOM.render(<Maintenance />, document.getElementById('root'));
    }

    ReactDOM.render(
        <React.StrictMode>
            <RollbarProvider config={rollbar}>
                <ErrorBoundary>
                    <Provider store={getStore()}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={defaultTheme}>
                                <ConnectedRouter history={getHistory()}>
                                    <Header />
                                    <NavBar />
                                    {RouteFactory()}
                                </ConnectedRouter>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </Provider>
                </ErrorBoundary>
            </RollbarProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();
