// @flow
import React from 'react';

type Props = {
    firstColumn: React.ReactElement,
    secondColumn: React.ReactElement,
    thirdColumn: React.ReactElement,
    fourthColumn: React.ReactElement,
    actionColumn: React.ReactElement,
    rowStyles?: {}
};

const CoachSettingsRowTemplate = ({
    firstColumn,
    secondColumn,
    thirdColumn,
    fourthColumn,
    actionColumn,
    rowStyles
}: Props) => {
    return (
        <li className="settings-row" style={rowStyles}>
            <div className="settings-left">
                <div className="description-column expand">{firstColumn}</div>
                <div className="description-column">{secondColumn}</div>
                <div className="description-column">{thirdColumn}</div>
                <div className="description-column">{fourthColumn}</div>
            </div>
            <div className="action-column expand">{actionColumn}</div>
        </li>
    );
};

CoachSettingsRowTemplate.defaultProps = {
    rowStyles: {}
};

export default CoachSettingsRowTemplate;
