// @flow
import React from 'react';
import { Container } from '@mui/material';
import CoachWhistle from '../assets/CoachWhistle';

type Props = {
    action: React.ReactElement
};

export default function AssistantCoachEmptyState({ action }: Props) {
    return (
        <Container
            data-testid="empty-state"
            maxWidth="lg"
            disableGutters
            classes={{ root: 'empty-state' }}
        >
            <div className="flex-center">
                <div className="flex-center">
                    <CoachWhistle />
                    <h2 className="empty-title">Add Coaches</h2>
                </div>
                <div className="info-card">
                    <h5 className="info-title">Add Assistant Coaches</h5>
                    <ul className="info-list">
                        <li>Invite other coaches to access your account</li>
                        <li>Each assistant coach added is $9.99 / mo</li>
                    </ul>
                </div>
                <div>{action}</div>
            </div>
        </Container>
    );
}
