import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { navigate } from '../../../state/navigation/navigation';
import ACTIONS from '../../../state/authentication/constants';
import authenticateTH from '../../api/authenticateTH';
import fetchAuthenticatedUser from '../../../state/api/fetchAuthenticatedUser';
import getHeadCoach from '../../../state/api/getHeadCoach';
import { getCookie, setCookie, removeCookie } from '../../../state/utils/cookie';
import CONFIG from '../../../api/config';
import { rollbarSetUserContext } from '../../../rollbarConfig';
import getFeaturesForUserId from '../../api/getFeaturesForUserId';

export default function useAuthentication() {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const stateToken = useSelector((state: any) => state.authentication?.token);

    const [authenticating, setAuthenticating] = useState(false);
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    const setCurrentLoggedInUser = (user: any) => {
        dispatch({
            type: ACTIONS.LOGIN_USER,
            user
        });
    };

    const setHeadCoach = (headCoach: any) => {
        dispatch({
            type: ACTIONS.SET_HEAD_COACH,
            headCoach
        });
    };

    const checkHeadCoachRole = async () => {
        const headCoach = await getHeadCoach();

        if (headCoach.id) {
            return setHeadCoach(headCoach);
        }

        return;
    };

    const getCurrentLoggedInUser = async () => {
        const user = await fetchAuthenticatedUser();

        if (user.status) {
            routeToLogin();
            return;
        }

        setCurrentLoggedInUser(user);
        await checkHeadCoachRole();
        dispatch({
            type: ACTIONS.AUTHENTICATION_SUCCESS
        });

        rollbarSetUserContext(user.id);

        const featuresResponse = await getFeaturesForUserId(user.id);
        dispatch({
            type: ACTIONS.FEATURES_SET,
            features: featuresResponse?.features ?? []
        });

        return user;
    };

    const logoutUser = () => {
        purge();
        return;
    };

    const loginUser = async (token: string) => {
        if (!token || token === 'null') {
            return routeToLogin();
        }

        setCookie(CONFIG.SESSION_NAME, token);
        return await authenticateByToken(token);
    };

    const routeToLogin = () => {
        purge();
        navigate(`/login${search}`);
    };

    const authenticateUser = async (email: string, password: string) => {
        setAuthenticated(null);
        setAuthenticating(true);
        const response = await authenticateTH(email, password);

        if (!response.session_id) {
            purge();
            setAuthenticated(false);
            setAuthenticating(false);
            return;
        }

        await loginUser(response.session_id);
        setAuthenticated(true);
        setAuthenticating(false);

        return;
    };

    const resetAuthenticationState = useCallback(() => {
        setAuthenticating(false);
        setAuthenticated(null);
    }, [setAuthenticated, setAuthenticating]);

    const purge = () => {
        dispatch({
            type: ACTIONS.LOGOUT_USER
        });
        dispatch({
            type: ACTIONS.AUTHENTICATION_FAIL
        });
        removeCookie(CONFIG.SESSION_NAME);
    };

    const authenticateByToken = async (token: string) => {
        if (token === 'null') {
            routeToLogin();
            return;
        }

        setSessionToken(token);
        return await getCurrentLoggedInUser();
    };

    const checkToken = () => {
        const cookie = getCookie(CONFIG.SESSION_NAME);

        if (!cookie || cookie === 'null') {
            return routeToLogin();
        } else if (cookie === stateToken) {
            return getCurrentLoggedInUser();
        }

        return authenticateByToken(cookie);
    };

    const checkTokenPublic = () => {
        const cookie = getCookie(CONFIG.SESSION_NAME);

        if (!cookie || cookie === 'null') {
            return;
        } else {
            return authenticateByToken(cookie);
        }
    };

    const setSessionToken = (token: string) => {
        dispatch({
            type: ACTIONS.AUTHENTICATION_SET_SESSION_TOKEN,
            token
        });
    };

    return {
        authenticating,
        authenticated,
        authenticateUser,
        resetAuthenticationState,
        checkTokenPublic,
        checkToken,
        logoutUser,
        loginUser
    };
}
