import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Link } from '@mui/material';

interface Props {
    hasAgreed: boolean;
    hasAgreedError: boolean;
    onChange: () => void;
}

export default function TermsOfUseCheckbox({ hasAgreed, hasAgreedError, onChange }: Props) {
    return (
        <Box className="terms-checkbox-container">
            <FormControl error={hasAgreedError}>
                <FormControlLabel
                    required
                    control={<Checkbox checked={hasAgreed} onChange={onChange} />}
                    label={
                        <Box className="terms-container narrow">
                            <Box>
                                By checking this box, you agree to the &nbsp;
                                <Link
                                    href="https://legal.trainheroic.com/terms-of-use.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms-link"
                                >
                                    Terms of Use
                                </Link>{' '}
                                and{' '}
                                <Link
                                    href="https://legal.trainheroic.com/privacy-policy.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms-link"
                                >
                                    Privacy&nbsp;Policy
                                </Link>
                                .
                            </Box>
                        </Box>
                    }
                />
                {hasAgreedError && <FormHelperText error>Missing required entry</FormHelperText>}
            </FormControl>
        </Box>
    );
}
