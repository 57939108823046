// @flow
import React from 'react';
import { Container, CircularProgress } from '@mui/material';

export default function Loading() {
    return (
        <Container
            data-testid="loading"
            maxWidth="lg"
            disableGutters
            classes={{ root: 'loading-container' }}
        >
            <CircularProgress
                data-testid="circularProgress"
                size={48}
                color="inherit"
            />
            <div className="loading-text">Loading</div>
        </Container>
    );
}
