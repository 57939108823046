

// @flow
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

interface Props {
    hasSigned: bool;
    hasSignedError: bool;
    onChange: () => void;
}

export default function DigitalSignatureCheckbox({hasSigned, hasSignedError, onChange}: Props) {
       return (
        <div className="terms-checkbox-container">
            <FormControl error={hasSignedError}>
                <FormControlLabel
                    required
                    control={<Checkbox checked={hasSigned} onChange={onChange} />}
                    label={<div className="terms-container narrow body1">
                        <span>
                            Clicking this box acts as my digital signature
                        </span>
                    </div>}  
                />
                {hasSignedError && <FormHelperText error >Please check the box to continue</FormHelperText>}
            </FormControl>
        </div>
    );
}
