// @flow
import { authenticatedFetch } from '../../api/request';

const deleteZapierToken = async (zapierToken) => {
    const path = `/v5/integrations/zapier/manage/${zapierToken.id}`;
    const requestOptions = {
        method: 'DELETE'
    };
    return authenticatedFetch(path, requestOptions);
};

export default deleteZapierToken;
