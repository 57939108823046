// @flow
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

type Props = {
    isOpen: boolean,
};

export default function AlreadyHasConsentAlert({ isOpen }: Props) {
    const redirectToTrainHeroic = () => {
        window.location.replace('http://www.trainheroic.com');
    };

    return (
        <div>
            <Dialog data-testid="dialog" open={isOpen} onClose={redirectToTrainHeroic}>
                <DialogTitle>Parent or Legal Guardian Consent Request is Invalid</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Either parent or legal guardian consent has been already been granted, or the request for consent has been revoked.
                    </DialogContentText>
                    <DialogContentText>
                        You can{' '}
                        <a
                            href="https://support.trainheroic.com/hc/en-us/requests/new"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="terms-link"
                        >
                            Contact&nbsp;Customer&nbsp;Support
                        </a>
                        {' '}with any questions.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={redirectToTrainHeroic} color="primary" variant="contained">
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
