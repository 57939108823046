import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getReceipt from '../../api/getReceipt';

export type Receipt = {
    cardType: string;
    coachLicenseCost: string;
    customerName: string;
    email: string;
    last4Digits: string;
    numberOfAssistantCoaches: number;
    org: string;
    product: string;
    tax: string;
    totalAssistantCoachLicenseCost: string;
    totalCost: string;
    transactionDate: string;
    userOrgName: string | null;
};

export default function useReceipt(transactionId?: string) {
    const [receipt, setReceipt] = useState(null);

    const orgId = useSelector((state: any) => state.user.headCoach.orgId);

    useEffect(() => {
        if (!transactionId) {
            return;
        }

        const getReceiptCall = async () => {
            const response = await getReceipt(orgId, transactionId);

            if (response) {
                setReceipt(response);
            }
        };

        getReceiptCall();
    }, [orgId, transactionId, setReceipt]);

    return receipt;
}
