// @flow
export const getCookie = cname => {
    let name = `${cname}=`,
        ca = document.cookie.split(';'),
        i,
        c,
        ca_length = ca.length;
    for (i = 0; i < ca_length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const setCookie = (sessionName, sessionToken) => {
    const domain = 'trainheroic.com';
    const expirationInSeconds = 60 * 60 * 24;

    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const expirationDate = new Date(currentTime + 1000 * expirationInSeconds);

    document.cookie = `${sessionName}=${sessionToken};domain=${domain};expires=${expirationDate.toGMTString()};path=/`;
};

export const removeCookie = sessionName => {
    const domain = 'trainheroic.com';
    const expirationInSeconds = 60 * 60 * 24;

    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const expirationDate = new Date(currentTime + 1000 * expirationInSeconds);

    document.cookie = `${sessionName}=null;domain=${domain};expires=${expirationDate.toGMTString()};path=/`;
};
