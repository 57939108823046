// @flow

import { unauthenticatedFetch } from '../../api/request';

type Athlete = {
    full_name: string,
    email: string,
    password: string
};

const createAthleteAccount = async (user: Athlete) => {
    const path = '/public/v5/users/createAthleteAccount';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: { 'Content-Type': 'application/json' }
    };
    return unauthenticatedFetch(path, requestOptions);
};

export default createAthleteAccount;
