// @flow
import React from 'react';
import { TextField } from '@mui/material';

export default function FormTextField(props: any) {
    return (
        <TextField
            value={props.value}
            onKeyDown={props.onKeyDown}
            onChange={props.onChange}
            data-testid="textField"
            required={props.required}
            className="form-textfield"
            type={props.type}
            label={props.label}
            size="small"
            error={props.error}
            disabled={props.disabled}
            helperText={props.helperText}
            InputProps={props.InputProps}
            inputProps={props.inputProps}
            InputLabelProps={{
                classes: {
                    root: 'input',
                    asterisk: 'label-asterisk',
                    focused: 'input-focus'
                }
            }}
            variant={props.variant}
            style={
                props.disabled
                    ? {
                          backgroundColor: 'rgba(5, 3, 16, 0.06)'
                      }
                    : {}
            }
            slotProps={props.slotProps}
            color={props.color}
        />
    );
}
