import { useState } from 'react';

export default function useStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Choose Plan', 'Payment', 'Confirm'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return {
        steps,
        activeStep,
        handleNext,
        handleBack,
        handleReset
    };
}
