// @flow
import { authenticatedFetch } from '../../api/request';

const fetchZapierTokens = async () => {
    const path = '/v5/integrations/zapier/manage';
    const requestOptions = {
        method: 'GET'
    };
    return authenticatedFetch(path, requestOptions);
};

export default fetchZapierTokens;
