import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import Loading from '../../common/components/Loading';
import PlanSelection from './PlanSelection';
import AthleteCounts from './AthleteCounts';
import SubscriptionPlanError from './SubscriptionPlanError';
import { navigateToCoachHeroic } from '../../state/navigation/navigation';
import Footer from '../../common/components/Footer';
import HighlighterHeader, { HighlighterBackground } from './HighlighterHeader';
import { SubscriptionOption, SubscriptionOptionsResponse } from '../types';

interface Props {
    handlePaymentPreview: (plan: SubscriptionOption) => void;
    subscriptionOptions: SubscriptionOptionsResponse;
    currentPlan: SubscriptionOption;
    selectedPlan: SubscriptionOption;
    setSelectedPlan: (plan: SubscriptionOption) => void;
    hasErrorLoadingOptions: boolean;
    setHasErrorLoadingOptions: (hasError: boolean) => void;
    isLoading: boolean;
}

export default function PlanSelectionPage({
    handlePaymentPreview,
    subscriptionOptions,
    currentPlan,
    selectedPlan,
    setSelectedPlan,
    hasErrorLoadingOptions,
    setHasErrorLoadingOptions,
    isLoading
}: Props) {
    const isHeadCoach = useSelector((state: any) => state.user.headCoach);

    if (!isHeadCoach) {
        navigateToCoachHeroic();
    }

    if (isLoading) {
        return <Loading />;
    }

    const { athleteCount, invitedAthleteCount, coachCount } = subscriptionOptions;

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Container maxWidth="lg" sx={{ minHeight: '70vh' }}>
                    <HighlighterHeader
                        title="Plans for every coach"
                        highlighterBackground={HighlighterBackground.UPGRADE}
                        width={400}
                    />
                    <Box className="current-plan-info">
                        <PlanSelection
                            subscriptionOptions={subscriptionOptions}
                            currentPlan={currentPlan}
                            selectedPlan={selectedPlan}
                            handlePaymentPreview={handlePaymentPreview}
                            setSelectedPlan={setSelectedPlan}
                        />
                        <AthleteCounts
                            currentAthletes={athleteCount}
                            invitedAthletes={invitedAthleteCount}
                            coachCount={coachCount}
                        />
                    </Box>
                </Container>
                <Footer />
            </Box>
            <SubscriptionPlanError open={hasErrorLoadingOptions} handleClose={() => setHasErrorLoadingOptions(false)} />
        </>
    );
}
