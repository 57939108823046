import React from 'react';

interface Props {
    className?: string;
    height?: number;
}

export default function BlackTHLogo({ className, height }: Props) {
    return (
        <svg
            className={className}
            width={height ?? '32px'}
            height={height ?? '32px'}
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>Logos / TH / TH Black</title>
            <g id="Logos-/-TH-/-TH-Black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                    d="M47.0150375,0 L32.426663,0 L29.8883126,14.4123822 C29.8544679,14.6042237 29.6888767,14.7447204 29.4940634,14.7468666 L22.3113985,14.8264429 C22.0578111,14.8292495 21.8641535,14.6009218 21.9080691,14.3512967 L24.194483,1.36947101 C24.3202862,0.654935681 23.7706817,0 23.045085,0 L8.45439912,0 L0,48 L15.0011144,48 C15.568219,48 16.053271,47.5928734 16.1516682,47.0345188 L18.706198,32.531664 C18.7656325,32.1942079 18.6744996,31.8476715 18.456738,31.5830226 L12.581468,24.4414666 C12.4365137,24.2653092 12.5618216,24 12.7899842,24 L34.7977712,24 C35.5243585,24 36.0747885,24.6559263 35.9488203,25.3714522 L34.9511454,31.0342437 C34.8527482,31.5927633 34.3675311,31.9998899 33.8004265,31.9998899 L27.1302194,31.9998899 C26.9337552,31.9998899 26.7655225,32.141047 26.7315127,32.3345395 L23.9722639,48 L38.8586366,48 C39.4919447,48 40.0337896,47.5451606 40.1437436,46.9214281 L48.1664167,1.37194744 C48.29255,0.656091353 47.7417899,0 47.0150375,0"
                    id="Fill-1"
                    fill="#050310"
                ></path>
            </g>
        </svg>
    );
}
