import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import getSubscriptionOptions from '../../api/getSubscriptionOptions';
import { SubscriptionOption, SubscriptionOptionsResponse } from '../../types';

const INITIAL_STATE: SubscriptionOptionsResponse = {
    athleteCount: 0,
    invitedAthleteCount: 0,
    coachCount: 0,
    hasPayingSubscription: false,
    hasYearlyLicense: false,
    isLicenseOverdue: false,
    nextBillingDate: '',
    isCancelPending: false,
    isMarketplaceCoach: false,
    subscriptions: []
};

export const DEFAULT_STATE: SubscriptionOption = {
    coachLicenseId: 15,
    isCurrentSubscription: true,
    isEnabled: true,
    title: 'Cancelled or Inactive Account',
    maxAthletes: 0,
    price: '',
    isUpgrade: false,
    proratedAmount: ''
};

export default function useSubscriptionOptions() {
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionOptions, setSubscriptionOptions] = useState(INITIAL_STATE);

    const [currentPlan, setCurrentPlan] = useState(DEFAULT_STATE);
    const [selectedPlan, setSelectedPlan] = useState(DEFAULT_STATE);

    const [hasErrorLoadingOptions, setHasErrorLoadingOptions] = useState(false);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const fetchSubscriptionOptions = useCallback(async () => {
        if (!orgId) {
            return;
        }

        setIsLoading(true);

        const response = await getSubscriptionOptions(orgId);

        if (response.status) {
            // error
            setHasErrorLoadingOptions(true);
        }

        const currentPlanFromResponse = response?.subscriptions.filter((plan: SubscriptionOption) => plan.isCurrentSubscription);

        if (currentPlanFromResponse.length > 0) {
            setCurrentPlan(currentPlanFromResponse[0]);
            setSelectedPlan(currentPlanFromResponse[0]);
            setSubscriptionOptions(response);
        } else {
            const updatedOptions = [DEFAULT_STATE, ...response.subscriptions];
            setSubscriptionOptions({
                ...response,
                subscriptions: updatedOptions
            });
        }

        setIsLoading(false);
    }, [orgId]);

    useEffect(() => {
        fetchSubscriptionOptions();
    }, [fetchSubscriptionOptions, orgId]);

    return {
        isLoading,
        subscriptionOptions,
        currentPlan,
        setCurrentPlan,
        selectedPlan,
        setSelectedPlan,
        hasErrorLoadingOptions,
        setHasErrorLoadingOptions
    };
}
