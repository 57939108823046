// @flow
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import Loading from '../../common/components/Loading';
import AssistantCoachEmptyState from './AssistantCoachEmptyState';
import AssistantCoaches from './AssistantCoaches';
import InviteCoaches from './InviteCoaches';
import InviteResentModal from './InviteResentModal';
import useAssistantCoachSettings from './hooks/useAssistantCoachSettings';
import { navigateToCoachHeroic } from '../../state/navigation/navigation';
import { COACH_LICENSE_TYPES } from '../../planManagement/constants';

export default function AssistantCoachSettings() {
    const isHeadCoach = useSelector((state: any) => state.user.headCoach);
    const coachLicenseId = useSelector(
        (state: any) => state.user.headCoach?.coachLicenseId
    );

    const nextBillingDate = useSelector((state: any) => {
        const date = moment(state.user.headCoach?.nextBillingDate);
        return date.isValid() ? date.format('MMMM Do, YYYY') : null;
    });

    const {
        isLoading,
        coachList,
        hasAvailableLicenses,
        removeCoach,
        resendInvite,
        deleteInvite,
        fetchCoachList,
        isOpen,
        setIsOpen,
        modalTitle,
        modalSubtext
    } = useAssistantCoachSettings();

    if (!isHeadCoach) {
        return navigateToCoachHeroic();
    }

    if (isLoading) {
        return <Loading />;
    }

    const isBasicUser = coachLicenseId && coachLicenseId === COACH_LICENSE_TYPES.BASIC;

    if (!isLoading && coachList.length === 0) {
        return (
            <AssistantCoachEmptyState
                action={
                    <InviteCoaches
                        disabled={isBasicUser}
                        buttonText="Add Assistant Coach"
                        refreshList={fetchCoachList}
                    />
                }
            />
        );
    }

    const helperText = hasAvailableLicenses && nextBillingDate
        ? `Available paid licenses will be canceled ${nextBillingDate}.`
        : null;

    return (
        <div className="outer-container with-nav">
            <Container maxWidth="lg">
                <div className="management-header">
                    <header>
                        <h2 className="title">Assistant Coaches</h2>
                    </header>
                    <div className="invite-coaches">
                        <InviteCoaches
                            disabled={isBasicUser}
                            buttonText="Invite Coaches"
                            refreshList={fetchCoachList}
                        />
                    </div>
                </div>
                <AssistantCoaches
                    coachList={coachList}
                    removeCoach={removeCoach}
                    resendInvite={resendInvite}
                    fetchCoachList={fetchCoachList}
                    deleteInvite={deleteInvite}
                />
                <div className="helper-text">{helperText}</div>
            </Container>
            <InviteResentModal
                title={modalTitle}
                subtext={modalSubtext}
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
            />
        </div>
    );
}
