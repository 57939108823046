// @flow
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { withStyles } from '@mui/styles';
import { IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import useScreenDimensions from '../hooks/useScreenDimensions';
import useAuthentication from '../../account/components/hooks/useAuthentication';
import { compareDeviceWidth } from '../utils/deviceSizes';
import {
    navigate,
    navigateToCoachHeroic
} from '../../state/navigation/navigation';
import { SETTINGS_TABS } from './NavBar';
import Routes from '../../routing/routes';

export default function MobileNav() {
    const { pathname } = useLocation();
    const { width } = useScreenDimensions();
    const { isGreaterThan675 } = compareDeviceWidth(width);

    const [anchorElement, setAnchorElement] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const hideMenu = () => {
        const hiddenPaths = [Routes.ACCEPT_ASSISTANT_COACH.path];
        return hiddenPaths.indexOf(pathname) > -1;
    };

    const openMenu = event => {
        setAnchorElement(event.currentTarget);
        setMenuIsOpen(true);
    };

    const closeMenu = () => {
        setAnchorElement(null);
        setMenuIsOpen(false);
    };

    if (isGreaterThan675 || hideMenu()) {
        return null;
    }

    return (
        <div data-testid="mobile-nav">
            <IconButton
                onClick={openMenu}
                aria-controls="customized-menu"
                aria-haspopup="true"
                size="large">
                <MenuIcon size={24} classes={{ root: 'mobile-menu-home' }} />
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorElement}
                keepMounted
                open={Boolean(anchorElement)}
                onClose={closeMenu}
            >
                <MenuItem key="home" onClick={navigateToCoachHeroic}>
                    <ListItemText
                        primary="Home"
                        classes={{ primary: 'drop-down-text' }}
                    />
                </MenuItem>
                {SETTINGS_TABS.map((tab, index) => (
                    <MenuItem
                        key={tab.name}
                        onClick={() => {
                            navigate(tab.path);
                            closeMenu();
                        }}
                    >
                        <ListItemText
                            primary={tab.name}
                            classes={{ primary: 'drop-down-text' }}
                        />
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    },
    list: {
        padding: 0
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
        }}
        {...props}
    />
));
