// @flow
const ACTIONS = {
    AUTHENTICATION_FAIL: 'authentication-fail',
    AUTHENTICATION_SET_SESSION_TOKEN: 'authentication-set-session-token',
    AUTHENTICATION_SUCCESS: 'authentication-success',
    LOGIN_USER: 'login-user',
    LOGOUT_USER: 'logout-user',
    SET_HEAD_COACH: 'set-head-coach',
    FEATURES_SET: 'features-set'
};

export default ACTIONS;
