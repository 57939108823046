// @flow
import { useState } from 'react';
import createCoachAccount from '../../api/createCoachAccount';
import createAthleteAccount from '../../api/createAthleteAccount';
import useAuthentication from './useAuthentication';

type CoachUser = {
    fullName: string,
    email: string,
    password: string,
    phoneNumber: string,
    country: string,
    organization: string,
    expectedAccountSize: string
};

export default function useAccountCreation() {
    const [creatingInProcess, setCreatingInProcess] = useState(false);
    const [creatingComplete, setCreatingComplete] = useState(null);
    const [serverError, setServerError] = useState(false);
    const [serverErrorMessages, setServerErrorMessages] = useState({});

    const { loginUser } = useAuthentication();

    const createNewAthleteAccount = async (name: string, email: string, password: string, token: string) => {
        setCreatingComplete(null);
        setCreatingInProcess(true);

        const newUser = {
            accountCreationLocation: 'account',
            full_name: name,
            email,
            password,
            token
        };

        const response = await createAthleteAccount(newUser);
        handleCreateAccountResponse(response);
    };

    const createNewCoachAccount = async (coachUser: CoachUser) => {
        setCreatingComplete(null);
        setCreatingInProcess(true);

        const response = await createCoachAccount(coachUser);
        handleCreateAccountResponse(response);
        return response;
    };

    const handleCreateAccountResponse = (response: any) => {
        if (response.session_id) {
            handleSuccessfulResponse(response);
        } else if (Array.isArray(response)) {
            handleUserInputError(response);
        } else {
            handleServerError();
        }

        setCreatingInProcess(false);
    };

    const handleSuccessfulResponse = async (response: any) => {
        await loginUser(response.session_id);
        setCreatingComplete(true);
    };

    const handleUserInputError = (response: Array) => {
        setCreatingComplete(false);
        const errorsToDisplay = convertServerResponse(response);
        setServerErrorMessages(errorsToDisplay);
    };

    const handleServerError = () => {
        setServerError(true);
        setCreatingComplete(false);
    };

    const convertServerResponse = (errorMessages: Array) =>
        errorMessages.reduce((acc, error) => {
            const errorKeys = Object.keys(error);
            const errorValues = Object.values(error);

            if (errorKeys.length) {
                if (!acc[errorKeys[0]]) {
                    acc[errorKeys[0]] = errorValues[0];
                }
            }
            return acc;
        }, {});

    return {
        createNewAthleteAccount,
        createNewCoachAccount,
        creatingInProcess,
        creatingComplete,
        setCreatingInProcess,
        setCreatingComplete,
        serverErrorMessages,
        serverError,
        setServerError
    };
}
