import React from 'react';
import DropIn from 'braintree-web-drop-in-react';
import { CardContent, CircularProgress } from '@mui/material';
import { BRAINTREE_OPTIONS } from '../../planManagement/constants';

type Props = {
    isLoadingInstance: boolean,
    clientToken: string | null,
    setBraintreeInstance: (instance: any) => void;
};

export default function BraintreeCard({
    isLoadingInstance,
    clientToken,
    setBraintreeInstance
}: Props) {
    return (
        <>
            <div className="card-content padding-desktop">
                {isLoadingInstance ? (
                    <CardContent
                        classes={{
                            root: 'padding-desktop card-content'
                        }}
                    >
                        <div className="order-summary card-loading-container">
                            <CircularProgress
                                data-testid="circularProgress"
                                size={32}
                                color="inherit"
                            />
                        </div>
                    </CardContent>
                ) : (
                    <div data-testid="dropIn">
                        <DropIn
                            options={{
                                ...BRAINTREE_OPTIONS,
                                authorization: clientToken
                            }}
                            onInstance={instance =>
                                setBraintreeInstance(instance)
                            }
                        />
                    </div>
                )}
            </div>
        </>
    );
}
