// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import PartnerLogoFooter from './PartnerLogoFooter';
import config from '../../api/config';
import CoachTrialForm from './CoachTrialForm';
import useScreenDimensions from '../../common/hooks/useScreenDimensions';
import BlackTHTrainHeroicLogo from '../../common/assets/BlackTHTrainHeroicLogo';

const testimonialDescription =
    'For years, I was the classic gym goer with no sustained plan or goals. The app made it easy to follow training sessions. TrainHeroic has been priceless when it comes to my training.';

const testimonialAuthor = 'Chris Sergison: physical therapist, coach, avid athlete, new father';

const MOBILE_DEVICE_WIDTH = 768;
const MOBILE_WELCOME_URL = 'https://www.trainheroic.com/welcome/';

export default function CreateAccount() {
    const isAuthenticated = useSelector((state: any) => state.authentication?.isAuthenticated);

    const { width } = useScreenDimensions();

    if (isAuthenticated) {
        if (width > MOBILE_DEVICE_WIDTH) {
            window.location.replace(config.URL_COACHAPP);
        } else {
            window.location.replace(MOBILE_WELCOME_URL);
        }
        return null;
    }

    return (
        <Box className="outer-container signup-form">
            <Box className="signup-header">
                <BlackTHTrainHeroicLogo className={undefined} />
            </Box>
            <section data-testid="section" className="testimonial-section">
                <Box className="side-by-side-layout">
                    <Container maxWidth="lg" classes={{ root: 'sign-up-form' }}>
                        <CoachTrialForm />
                    </Container>
                    <Box className="testimonial-container">
                        <Box className="spacer" />
                        <Box className="section-card testimonial-card">
                            <img
                                className="quote-icon"
                                height={50}
                                width={50}
                                src="https://static.trainheroic.com/icons/QuoteBlue.png"
                            />
                            <h4 className="testimonial-content">{testimonialDescription}</h4>
                            <h6 className="testimonial-author">{testimonialAuthor}</h6>
                            <p>Verified TrainHeroic Athlete</p>
                        </Box>
                    </Box>
                </Box>
                <PartnerLogoFooter />
            </section>
        </Box>
    );
}
