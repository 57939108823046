// @flow
import ACTIONS from '../authentication/constants';

const user = (state: any = { loggedInUser: false, headCoach: false }, action: any = {}) => {
    switch (action.type) {
        case ACTIONS.LOGIN_USER:
            return {
                ...state,
                loggedInUser: action.user
            };
        case ACTIONS.LOGOUT_USER:
            return {
                ...state,
                loggedInUser: false
            };
        case ACTIONS.SET_HEAD_COACH:
            return {
                ...state,
                headCoach: action.headCoach
            };
        default:
            return state;
    }
};

export default user;
