import React, { useState } from 'react';
import { Box, CardHeader, Grid2 as Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import HighlighterHeader, { HighlighterBackground } from './HighlighterHeader';
import BraintreeDropInCard from './BraintreeDropInCard';
import PaymentSummary from './PaymentSummary';
import { UpgradePreview } from '../types';
import CardWithBoxShadow from '../../common/components/CardWithBoxShadow';
import SecondaryButton from '../../common/components/SecondaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';

interface Props {
    maxAthleteCount: number;
    planPreview: UpgradePreview;
    clientToken: string | null;
    isLoading: boolean;
    setBraintreeInstance: (instance: any) => void;
    handleReviewPurchase: () => Promise<void>;
    handleBack: () => void;
    isCreatePaymentMethodLoading: boolean;
}

export default function PaymentPage({
    maxAthleteCount,
    planPreview,
    handleReviewPurchase,
    clientToken,
    isLoading,
    setBraintreeInstance,
    handleBack,
    isCreatePaymentMethodLoading
}: Props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box marginX={4}>
            <HighlighterHeader title="Payment" highlighterBackground={HighlighterBackground.PAYMENT} width={200} />
            <Box display="flex" flex={1} justifyContent="center">
                <Grid
                    display="flex"
                    flex={1}
                    container
                    spacing={2}
                    sx={{ maxWidth: 1200, flexDirection: isSmallScreen ? 'column-reverse' : null }}
                >
                    <Grid size={{ xs: 12, md: 6 }}>
                        <CardWithBoxShadow>
                            <CardHeader
                                title={<Typography variant="h5">Card Details</Typography>}
                                classes={{
                                    root: 'padding-desktop'
                                }}
                            />
                            <BraintreeDropInCard
                                isLoading={isLoading}
                                clientToken={clientToken}
                                setBraintreeInstance={setBraintreeInstance}
                                handleClose={() => ({})}
                                paymentFailed={false}
                                paymentSuccess={false}
                            />
                            <Grid
                                container
                                display="flex"
                                flex={1}
                                spacing={2}
                                sx={{
                                    flexDirection: isSmallScreen ? 'column-reverse' : null,
                                    margin: '0 32px 32px'
                                }}
                            >
                                <Grid size={{ xs: 12, md: 5 }}>
                                    <TertiaryButton sx={{ width: isSmallScreen ? '100%' : null }} onClick={handleBack}>
                                        Back
                                    </TertiaryButton>
                                </Grid>
                                <Grid size={{ xs: 12, md: 7 }}>
                                    <SecondaryButton
                                        onClick={handleReviewPurchase}
                                        loading={isCreatePaymentMethodLoading}
                                        sx={{ width: '100%' }}
                                    >
                                        Continue
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        </CardWithBoxShadow>
                    </Grid>
                    <Grid display="block" flex={1} container spacing={2} size={{ xs: 12, md: 6 }}>
                        <CardWithBoxShadow>
                            <PaymentSummary maxAthleteCount={maxAthleteCount} planPreview={planPreview} />
                        </CardWithBoxShadow>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
