import React from 'react';
import { connect } from 'react-redux';

type Props = {
    feature: string;
    featureSet: any;
    children: any;
    fallback?: JSX.Element | null;
};

function FeatureFlag({ feature, featureSet, children, fallback = null }: Props) {
    const canAccessFeature = featureSet[feature];

    if (canAccessFeature) {
        return children;
    } else {
        return fallback;
    }
}

const mSTP = (state: any, ownProps: any) => {
    return {
        ...ownProps,
        featureSet: state.features
    };
};

export default connect(mSTP)(FeatureFlag);
