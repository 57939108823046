// @flow
import Login from '../account/components/Login';
import CreateAccount from '../account/components/CreateAccount';
import ForgotPassword from '../account/components/ForgotPassword';
import AssistantCoachSettings from '../coachManagement/components/AssistantCoachSettings';
import SubscriptionPlans from '../planManagement/components/SubscriptionPlans';
import AcceptAssistantCoachInvite from '../coachManagement/components/AcceptAssistantCoachInvite';
import CancelPlan from '../planManagement/components/CancelPlan';
import PaymentInfo from '../payment/components/PaymentInfo';
import BillingHistory from '../billing/components/BillingHistory';
import Receipt from '../billing/components/Receipt';
import IntegrationApiTokens from '../integrations/components/IntegrationApiTokens';
import config from '../api/config';
import GrantParentalConsent from '../parentalConsent/components/GrantParentalConsent';

export default {
    HOME: {
        component: () => {
            window.location = config.URL_COACHAPP;
        },
        path: '/'
    },
    LOGIN: {
        component: Login,
        path: '/login',
        isPublic: true
    },
    CREATE_ACCOUNT: {
        component: CreateAccount,
        path: '/create-account',
        isPublic: true
    },
    FORGOT_PASSWORD: {
        component: ForgotPassword,
        path: '/forgot-password',
        isPublic: true
    },
    ASSISTANT_COACHES: {
        component: AssistantCoachSettings,
        path: '/assistant-coaches'
    },
    SUBSCRIPTION_PLANS: {
        BASE: {
            component: SubscriptionPlans,
            path: '/subscription-plans'
        },
        CANCEL: {
            component: CancelPlan,
            path: '/subscription-plans/cancel'
        }
    },
    ACCEPT_ASSISTANT_COACH: {
        component: AcceptAssistantCoachInvite,
        path: '/accept-assistant-coach-invite',
        isPublic: true
    },
    GRANT_PARENTAL_CONSENT: {
        component: GrantParentalConsent,
        path: '/grant-parental-consent',
        isPublic: true
    },
    PAYMENT: {
        component: PaymentInfo,
        path: '/payment'
    },
    BILLING: {
        BASE: {
            component: BillingHistory,
            path: '/billing'
        },
        DETAIL: {
            component: Receipt,
            path: '/billing/invoice/:id'
        }
    },
    INTEGRATIONS: {
        component: IntegrationApiTokens,
        path: '/integrations'
    }
};
