// @flow
import React from 'react';

export default function GrantParentalConsentFooter() {
    return (
    <>
        <div className="body1 paragraph">
            By submitting this information, you certify that you consent to the collection, use and/or disclosure
            of your child's Personal Data as described in TrainHeroics'{' '}
            <a
                href="https://legal.trainheroic.com/terms-of-use.html"
                target="_blank"
                rel="noopener noreferrer"
                className="terms-link"
            >
                Terms of Use
            </a>
            {' '}and{' '}
            <a
                href="https://legal.trainheroic.com/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="terms-link"
            >
                Privacy&nbsp;Policy
            </a>
            .
        </div>
        <br/>
        <div className="body1 paragraph">
            If you do not consent to these terms, your child will not be able to use the Sites or Services.
            If you have any questions, please do not hestitate to{' '}
            <a
                href="https://support.trainheroic.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
                className="terms-link"
            >
                Contact&nbsp;Us
            </a>.
        </div>
    </>
);
}
