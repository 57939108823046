// @flow
import { useCallback, useEffect, useState } from 'react';
import createZapierToken from '../api/createZapierToken';
import fetchZapierTokens from '../api/fetchZapierTokens';
import deleteZapierToken from '../api/deleteZapierToken';

export default function useZapierTokens() {
    const [zapierTokens, setZapierTokens] = useState([]);

    const getZapierTokens = useCallback(async () => {
        const tokens = await fetchZapierTokens();
        setZapierTokens(tokens);
    }, [setZapierTokens]);

    const generateZapierToken = async () => {
        await createZapierToken();
        getZapierTokens();
    };

    const removeZapierToken = async zapierToken => {
        await deleteZapierToken(zapierToken);
        getZapierTokens();
    };

    useEffect(() => {
        getZapierTokens();
    }, [getZapierTokens]);

    return {
        zapierTokens,
        generateZapierToken,
        removeZapierToken
    };
}
