// @flow
import { authenticatedFetch } from '../../api/request';

const deleteInvitation = async (orgId: number, inviteId: number) => {
    const path = `/v5/orgs/${orgId}/assistantCoaches/invite/${inviteId}`;
    const requestOptions = {
        method: 'DELETE'
    };
    return await authenticatedFetch(path, requestOptions);
};

export default deleteInvitation;
