// @flow
import { authenticatedFetch } from '../../api/request';

export default async function processPlanUpgrade(
    nonce,
    orgId,
    newCoachLicenseId
) {
    return await authenticatedFetch(`/v5/orgs/${orgId}/subscriptions/upgrade`, {
        method: 'PUT',
        body: JSON.stringify({ nonce, newCoachLicenseId })
    });
}
