// @flow
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Card, CardHeader, CardActions, MenuItem, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FormSelectField from '../../common/components/FormSelectField';
import TertiaryButton from '../../common/components/TertiaryButton';
import PrimaryButton from '../../common/components/PrimaryButton';
import SuccessDialog from './SuccessDialog';
import ServerErrorAlert from '../../common/components/ServerErrorAlert';
import BraintreeDropInCard from './BraintreeDropInCard';
import usePlanSelectionWithPayment from './hooks/usePlanSelectionWithPayment';
import { COACH_LICENSE_TYPES } from '../constants';
import { navigate } from '../../state/navigation/navigation';
import CONFIG from '../../api/config';

type SubscriptionOptions = {
    isMarketplaceCoach: boolean,
    isCancelPending: boolean,
    hasPayingSubscription: boolean,
    hasYearlyLicense: boolean,
    isLicenseOverdue: boolean,
    subscriptions: Array<any>,
    nextBillingDate: string
};
type Props = {
    subscriptionOptions: SubscriptionOptions,
    currentPlan: any,
    selectedPlan: any,
    setSelectedPlan: Dispatch<SetStateAction<DefaultState>>,
    fetchSubscriptionOptions: () => {}
};

export default function PlanSelectionWithPayment({
    subscriptionOptions,
    currentPlan,
    selectedPlan,
    setSelectedPlan,
    fetchSubscriptionOptions
}: Props) {
    const history = useHistory();
    const {
        menuOpen,
        setMenuOpen,
        loadPaymentForm,
        setLoadPaymentForm,
        handleDowngradeFlow,
        setBraintreeInstance,
        isPaymentProcessing,
        clientToken,
        isLoading,
        processBraintree,
        paymentSuccess,
        setPaymentSuccess,
        setPaymentFailed,
        paymentFailed,
        downgradeFailed,
        setDowngradeFailed,
        downgradeSuccess,
        setDowngradeSuccess,
        isTrial,
        numDaysRemainingInTrial,
        errorMessage
    } = usePlanSelectionWithPayment();

    const {
        isMarketplaceCoach,
        isCancelPending,
        hasPayingSubscription,
        hasYearlyLicense,
        isLicenseOverdue,
        subscriptions,
        nextBillingDate
    } = subscriptionOptions;

    const handleClose = useCallback(() => {
        setLoadPaymentForm(false);
        setDowngradeFailed(false);
        setDowngradeSuccess(false);
        setPaymentFailed(false);
        setPaymentSuccess(false);

        fetchSubscriptionOptions();

        if (isTrial && paymentSuccess) {
            window.open(CONFIG.URL_THANKS, '_blank');
        }
    }, [isTrial, paymentSuccess]);

    const { coachLicenseId, isUpgrade, price, proratedAmount } = selectedPlan;

    const handleUpgradeFlow = () => {
        if (loadPaymentForm) {
            processBraintree(coachLicenseId);
        } else {
            setLoadPaymentForm(true);
        }
    };

    const cancelChanges = () => {
        setLoadPaymentForm(false);
        setSelectedPlan(currentPlan);
    };

    const generateButtonText = () => {
        let buttonText = 'Upgrade Plan';

        if (selectedPlan !== currentPlan) {
            buttonText = isUpgrade ? 'Upgrade' : 'Downgrade';
        }

        if (loadPaymentForm) {
            buttonText = 'Submit Payment';
        }

        if (isLicenseOverdue) {
            buttonText = 'Go to Billing';
        }

        return buttonText;
    };

    const managePlanChanges = () => {
        if (isLicenseOverdue) {
            navigate('/payment');
        } else if (selectedPlan === currentPlan) {
            setMenuOpen(true);
        } else if (isUpgrade) {
            handleUpgradeFlow();
        } else {
            handleDowngradeFlow(coachLicenseId);
        }
    };

    const generatePlanOptions = () => {
        return (
            subscriptions?.length &&
            subscriptions.map(option => {
                const selected = coachLicenseId === option.coachLicenseId ? 'select-form-selected' : '';
                const disabled = option.isEnabled ? '' : 'option-disabled';

                return (
                    <MenuItem key={option.coachLicenseId} value={option} style={{ flexDirection: 'row' }}>
                        <ListItemText
                            primary={option.title}
                            classes={{
                                primary: `${disabled} ${selected}`
                            }}
                        />
                        {menuOpen && coachLicenseId === option.coachLicenseId ? <CheckIcon /> : null}
                    </MenuItem>
                );
            })
        );
    };

    const displayProrationText = () => {
        const showUpgradeMessage =
            selectedPlan !== currentPlan && isUpgrade && hasPayingSubscription && proratedAmount !== '$0.00';

        if (showUpgradeMessage) {
            return (
                <div className="subscription-plan-select">
                    <p className="proration">
                        You will be charged {proratedAmount} today to upgrade for the remainder of this billing cycle,
                        then {price} per month (plus $9.99 per assistant coach) going forward.
                    </p>
                </div>
            );
        }

        const showDowngradeMessage = selectedPlan !== currentPlan && !isUpgrade;

        if (showDowngradeMessage) {
            const formattedNextBillingDate = moment(nextBillingDate, 'YYYY-MM-DD').format('MMMM Do, YYYY');
            return (
                <div className="subscription-plan-select">
                    <p className="proration">
                        Your account will be billed {price} per month (plus $9.99 per assistant coach) <br /> starting{' '}
                        {formattedNextBillingDate}.
                    </p>
                </div>
            );
        }

        return null;
    };

    const isTrialOrBasic =
        currentPlan.coachLicenseId === COACH_LICENSE_TYPES.TRIAL ||
        currentPlan.coachLicenseId === COACH_LICENSE_TYPES.BASIC;

    const showCancelPlanButton =
        !loadPaymentForm && selectedPlan === currentPlan && !isTrialOrBasic && !isLicenseOverdue;

    const helperText =
        selectedPlan !== currentPlan ? `* ${selectedPlan.price} plus $9.99 per assistant coach (if applicable)` : '';

    const cardTitle = isTrial ? `You have ${numDaysRemainingInTrial} days remaining in your trial.` : 'Current Plan';

    return (
        <>
            <div className="current-plan">
                <Card
                    data-testid="card"
                    classes={{
                        root: 'card'
                    }}
                    elevation={2}
                >
                    <CardHeader
                        title={cardTitle}
                        classes={{
                            root: 'padding-desktop'
                        }}
                        slotProps={{ title: { variant: 'h5' } }}
                    />
                    <form className="subscription-plan-select">
                        <div>
                            {subscriptions?.length && (
                                <FormSelectField
                                    disabled={hasYearlyLicense || isLicenseOverdue}
                                    onClose={() => {
                                        setMenuOpen(false);
                                    }}
                                    onOpen={() => setMenuOpen(true)}
                                    open={menuOpen}
                                    required={true}
                                    value={selectedPlan}
                                    renderValue={value => <ListItemText primary={value.title} />}
                                    onChange={event => {
                                        const option = event.target.value;
                                        if (option.isEnabled) {
                                            setSelectedPlan(option);
                                        }

                                        setMenuOpen(false);
                                    }}
                                    helperText={helperText}
                                >
                                    {generatePlanOptions()}
                                </FormSelectField>
                            )}
                        </div>
                    </form>
                    {displayProrationText()}
                    {isLicenseOverdue && (
                        <div className="account-overdue">
                            <p>
                                There is an issue with your payment information. <br />
                                Please go to Billing to update your payment method.
                            </p>
                        </div>
                    )}
                    {errorMessage && (
                        <div className="account-overdue">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                    {loadPaymentForm && (
                        <form className="subscription-plan-select payment-form">
                            <BraintreeDropInCard
                                isLoading={isLoading}
                                clientToken={clientToken}
                                setBraintreeInstance={setBraintreeInstance}
                                handleClose={handleClose}
                                paymentSuccess={paymentSuccess}
                                paymentFailed={paymentFailed}
                                removeHorizontalPadding
                            />
                        </form>
                    )}

                    <CardActions
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            padding: '32px'
                        }}
                    >
                        <PrimaryButton
                            sx={{ flex: 1, marginRight: '8px' }}
                            disabled={hasYearlyLicense}
                            showActivity={isPaymentProcessing}
                            onClick={managePlanChanges}
                        >
                            {generateButtonText()}
                        </PrimaryButton>
                        {selectedPlan !== currentPlan ? (
                            <TertiaryButton sx={{ flex: 1, marginLeft: '8px' }} onClick={() => cancelChanges()}>
                                Cancel Changes
                            </TertiaryButton>
                        ) : null}
                        {showCancelPlanButton && (
                            <TertiaryButton
                                sx={{ flex: 1, marginLeft: '8px' }}
                                disabled={isCancelPending || isMarketplaceCoach}
                                onClick={() => {
                                    return history.push({
                                        pathname: '/subscription-plans/cancel',
                                        state: { nextBillingDate }
                                    });
                                }}
                            >
                                Cancel Plan
                            </TertiaryButton>
                        )}
                    </CardActions>
                </Card>
                {!isLicenseOverdue && hasYearlyLicense && (
                    <div className="sales-contact">
                        <p>
                            Please contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>{' '}
                            to upgrade your account.
                        </p>
                    </div>
                )}
                {isMarketplaceCoach && (
                    <div className="sales-contact">
                        <p>
                            Your account has active marketplace teams <br /> or programs. To cancel, contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>
                        </p>
                    </div>
                )}
                {!hasYearlyLicense && (
                    <div className="sales-contact">
                        <p>
                            Need more than 1000 athletes? <br />
                            Contact{' '}
                            <a href="mailto:support@trainheroic.com" className="sales-email">
                                support@trainheroic.com
                            </a>
                        </p>
                    </div>
                )}
            </div>
            <SuccessDialog
                title={'Success!'}
                subtext={'Your account has been successfully downgraded.'}
                isOpen={downgradeSuccess}
                handleClose={handleClose}
            />
            <ServerErrorAlert
                title="There was an error downgrading your account."
                open={downgradeFailed}
                handleClose={handleClose}
            />
        </>
    );
}
