// @flow
import React from 'react';

export default function GrantParentalConsentBody() {
    return (
        <div>
            <div className="body1">
                TrainHeroic, LLC (“TrainHeroic”, “we,” or “us”) provides a variety of services and features directed to coaches, sport enthusiasts and athletes through our online and mobile fitness offerings (“Services”).The TrainHeroic Services are provided through its websites (
                    <a
                        href="https://www.trainheroic.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="terms-link"
                    >
                        www.trainheroic.com
                    </a>
                ), related mobile applications and all related subdomains.  All of the above-identified websites and mobile applications are collectively referred to as the “Sites” and individually as a “Site.”
            </div>
            <div className="body1 paragraph">
                Some of the data we collect though our Sites and Services is considered “personally identifiable information,” “personal information” or “personal data” under applicable privacy laws (collectively referred to in this Parental Consent Form as “Personal Data”).  Generally, Personal Data is information that can be reasonably connected to an identified or identifiable individual.  It does not include de-identified or anonymized data.
            </div>
            <div className="body1 paragraph">
                In compliance with applicable law and our company practices, we do not knowingly collect Personal Data from children under 16 years of age or permit children under 16 years old to register for accounts on the Sites and Services without first obtaining consent from a parent or legal guardian. The exception to this is we will collect limited Personal Data from a child under 16 years old if they attempt to create an account and identify that they are under 16 years old.  In that event, we will permit the child to create a limited, temporary account to provide their name and contact information for a parent or legal guardian so that we can attempt to obtain consent.  If we are unable to obtain consent, we will delete the child’s account and Personal Data.  We will fully activate an account only after receiving verifiable consent from a parent or legal guardian.  We also permit parents and legal guardians of athletes under 16 years of age to contact us to request (1) what Personal Data we have about their child, (2) that we correct any incorrect Personal Data, (3) that we delete the Personal Data of their child, and/or (4) that we cease further collection or use of any Personal Data from their child. We do not sell the Personal Data of children under 16 years old.
            </div>
            <div className="body1 paragraph">
                Your child has expressed interest in using our Sites and Services and has created a limited, temporary account so that they can obtain parental consent to use the Sites and Services. Your child has provided us with your contact information so that we can obtain your consent to collect Personal Data from your child and provide them with access to the Sites and Services.
            </div>
            <div className="body1 paragraph">
                If you consent to permitting your child to use the Sites and Services, and to our collection, processing and use of their Personal Data, please complete this form and return it to us.  By submitting this form to us, you understand, agree and content to the following:
            </div>
            <ul className="body1 paragraph">
                <li>TrainHeroic will collect Personal Data from your child for the purpose of providing the child with access to the Sites and Services.</li>
                <li>Your child’s Personal Data may be shared with TrainHeroic service providers and, if selected, coaches. Your child may also directly share Personal Data with other users of the Sites and Services.</li>
                <li>Our{' '}
                    <a
                        href="https://legal.trainheroic.com/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="terms-link"
                    >
                        Privacy&nbsp;Policy
                    </a>
                    {' '}describes how we collect, use, share and maintain Personal Data.</li>
                <li>If you do not provide consent, we will delete your contact information and any Personal Data your child provided to us from our records.</li>
                <li>You have the right to review the Personal Data we collect from your child, to withdraw your consent and to request deletion at any time. To exercise any of these rights, you may contact us at{' '}
                    <a href="mailto:privacy@trainheroic.com" className="terms-link">privacy@trainheroic.com</a>{' '}or{' '}
                    <a
                        href="https://support.trainheroic.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="terms-link"
                    >
                        Contact&nbsp;Us
                    </a>{' '}via webform.</li>
            </ul>
        </div>
    );
}
