import { useState, useEffect } from 'react';
import getUser from '../../api/getUser';

export default function usePlanSelection() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [numDaysRemainingInTrial, setNumDaysRemainingInTrial] = useState(0);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();
            if (user.isTrial) {
                setIsTrial(true);
                setNumDaysRemainingInTrial(user.numDaysRemainingInTrial);
            }
        };
        fetchUser();
    }, []);

    return {
        menuOpen,
        setMenuOpen,
        isTrial,
        numDaysRemainingInTrial
    };
}
