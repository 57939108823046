// @flow
import { useState } from 'react';
import requestResetPasswordEmail from '../../api/requestResetPasswordEmail';

export default function useAccountCreation() {
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);

    const sendPasswordReset = async () => {
        setSending(true);
        const response = await requestResetPasswordEmail(email);
        if (response.status === 200) {
            setSending(false);
            setEmailHasBeenSent(true);
        } else {
            setSending(false);
        }
    };

    return {
        emailHasBeenSent,
        email,
        setEmail,
        sending,
        sendPasswordReset
    };
}
