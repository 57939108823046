// @flow
import React from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from './PrivateRoute';
import ROUTES from './routes';

type View = {
    component: React.Element<typeof Component>,
    path: string,
    isPublic: boolean
};
type ViewRoutes = {
    (key: string): View
};

export default () => {
    const routes = mapRoutes(ROUTES);

    return <Switch>{routes}</Switch>;
};

export const mapRoutes = (viewRoutes: ViewRoutes) => {
    let routes = [];
    Object.keys(viewRoutes).forEach(key => {
        const view = viewRoutes[key];

        if (view.path) {
            routes.push(createRoute(view));
        } else {
            routes = routes.concat(mapRoutes({ ...view }));
        }
    });

    return routes;
};

const createRoute = (view: View) => {
    if (view.isPublic) {
        return createPublicRoute(view);
    }

    return createPrivateRoute(view);
};

const createPublicRoute = (view: View) => {
    return (
        <Route path={view.path} key={view.path} component={view.component} />
    );
};

const createPrivateRoute = (view: View) => {
    return (
        <PrivateRoute
            exact
            path={view.path}
            key={view.path}
            component={view.component}
        />
    );
};
