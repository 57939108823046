// @flow
import React from 'react';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material';

const dialogStyle = {
    padding: 30
};

const imageStyle = {
    textAlign: 'center'
};

const textStyle = {
    color: '$primary-black',
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: 15
};

const loaderStyle = {
    marginBottom: 15
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    root: {
        height: 16,
        borderRadius: 8
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#0074e2'
    }
  }));

export default function LoadingDialog({ isOpen, progress }) {
    return (
        <Dialog aria-labelledby="step-loader" open={isOpen} maxWidth={200}>
            <DialogContent style={dialogStyle}>
                <div style={imageStyle}>
                    <img
                        data-testid="image"
                        height={50}
                        width={50}
                        src="https://static.trainheroic.com/images/CitrusGradientLogo.png"
                        alt="TrainHeroic Logo"
                    />
                </div>
                <div style={textStyle}>
                    Preparing your
                    <br />
                    FREE Trainheroic Trial
                </div>
                <BorderLinearProgress
                    style={loaderStyle}
                    variant="determinate"
                    value={progress}
                />
            </DialogContent>
        </Dialog>
    );
}
