import { getState } from '../state/store';
import Config from './config';

const HTTP_CODES = {
    SERVER_ERROR: 500,
    FORBIDDEN: 403
};

const responseHandler = async (response: any, onFail?: () => {}) => {
    if (response.status === HTTP_CODES.SERVER_ERROR) {
        if (onFail) return onFail();
    }

    if (response.status === HTTP_CODES.FORBIDDEN) {
        window.location.replace(Config.URL_COACHAPP);
        return;
    }

    let returnValue;
    try {
        returnValue = await response.text();
        returnValue = JSON.parse(returnValue);
    } catch (e) {
        returnValue = response;
    }

    return returnValue;
};

export async function authenticatedFetch(url: string, options: any = { method: 'GET' }, onFail?: () => {}) {
    const sessionToken = getState().authentication.token;

    if (!sessionToken) {
        console.error('No session found');
        return { status: 401, message: 'Not Authenticated' };
    }

    const authenticatedOptions = { ...options };

    if (!authenticatedOptions.headers) {
        authenticatedOptions.headers = {};
    }

    authenticatedOptions.headers['Api-Token'] = 'DISABLED';
    authenticatedOptions.headers['Session-Token'] = sessionToken;

    if (options.method !== 'GET' && authenticatedOptions.headers['Content-Type'] === undefined) {
        authenticatedOptions.headers['Content-Type'] = 'application/json';
    }

    let response;
    try {
        response = await fetch(urlFormatter(url), authenticatedOptions);
    } catch (error) {
        if (onFail) return onFail();
        return;
    }

    return responseHandler(response, onFail);
}

export async function unauthenticatedFetch(url: string, options: any = { method: 'GET' }) {
    const authenticatedOptions = { ...options };

    if (!authenticatedOptions.headers) {
        authenticatedOptions.headers = {};
    }

    if (options.method !== 'GET' && authenticatedOptions.headers['Content-Type'] === undefined) {
        authenticatedOptions.headers['Content-Type'] = 'application/json';
    }

    const response = await fetch(urlFormatter(url), authenticatedOptions);

    return responseHandler(response);
}

export const urlFormatter = (path: string) => {
    if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0) {
        return path;
    }
    return `${Config.API}${path}`;
};
