// @flow
import React from 'react';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';

type Props = {
    error?: boolean,
    required: boolean,
    label?: string,
    value: any,
    onChange: (event: any) => void,
    children: any,
    errorText?: string | null,
    helperText?: string | null,
    disabled?: Boolean,
    onClose: () => void,
    onOpen: () => void,
    open: boolean,
    renderValue: any
};

export default function FormSelectField(props: Props) {
    return (
        <FormControl
            data-testid="formControl"
            className="form-select-field"
            error={props.error}
            required={props.required}
        >
            <InputLabel
                data-testid="inputLabel"
                classes={{
                    root: 'input',
                    asterisk: 'label-asterisk',
                    focused: 'input-focus'
                }}
                className="form-select-label"
            >
                {props.label}
            </InputLabel>
            <Select
                variant="standard"
                disabled={props.disabled}
                data-testid="select"
                value={props.value}
                renderValue={props.renderValue}
                onChange={props.onChange}
                onOpen={props.onOpen}
                onClose={props.onClose}
                open={props.open}
                inputProps={{
                    classes: {
                        root: 'select-input',
                        select: 'select-input-focus'
                    }
                }}
            >
                {props.children}
            </Select>
            {props.error ? <FormHelperText>{props.errorText}</FormHelperText> : null}
            {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
        </FormControl>
    );
}

FormSelectField.defaultProps = {
    error: false,
    errorText: null,
    helperText: null
};
