// @flow
import { useState } from 'react';

export default function useLoadingDialog(callback: function) {
    const [isOpen, setIsOpen] = useState(false);
    const [progress, setProgress] = useState(0);

    const startProgress = () => {
        setProgress(0);
        setIsOpen(true);
        const timer = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    return 100;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);
        return () => {
            clearInterval(timer);
        };
    };

    return {
        isOpen,
        setIsOpen,
        progress,
        setProgress,
        startProgress
    };
}
