// @flow
import React, { useState } from 'react';
import CoachSettingsRowTemplate from './CoachSettingsRowTemplate';
import TertiaryButton from '../../common/components/TertiaryButton';
import RemoveCoachModal from './RemoveCoachModal';
import InviteCoach from './InviteCoach';

type Props = {
    coach: any,
    removeCoach: () => {},
    resendInvite: () => {},
    deleteInvite: () => {},
    fetchCoachList: () => {}
};

const AssistantCoachRow = ({ coach, removeCoach, resendInvite, deleteInvite, fetchCoachList }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id, fullName, status, isInvited, isAvailable, price } = coach;

    let actionColumn = null;
    let rowStyles = null;

    if (isAvailable) {
        rowStyles = {
            fontStyle: 'italic'
        };
        actionColumn = (
            <div className="action-row">
                <InviteCoach buttonText="Invite Coach" refreshList={fetchCoachList} />
            </div>
        );
    } else {
        actionColumn = isInvited ? (
            <div className="action-row">
                <TertiaryButton data-testid="resend-invite" onClick={() => resendInvite(id)}>
                    Resend Invite
                </TertiaryButton>
                <TertiaryButton data-testid="delete-invite" onClick={() => deleteInvite(id)}>
                    Delete Invite
                </TertiaryButton>
            </div>
        ) : (
            <div className="action-row">
                <TertiaryButton data-testid="remove-coach" onClick={() => setIsModalOpen(true)}>
                    Remove Coach
                </TertiaryButton>
            </div>
        );
    }

    return (
        <>
            <CoachSettingsRowTemplate
                rowStyles={rowStyles}
                firstColumn={<div>{fullName}</div>}
                secondColumn={<div>Assistant</div>}
                thirdColumn={<div>{status}</div>}
                fourthColumn={<div>{price}</div>}
                actionColumn={actionColumn}
            />
            <RemoveCoachModal
                coach={coach}
                isModalOpen={isModalOpen}
                removeCoach={removeCoach}
                handleClose={() => setIsModalOpen(false)}
            />
        </>
    );
};

export default AssistantCoachRow;
