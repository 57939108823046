export const COACH_LICENSE_TYPES = {
    TRIAL: 10,
    PRO: 12,
    ELITE: 13,
    ENTERPRISE: 14,
    BASIC: 15,
    STARTER: 17,
    ONE_ATHLETE: 18,
    FIVE_ATHLETES: 19,
    FIFTEEN_ATHLETES: 20,
    TWENTY_FIVE_ATHLETES: 21,
    FIFTY_ATHLETES: 22,
    SEVENTY_FIVE_ATHLETES: 23,
    ONE_HUNDRED_ATHLETES: 24,
    TWO_HUNDRED_ATHLETES: 25,
    CUSTOM_ELITE: 26
};

export const BRAINTREE_OPTIONS = {
    translations: {
        payingWith: '',
        chooseAnotherWayToPay: 'Add New Card',
        fieldEmptyForCvv: 'Required entry',
        fieldEmptyForExpirationDate: 'Required entry',
        fieldEmptyForNumber: 'Required entry',
        fieldEmptyForPostalCode: 'Required entry',
        editPaymentMethods: '',
        edit: 'Edit Payment Methods'
    },
    vaultManager: true,
    card: {
        overrides: {
            styles: {
                input: {
                    'font-size': '14px'
                }
            },
            fields: {
                number: {
                    placeholder: 'Card Number'
                },
                cvv: {
                    placeholder: 'CVV'
                },
                expirationDate: {
                    placeholder: 'Expiration Date (MM/YY)'
                },
                postalCode: {
                    placeholder: 'Postal Code'
                }
            }
        }
    }
};
