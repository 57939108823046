// @flow
import React from 'react';

const LOGO_COUNT = 18;
const LOGO_ARRAY = [];
LOGO_ARRAY.push(require('../assets/partner-logos/01.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/02.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/03.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/04.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/05.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/06.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/07.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/08.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/09.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/10.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/11.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/12.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/13.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/14.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/15.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/16.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/17.png'));
LOGO_ARRAY.push(require('../assets/partner-logos/18.png'));

export default function PartnerLogoFooter() {
    return (
        <div className="logo-container">
            {LOGO_ARRAY.map(logo => (
                <div>
                    <img data-testid="image" alt="Partner Logo" src={logo} />
                </div>
            ))}
        </div>
    );
}
