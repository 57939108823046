// @flow
import React from 'react';
import { Card, CardActions, CardContent } from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import FormTextField from '../../common/components/FormTextField';
import PrimaryButton from '../../common/components/PrimaryButton';
import ServerErrorAlert from '../../common/components/ServerErrorAlert';
import useNewAccountFormManager from './hooks/useNewAccountFormManager';
import { navigate } from '../../state/navigation/navigation';
import LoadingDialog from './LoadingDialog';
import useScreenDimensions from '../../common/hooks/useScreenDimensions';
import TermsOfUseCheckbox from './TermsOfUseCheckbox';
import useCaptcha from './hooks/useCaptcha';
import TertiaryButton from '../../common/components/TertiaryButton';

export default function CoachTrialForm() {
    const { width } = useScreenDimensions();

    const {
        name,
        setName,
        nameValidated,
        nameErrorMessage,
        email,
        setEmail,
        emailValidated,
        emailErrorMessage,
        password,
        setPassword,
        passwordValidated,
        passwordErrorMessage,
        hasAgreed,
        setHasAgreed,
        expectedAccountSize,
        setExpectedAccountSize,
        expectedAccountSizeValidated,
        setExpectedAccountSizeValidated,
        expectedAccountSizeErrorMessage,
        showValidationErrors,
        isInProcess,
        isOpen,
        progress,
        createAccount,
        createAccountSuccessfulOrNotSubmitted,
        serverErrorMessages,
        organization,
        setOrganization,
        organizationValidated,
        organizationErrorMessage,
        setCountryCode,
        phoneNumber,
        setPhoneNumber,
        phoneNumberValidated,
        phoneNumberErrorMessage,
        serverError,
        setServerError
    } = useNewAccountFormManager(true);

    const { isBlocked, setVerified, token, H_CAPTCHA_KEY } = useCaptcha();

    const hasNameError = showValidationErrors && !nameValidated;
    const hasEmailError = showValidationErrors && !emailValidated;
    const hasPasswordError = showValidationErrors && !passwordValidated;
    const hasAgreedError = showValidationErrors && !hasAgreed;
    const hasOrganizationError = showValidationErrors && !organizationValidated;
    const hasPhoneNumberError = showValidationErrors && !phoneNumberValidated;
    const hasExpectedAccountSizeError = showValidationErrors && !expectedAccountSizeValidated;

    const nameErrorText = createAccountSuccessfulOrNotSubmitted ? nameErrorMessage : serverErrorMessages.fullName;
    const emailErrorText = createAccountSuccessfulOrNotSubmitted ? emailErrorMessage : serverErrorMessages.email;
    const passwordErrorText = createAccountSuccessfulOrNotSubmitted
        ? passwordErrorMessage
        : serverErrorMessages.password;
    const organizationErrorText = createAccountSuccessfulOrNotSubmitted
        ? organizationErrorMessage
        : serverErrorMessages.organization;
    const phoneNumberErrorText = createAccountSuccessfulOrNotSubmitted
        ? phoneNumberErrorMessage
        : serverErrorMessages.phoneNumber;
    const expectedAccountSizeErrorText = createAccountSuccessfulOrNotSubmitted
        ? expectedAccountSizeErrorMessage
        : serverErrorMessages.expectedAccountSize;

    const handleCreateAccountViaEnterKey = event => {
        const enterKeyPressed = event.key === 'Enter' || event.keyCode === 13 || event.which === 13;

        if (isBlocked) {
            return;
        }

        if (enterKeyPressed) {
            createAccount(token);
        }
    };

    const displayCoachQuestions = () => (
        <>
            <div className="form-input">
                <FormTextField
                    onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                    required={true}
                    type="text"
                    label="Organization Name"
                    value={organization}
                    variant="standard"
                    onChange={event => setOrganization(event.target.value)}
                    error={hasOrganizationError}
                    helperText={hasOrganizationError ? organizationErrorText : ''}
                />
            </div>

            <div className="form-input">
                <FormTextField
                    value={expectedAccountSize}
                    label="How many clients do you coach?"
                    type="number"
                    inputProps={{
                        min: 1,
                        max: 10000,
                        step: 1,
                        inputMode: 'numeric'
                    }}
                    variant="standard"
                    error={hasExpectedAccountSizeError}
                    required={true}
                    helperText={hasExpectedAccountSizeError ? expectedAccountSizeErrorText : ''}
                    onChange={event => {
                        setExpectedAccountSize(event.target.value);
                    }}
                />
            </div>

            <div className="form-input">
                <MuiPhoneNumber
                    onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                    data-testid="text-field-input"
                    className="form-textfield"
                    inputProps={{
                        name: 'phone',
                        required: true
                    }}
                    type="tel"
                    label="Phone Number"
                    value={phoneNumber}
                    defaultCountry="us"
                    onChange={(value, country) => {
                        setCountryCode(country.countryCode.toUpperCase());
                        setPhoneNumber(value);
                    }}
                    error={hasPhoneNumberError}
                    helperText={hasPhoneNumberError ? phoneNumberErrorText : ''}
                />
            </div>
        </>
    );

    return (
        <div className="container payment-container">
            <div>
                <Card data-testid="card" classes={{ root: 'form-card card' }}>
                    <div className="title-container">
                        <div className="title">
                            Start your
                            <br /> free coach trial
                        </div>
                        {width > 375 ? (
                            <img
                                alt="highlight"
                                className="title-highlight"
                                height={50}
                                width={350}
                                src="https://static.trainheroic.com/icons/Highlight.Long2.png"
                            />
                        ) : (
                            <div className="title-placeholder" />
                        )}
                    </div>
                    <form className="form-padding padding-desktop">
                        <div className="form-input">
                            <FormTextField
                                onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                required={true}
                                type="text"
                                label="First and Last Name"
                                value={name}
                                variant="standard"
                                onChange={event => setName(event.target.value)}
                                error={hasNameError}
                                helperText={hasNameError ? nameErrorText : ''}
                            />
                        </div>
                        <div className="form-input">
                            <FormTextField
                                onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                required={true}
                                type="email"
                                label="Email"
                                value={email}
                                variant="standard"
                                onChange={event => setEmail(event.target.value)}
                                error={hasEmailError}
                                helperText={hasEmailError ? emailErrorText : ''}
                            />
                        </div>
                        <div className="form-input">
                            <FormTextField
                                onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                required={true}
                                type="password"
                                label="Password"
                                value={password}
                                variant="standard"
                                onChange={event => setPassword(event.target.value)}
                                error={hasPasswordError}
                                helperText={hasPasswordError ? passwordErrorText : ''}
                            />
                        </div>
                        {displayCoachQuestions()}
                    </form>
                    <CardContent classes={{ root: 'padding-desktop' }}>
                        <TermsOfUseCheckbox
                            hasAgreed={hasAgreed}
                            hasAgreedError={hasAgreedError}
                            onChange={() => setHasAgreed(!hasAgreed)}
                        />
                    </CardContent>
                    <CardActions classes={{ root: 'action-container' }}>
                        <HCaptcha sitekey={H_CAPTCHA_KEY} onVerify={captchaToken => setVerified(captchaToken)} />
                        <div className="action-buttons-container">
                            <PrimaryButton
                                showActivity={isInProcess}
                                disabled={isBlocked}
                                onClick={() => createAccount(token)}
                            >
                                Start Your Free Trial
                            </PrimaryButton>
                        </div>
                    </CardActions>
                </Card>
                <div className="action-buttons-container button-margin">
                    <TertiaryButton onClick={() => navigate('/login')}>Already have an account? Login.</TertiaryButton>
                </div>
                <LoadingDialog isOpen={isOpen} progress={progress} />
            </div>
            <ServerErrorAlert
                title="There was an error creating your account."
                open={serverError}
                handleClose={() => setServerError(false)}
            />
        </div>
    );
}
