import ACTIONS from '../authentication/constants';

const features = (state = {}, action = {}) => {
    switch (action.type) {
        case ACTIONS.FEATURES_SET:
            return {
                ...action.features
            };
        case ACTIONS.RESET_ALL:
            return {};
        default:
            return state;
    }
};

export default features;
