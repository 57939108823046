// @flow
import { push } from 'react-router-redux';
import { getStore } from '../store';

import CONFIG from '../../api/config';

export const navigate = (destination: string) => {
    window.scrollTo(0, 0);
    getStore().dispatch(push(destination));
};

export const navigateNewTab = (destination: string) => {
    window.open(destination, '_blank');
};

export const navigateToCoachHeroic = () => {
    window.location.replace(CONFIG.URL_COACHAPP);
};

export const navigateToAthleteList = () => {
    const path = `${CONFIG.URL_COACHHEROIC}/athletes`;
    window.location.replace(path);
};
