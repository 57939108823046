import React from 'react';
import { Card, CardProps, styled } from '@mui/material';

const StyledComponent = styled(Card)({
    elevation: 2,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
    flex: 1,
    boxShadow: '0px 1px 2px 0px var(--effects-shadow, rgba(26, 32, 46, 0.30))'
});

interface Props extends CardProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

function CardWithBoxShadow({ children, style, ...props }: Props) {
    return (
        <StyledComponent style={style} {...props}>
            {children}
        </StyledComponent>
    );
}

export default CardWithBoxShadow;
