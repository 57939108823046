import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionOption, UpgradePreview } from '../../types';
import getUpgradePreview from '../../api/getUpgradePreview';
import useSubscriptionOptions from './useSubscriptionOptions';
import useBraintreeDropIn from './useBraintreeDropIn';
import useStepper from './useStepper';

export const DEFAULT_PLAN_PREVIEW: UpgradePreview = {
    coachLicenseAmount: 0,
    assistantCoachLicenseCount: 0,
    assistantCoachLicenseAmount: 0,
    subtotalDueNextBillingDate: 0,
    totalDueNextBillingDate: 0,
    proratedCoachLicenseAmount: 0,
    totalDueToday: 0
};

export default function useSubscriptionPlanCheckout() {
    const [planPreview, setPlanPreview] = useState<UpgradePreview>(DEFAULT_PLAN_PREVIEW);
    const [maxAthleteCount, setMaxAthleteCount] = useState(0);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const { steps, activeStep, handleNext, handleBack, handleReset } = useStepper();

    const handlePaymentPreview = async (plan: SubscriptionOption) => {
        const response = await getUpgradePreview(orgId, plan.coachLicenseId, '');

        if (response.status) {
            return;
        }
        setPlanPreview(response);
        setMaxAthleteCount(plan.maxAthletes);
        handleNext();
    };

    const {
        isLoading: isLoadingSubscriptionOptions,
        subscriptionOptions,
        currentPlan,
        selectedPlan,
        setSelectedPlan,
        hasErrorLoadingOptions,
        setHasErrorLoadingOptions
    } = useSubscriptionOptions();

    const {
        setBraintreeInstance,
        clientToken,
        isLoading: isLoadingBraintreeDropIn,
        createPaymentMethod,
        isCreatePaymentMethodLoading
    } = useBraintreeDropIn();

    const handleReviewPurchase = async () => {
        const paymentMethodId = await createPaymentMethod();
        if (!paymentMethodId) {
            // TO DO: Communicate card is invalid to user
            return;
        }

        const response = await getUpgradePreview(orgId, selectedPlan.coachLicenseId, paymentMethodId);
        if (response.status) {
            return;
        }

        setPlanPreview(response);
        handleNext();
    };

    return {
        steps,
        activeStep,
        handlePaymentPreview,
        handleBack,
        maxAthleteCount,
        planPreview,
        isLoadingSubscriptionOptions,
        subscriptionOptions,
        currentPlan,
        selectedPlan,
        setSelectedPlan,
        hasErrorLoadingOptions,
        setHasErrorLoadingOptions,
        setBraintreeInstance,
        clientToken,
        isLoadingBraintreeDropIn,
        handleReviewPurchase,
        createPaymentMethod,
        isCreatePaymentMethodLoading
    };
}
