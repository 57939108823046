// @flow
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import checkAssistantCoachInviteToken from '../../api/checkAssistantCoachInviteToken';
import { getQueryParams } from '../../../common/utils/locationParams';
import config from '../../../api/config';
import useScreenDimensions from '../../../common/hooks/useScreenDimensions';

export const RESPONSE_TYPES = {
    INVALID_TOKEN: 'invalid-token',
    INVALID_ACCOUNT: 'invalid-account',
    VALID: 'valid',
    LOADING: 'loading'
};

export default function useAcceptAssistantCoachInvite() {
    const [response, setResponse] = useState({
        status: RESPONSE_TYPES.LOADING,
        message: 'Loading...'
    });

    const [displaySpecialText, setDisplaySpecialText] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
    const { width } = useScreenDimensions();
    const queryParams = getQueryParams(useLocation());
    const token = queryParams.token ? queryParams.token : null;

    const redirectToCoach = () => {
        window.location.replace(config.URL_COACHAPP);
    };

    const checkInviteToken = async () => {
        setIsLoading(true);
        const inviteResponse = await checkAssistantCoachInviteToken(token);
        setIsLoading(false);

        if (inviteResponse && inviteResponse.status === 409) {
            setDisplaySpecialText(true);
            return setResponse({
                status: RESPONSE_TYPES.INVALID_ACCOUNT,
                message:
                    'Please create a new account with a different email address to accept this assistant coach invitation.'
            });
        }

        if (
            inviteResponse &&
            (inviteResponse.status === 500 ||
                inviteResponse.status === 400 ||
                inviteResponse.status === 401 ||
                inviteResponse.status === 404 ||
                inviteResponse.status === 409)
        ) {
            return setResponse({
                status: RESPONSE_TYPES.INVALID_TOKEN,
                message: 'Sorry this invitation is no longer valid.'
            });
        }

        setResponse({
            status: RESPONSE_TYPES.VALID,
            message: 'Success!',
            orgName: inviteResponse.orgName
        });
    };

    useEffect(() => {
        checkInviteToken();
    }, [isAuthenticated]);

    return {
        isAuthenticated,
        width,
        isLoading,
        response,
        redirectToCoach,
        displaySpecialText
    };
}
