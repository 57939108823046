// @flow
import { authenticatedFetch } from '../../api/request';

const resendInvitation = async (orgId: number, inviteId: number) => {
    const path = `/v5/orgs/${orgId}/assistantCoaches/invite/${inviteId}/resend`;
    const requestOptions = {
        method: 'POST'
    };
    return await authenticatedFetch(path, requestOptions);
};

export default resendInvitation;
