// @flow
import useAuthentication from './useAuthentication';
import useFormManager from '../../../common/hooks/useFormManager';
import useValidatedField from '../../../common/hooks/useValidatedField';
import useUpdateEffect from '../../../common/hooks/useUpdateEffect';

export default function useLoginFormManager() {
    const { showValidationErrors, setShowValidationErrors, notNullValidator } = useFormManager();

    const {
        value: email,
        setValue: setEmail,
        validated: emailValidated,
        setValidated: setEmailValidated,
        errorState: emailErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: password,
        setValue: setPassword,
        validated: passwordValidated,
        setValidated: setPasswordValidated,
        errorState: passwordErrorMessage
    } = useValidatedField(notNullValidator);

    const { authenticating, authenticated, authenticateUser, resetAuthenticationState } = useAuthentication();

    const authFormSuccessfulOrNotSubmitted = authenticated === null ? true : authenticated;

    const onAuthenticatedStateChange = () => {
        setEmailValidated(authFormSuccessfulOrNotSubmitted);
        setPasswordValidated(authFormSuccessfulOrNotSubmitted);
    };

    useUpdateEffect(onAuthenticatedStateChange, [
        authFormSuccessfulOrNotSubmitted,
        setEmailValidated,
        setPasswordValidated
    ]);

    useUpdateEffect(resetAuthenticationState, [email, password]);

    const isInProgress = authenticated || authenticating;

    const authenticateWithForm = () => {
        const formFieldsValid = emailValidated && passwordValidated;

        const canAuthenticate = formFieldsValid && !isInProgress;

        setShowValidationErrors(true);

        if (canAuthenticate) {
            authenticateUser(email, password);
        }
    };

    return {
        email,
        setEmail,
        emailValidated,
        emailErrorMessage,
        password,
        setPassword,
        passwordValidated,
        passwordErrorMessage,
        authenticateWithForm,
        showValidationErrors,
        isInProgress,
        authenticated,
        authFormSuccessfulOrNotSubmitted
    };
}
