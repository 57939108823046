import { authenticatedFetch } from '../../api/request';

const getUpgradePreview = async (orgId: number, newCoachLicenseId: number, paymentMethodId: string) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ nonce: '', paymentMethodId }),
        headers: { 'Content-Type': 'application/json' }
    };
    return authenticatedFetch(`/v5/orgs/${orgId}/subscriptions/${newCoachLicenseId}/upgradePreview`, options);
};

export default getUpgradePreview;
