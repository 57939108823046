import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import authentication from './authentication/authenticationReducer';
import user from './user/userReducer';
import features from './features/featuresReducer';

import CONFIG from '../api/config';

const history = createBrowserHistory();

// reducer & store creation
const reducers = combineReducers({
    authentication,
    user,
    router: connectRouter(history),
    features
});

const getMiddleWare = () => {
    let middleware = [routerMiddleware(history), thunk];
    if (CONFIG.REDUX_LOGGER_ENABLED) {
        middleware.push(createLogger());
    }

    return applyMiddleware(...middleware);
};

let store = createStore(reducers, getMiddleWare());

export const getStore = () => store;
export const getState = () => store.getState();
export const getHistory = () => history;
export const setMockedStore = mockedStore => {
    store = mockedStore;
};
