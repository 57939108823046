// @flow
import { useState, useEffect } from 'react';

const getScreenDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    return { width, height };
};

export default function useScreenDimensions() {
    const [screenDimensions, setScreenDimensions] = useState(
        getScreenDimensions()
    );

    useEffect(() => {
        const handleResize = () => {
            setScreenDimensions(getScreenDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return screenDimensions;
}
