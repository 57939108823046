// @flow
import { authenticatedFetch } from '../../api/request';

export default async function processPlanDowngrade(
    orgId: number,
    newCoachLicenseId: number
) {
    return await authenticatedFetch(
        `/v5/orgs/${orgId}/subscriptions/downgrade`,
        {
            method: 'PUT',
            body: JSON.stringify({ newCoachLicenseId })
        }
    );
}
