import React from 'react';
import Button from '@mui/material/Button';

export default function DownloadButtonGroup() {
    return (
        <div
            data-testid="download-buttons"
            className="download-buttons-container"
        >
            <Button
                href="https://itunes.apple.com/us/app/trainheroic/id955074569"
                target="_blank"
                rel="noopener noreferrer"
                classes={{ root: 'app-store-button' }}
            />
            <Button
                href="https://play.google.com/store/apps/details?id=com.TrainHeroic.TrainHeroic"
                target="_blank"
                rel="noopener noreferrer"
                classes={{ root: 'play-store-button' }}
            />
        </div>
    );
}
