import React from 'react';
import SubscriptionPlansWithEmbeddedPayment from './SubscriptionPlansWithEmbeddedPayment';
import SubscriptionPlansCheckout from './SubscriptionPlanCheckout';
import FeatureFlag from '../../common/components/FeatureFlag';
import { FEATURES } from '../../account/api/getFeaturesForUserId';

export default function SubscriptionPlans() {
    return (
        <FeatureFlag
            feature={FEATURES.MARKETPLACE_COACH_PURCHASE_FLOW_V1}
            fallback={<SubscriptionPlansWithEmbeddedPayment />}
        >
            <SubscriptionPlansCheckout />
        </FeatureFlag>
    );
}
