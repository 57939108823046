import React from 'react';
import Config from './api/config';

const API_URL = Config.API;
export default function Maintenance() {
    return (
        <iframe
            src={API_URL}
            style={{ width: '100vw', height: '100vh', border: 'none' }}
        />
    );
}