import React from 'react';

export default function CheckCircleIcon({ className }) {
    return (
        <svg
            className={className}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
        <title>Highlight Checkmark</title>
        <circle cx="12" cy="12" r="12" fill="#F1FD53"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75005 15.1492L7.12505 12.5242C6.8351 12.2343 6.365 12.2343 6.07505 12.5242C5.7851 12.8142 5.7851 13.2843 6.07505 13.5742L9.75005 17.2492L18.225 8.77422C18.515 8.48427 18.515 8.01417 18.225 7.72422C17.9351 7.43427 17.465 7.43427 17.175 7.72422L9.75005 15.1492Z" fill="#050310"/>
=        </svg>
    );
}