// @flow

import { unauthenticatedFetch } from '../../api/request';

type CoachUser = {
    fullName: string,
    email: string,
    password: string,
    phoneNumber: string,
    country: string,
    organization: string,
    expectedAccountSize: string,
    athleteType: string,
    athleteTypePartTwo: string,
    athleteSetting: string,
    athleteTotal: string,
};

const createCoachAccount = async (user: CoachUser) => {
    const path = '/public/v5/users/createCoachAccount';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: { 'Content-Type': 'application/json' }
    };
    return await unauthenticatedFetch(path, requestOptions);
};

export default createCoachAccount;
