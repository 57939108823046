import React from 'react';
import { Box, Typography } from '@mui/material';
import textHighlighterUpgrade from '../../common/assets/TextHighlighterUpgrade.svg';
import textHighlighterPayment from '../../common/assets/TextHighlighterPayment.svg';
import textHighlighterReviewPurchase from '../../common/assets/TextHighlighterConfirmPurchase.svg';

export enum HighlighterBackground {
    UPGRADE = 'upgrade',
    PAYMENT = 'payment',
    REVIEW_PURCHASE = 'reviewPurchase'
}
interface HighlighterHeaderProps {
    title: string;
    highlighterBackground: HighlighterBackground;
    width: number;
}

const HIGHLIGHTER_SVG = {
    upgrade: textHighlighterUpgrade,
    payment: textHighlighterPayment,
    reviewPurchase: textHighlighterReviewPurchase
};

export default function HighlighterHeader({ title, highlighterBackground, width }: HighlighterHeaderProps) {
    return (
        <Box justifyContent="center" alignItems="center" marginTop={10} marginBottom={5} width="100%" display="flex">
            <Box
                height="30px"
                width={width}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ backgroundImage: `url(${HIGHLIGHTER_SVG[highlighterBackground]})`, backgroundSize: 'cover' }}
            >
                <Typography sx={{ paddingRight: 2 }} variant="h3" noWrap>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
}
