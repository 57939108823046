import React from 'react';
import { Box, Typography } from '@mui/material';

export default function InvoicesHeader() {
    return (
        <Box className="settings-row">
            <Box className="settings-left">
                <Box className="description-column">
                    <Typography fontWeight={700}>Date</Typography>
                </Box>
                <Box className="description-column">
                    <Typography fontWeight={700}>Amount</Typography>
                </Box>
            </Box>
            <Box className="settings-left">
                <Box className="description-column">
                    <Typography fontWeight={700}>Item</Typography>
                </Box>
                <Box className="description-column">
                    <Typography fontWeight={700}>Last 4</Typography>
                </Box>
            </Box>
            <Box className="settings-right">
                <Box className="description-column">
                    <Typography fontWeight={700}>View</Typography>
                </Box>
            </Box>
        </Box>
    );
}
