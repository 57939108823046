import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

export default createTheme({
    typography: {
        fontFamily: 'Poppins',
        button: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 700,
            textTransform: 'capitalize'
        },
        h1: {
            fontSize: '24px',
            fontWeight: 700,
            fontStyle: 'italic',
            textTransform: 'uppercase'
        },
        h3: {
            fontSize: '32px',
            fontWeight: 700,
            fontStyle: 'italic',
            letterSpacing: '-1.5px'
        },
        h5: {
            fontSize: '24px',
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '12px',
            fontWeight: 400
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '150%'
        },
        caption: { fontSize: '12px', fontWeight: 400, lineHeight: '150%', letterSpacing: '0.1px' }
    },
    palette: {
        primary: { main: '#0A0EFF', light: '#349CFF' },
        secondary: { main: '#EEF4FF', light: '#E5EEF7', contrastText: '#0A0EFF' },
        error: { main: red.A400 },
        background: { default: '#FFF' },
        info: { main: '#0A0EFF' }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                body1: {
                    '@media (max-width: 420px)': {
                        fontSize: '12px'
                    },
                    '&.select-form-selected': {
                        fontWeight: 700
                    },
                    '&.option-disabled': {
                        color: '#D4D4D4'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    display: 'flex'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    color: '#000'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '2px 2px 12px 0px rgba(203, 203, 203, 0.5)'
                },
                elevation2: {
                    boxShadow: '0px 1px 2px 0px rgba(26, 32, 46, 0.3)'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    padding: '12px 16px',
                    '@media (min-width:600px)': {
                        padding: '8px 24px'
                    }
                },
                contained: {
                    padding: '12px 16px',
                    '@media (min-width:600px)': {
                        padding: '8px 24px'
                    }
                },
                containedPrimary: {
                    '&:hover': {
                        opacity: 'rgba(#0A0EFF, 0.8)'
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: '0 8px 8px',
                    position: 'relative',
                    '@media (min-width:540px)': {
                        padding: '0px 16px 8px'
                    },
                    '@media (min-width:780px)': {
                        padding: '0px 32px 8px'
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '@media (min-width:780px)': {
                        padding: '24px'
                    }
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '8px 24px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    '@media (min-width: 880px)': {
                        paddingLeft: '60px'
                    }
                },
                colorDefault: {
                    color: '#fff',
                    backgroundColor: '#050310'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#FFF',
                    margin: '0 16px',
                    minWidth: 'unset',
                    padding: '16px 6px',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Poppins',
                    '@media (min-width: 600px)': {
                        minWidth: 'unset'
                    }
                },
                textColorPrimary: {
                    opacity: 1,
                    color: '#FFF',
                    letterSpacing: '0.5px',
                    '&.Mui-selected': {
                        color: '#FFF',
                        fontWeight: '700',
                        boxShadow: 'inset 0 -4px 0 0 #FFF'
                    },
                    '&:hover': {
                        color: '#FFF',
                        boxShadow: 'inset 0 -4px 0 0 #FFF'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                inputMultiline: {
                    lineHeight: 1.4
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                asterisk: { display: 'none' }
            }
        }
    }
});
