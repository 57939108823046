// @flow
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppBar, Tab, Tabs } from '@mui/material';
import { navigate } from '../../state/navigation/navigation';
import Routes from '../../routing/routes';

export const SETTINGS_TABS = [
    { name: 'Coach Plans', path: '/subscription-plans' },
    { name: 'Manage Assistant Coaches', path: '/assistant-coaches' },
    { name: 'Payment Info', path: '/payment' },
    { name: 'Payment History', path: '/billing' }
];

export const MARKETPLACE_TABS = [{ name: 'Zapier Integrations', path: '/integrations' }];

export default function NavBar() {
    const { pathname } = useLocation();

    const [value, setValue] = useState(SETTINGS_TABS.findIndex(pathObj => pathname?.includes(pathObj.path)));

    const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);

    const headCoach = useSelector((state: any) => state.user.headCoach);
    const tabs = headCoach.isMarketplaceEnabled ? SETTINGS_TABS.concat(MARKETPLACE_TABS) : SETTINGS_TABS;
    useEffect(() => {
        const setActiveTab = () => {
            const index = tabs.findIndex(pathObj => pathname?.includes(pathObj.path));
            return setValue(index);
        };

        setActiveTab();
    }, [tabs, pathname]);

    const hiddenPaths = [
        Routes.LOGIN.path,
        Routes.CREATE_ACCOUNT.path,
        Routes.FORGOT_PASSWORD.path,
        Routes.ACCEPT_ASSISTANT_COACH.path
    ];
    const hideNav = hiddenPaths.indexOf(pathname) > -1;

    if (hideNav || !isAuthenticated) {
        return null;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const generateTabs = () =>
        tabs.map((tab, _index) => (
            <Tab
                key={`${tab.name}`}
                href={tab.path}
                component="a"
                onClick={event => {
                    event.preventDefault();
                    navigate(tab.path);
                }}
                label={tab.name}
            />
        ));

    return (
        <div data-testid="nav-bar" className="nav-bar">
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange}>
                    {generateTabs()}
                </Tabs>
            </AppBar>
        </div>
    );
}
