import React from 'react';
import Invoices from './Invoices';

export default function BillingHistory() {
    return (
        <div data-testid="billing-history" className="outer-container with-nav">
            <Invoices />
        </div>
    );
}
