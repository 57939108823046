// @flow
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import FormTextArea from '../../common/components/FormTextArea';
import useScreenDimensions from '../../common/hooks/useScreenDimensions';
import { compareDeviceWidth } from '../../common/utils/deviceSizes';

export default function InviteCoachesModal({
    isModalVisible,
    inviteCoaches,
    handleClose,
    emails,
    setEmails,
    hasEmailsError,
    emailsErrorText,
    message,
    setMessage,
    isInProgress
}: Props) {
    const { width } = useScreenDimensions();
    const { isGreaterThan675 } = compareDeviceWidth(width);

    const inputSlotProps = {
        inputLabel: { style: { fontSize: '16px' } }
    };

    return (
        <Dialog
            fullScreen={!isGreaterThan675}
            maxWidth="md"
            data-testid="dialog"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            open={isModalVisible}
        >
            <DialogTitle>
                <h2 className="invite-title">Invite Coaches</h2>
            </DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <FormTextArea
                        autoFocus={true}
                        label="Emails"
                        required={true}
                        type="email"
                        value={emails}
                        onChange={event => setEmails(event.target.value)}
                        rows="4"
                        placeholder="Enter email(s) separated by commas"
                        variant="outlined"
                        error={hasEmailsError}
                        helperText={hasEmailsError ? emailsErrorText : ''}
                        slotProps={inputSlotProps}
                    />
                </Box>
                <Box>
                    <FormTextArea
                        label="Welcome Message"
                        required={false}
                        type="text"
                        value={message}
                        onChange={event => setMessage(event.target.value)}
                        rows="4"
                        variant="outlined"
                        slotProps={inputSlotProps}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton> */}
                <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
                <PrimaryButton showActivityIndicator={isInProgress} onClick={inviteCoaches}>
                    Invite Coaches
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}
