// @flow
import React from 'react';
import { TextField } from '@mui/material';

export default function FormTextArea(props: any) {
    return (
        <TextField
            value={props.value}
            autoFocus={props.autoFocus}
            fullWidth={true}
            multiline
            rows={props.rows}
            maxRows={props.rowsMax}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            data-testid="textArea"
            required={props.required}
            className="form-textarea"
            type={props.type}
            label={props.label}
            size="small"
            variant={props.variant}
            error={props.error}
            helperText={props.helperText}
            InputProps={props.InputProps}
            inputProps={props.inputProps}
            InputLabelProps={{
                classes: {
                    root: 'input',
                    asterisk: 'label-asterisk',
                    focused: 'input-focus'
                }
            }}
            slotProps={props.slotProps}
        />
    );
}
