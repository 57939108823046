import { authenticatedFetch } from '../../api/request';

const createPaymentMethod = async (orgId: number, nonce: string) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ nonce }),
        headers: { 'Content-Type': 'application/json' }
    };
    return authenticatedFetch(`/v5/orgs/${orgId}/paymentMethod`, options);
};

export default createPaymentMethod;
