// @flow
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import Loading from '../common/components/Loading';
import useAuthentication from '../account/components/hooks/useAuthentication';

export default function PrivateRoute(props) {
    const { checkToken } = useAuthentication();
    const isAuthenticated = useSelector(
        (state: any) => state.authentication?.isAuthenticated
    );

    useEffect(() => {
        if (isAuthenticated) {
            return;
        }

        checkToken();
    }, [isAuthenticated, checkToken]);

    if (!isAuthenticated) {
        return <Loading />;
    }
    const { component: Component } = props;

    return <Route {...props} render={props => <Component {...props} />} />;
}
