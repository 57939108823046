import React from 'react';
import { Card, CardHeader, CardContent, CardActions, Box } from '@mui/material';
import Container from '@mui/material/Container';
import FormTextField from '../../common/components/FormTextField';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import useForgotPassword from './hooks/useForgotPassword';
import { navigate } from '../../state/navigation/navigation';

interface Props {
    navigateToLogin: () => void;
}

export default function ForgotPassword({ navigateToLogin }: Props) {
    const { emailHasBeenSent, email, setEmail, sending, sendPasswordReset } = useForgotPassword();

    const navToLogin = navigateToLogin || (() => navigate('/login'));
    const instructionsSent = 'Instructions have been sent to your email on how to reset your password.';

    return (
        <Box className="outer-container">
            <Container maxWidth="lg" classes={{ root: 'header-space' }}>
                <Box className="form-container">
                    <Card data-testid="card" classes={{ root: 'form-card card' }}>
                        <CardHeader
                            title="Reset Password"
                            classes={{
                                root: 'padding-desktop',
                                title: 'card-title'
                            }}
                        />
                        {emailHasBeenSent ? (
                            <CardContent
                                classes={{
                                    root: 'padding-desktop card-content'
                                }}
                            >
                                {instructionsSent}
                            </CardContent>
                        ) : (
                            <form className="form-padding padding-desktop">
                                <Box className="form-input" paddingTop={1}>
                                    <FormTextField
                                        required={true}
                                        type="email"
                                        label="Email"
                                        value={email}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            setEmail(event.target.value)
                                        }
                                        slotProps={{
                                            inputLabel: { style: { fontSize: '16px' } }
                                        }}
                                    />
                                </Box>
                            </form>
                        )}
                        <CardActions classes={{ root: 'action-container' }}>
                            <Box className="action-buttons-container">
                                {!emailHasBeenSent && (
                                    <PrimaryButton showActivity={sending} onClick={sendPasswordReset}>
                                        Send Email Link to Reset Password
                                    </PrimaryButton>
                                )}
                            </Box>
                            <Box className="action-buttons-container">
                                <TertiaryButton onClick={navToLogin}>Return to Login</TertiaryButton>
                            </Box>
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </Box>
    );
}
