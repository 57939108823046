// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Container, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import FormTextField from '../../common/components/FormTextField';
import Header from '../../common/components/Header';
import useCaptcha from './hooks/useCaptcha';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import ServerErrorAlert from '../../common/components/ServerErrorAlert';
import useNewAccountFormManager from './hooks/useNewAccountFormManager';
import { navigate } from '../../state/navigation/navigation';
import TermsOfUseCheckbox from './TermsOfUseCheckbox';

type Props = {
    navigateToLogin: () => {},
    onAccountCreate: () => {},
    instructions: string,
    style: string | null
};

export default function CreateAthleteAccount({ navigateToLogin, onAccountCreate, instructions, style }: Props) {
    const isAuthenticated = useSelector((state: any) => state.authentication?.isAuthenticated);

    const navToLogin = navigateToLogin || (() => navigate('/account/login'));
    const { isBlocked, setVerified, token, H_CAPTCHA_KEY } = useCaptcha();
    const createAccountText = 'Create Account';

    const {
        name,
        setName,
        nameValidated,
        nameErrorMessage,
        email,
        setEmail,
        emailValidated,
        emailErrorMessage,
        password,
        setPassword,
        passwordValidated,
        passwordErrorMessage,
        hasAgreed,
        setHasAgreed,
        showValidationErrors,
        isInProcess,
        createAccount,
        createAccountSuccessfulOrNotSubmitted,
        serverErrorMessages,
        serverError,
        setServerError
    } = useNewAccountFormManager(false);

    if (isAuthenticated && !onAccountCreate) {
        return null;
    }

    const hasNameError = showValidationErrors && !nameValidated;
    const hasEmailError = showValidationErrors && !emailValidated;
    const hasPasswordError = showValidationErrors && !passwordValidated;
    const hasAgreedError = showValidationErrors && !hasAgreed;
    const nameErrorText = createAccountSuccessfulOrNotSubmitted ? nameErrorMessage : serverErrorMessages.full_name;
    const emailErrorText = createAccountSuccessfulOrNotSubmitted ? emailErrorMessage : serverErrorMessages.email;
    const passwordErrorText = createAccountSuccessfulOrNotSubmitted
        ? passwordErrorMessage
        : serverErrorMessages.password;

    const handleCreateAccountViaEnterKey = event => {
        const enterKeyPressed = event.key === 'Enter' || event.keyCode === 13 || event.which === 13;

        if (enterKeyPressed && !isBlocked) {
            createAccount(token);
        }
    };

    return (
        <div className="outer-container">
            <Header />
            <Container maxWidth="lg" classes={{ root: 'header-space' }}>
                <div className="container payment-container">
                    <Card data-testid="card" classes={{ root: 'form-card card' }}>
                        <CardHeader
                            title="Create Account"
                            classes={{
                                root: 'padding-desktop',
                                title: 'card-title'
                            }}
                        />
                        <form className="form-padding padding-desktop">
                            <div className="form-input">
                                <p className={style || ''}>{instructions}</p>
                            </div>
                            <div className="form-input">
                                <FormTextField
                                    onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                    required
                                    variant="standard"
                                    type="text"
                                    label="First and Last Name"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                    error={hasNameError}
                                    helperText={hasNameError ? nameErrorText : ''}
                                />
                            </div>
                            <div className="form-input">
                                <FormTextField
                                    onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                    required
                                    variant="standard"
                                    type="email"
                                    label="Email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    error={hasEmailError}
                                    helperText={hasEmailError ? emailErrorText : ''}
                                />
                            </div>
                            <div className="form-input">
                                <FormTextField
                                    onKeyDown={event => handleCreateAccountViaEnterKey(event)}
                                    required
                                    variant="standard"
                                    type="password"
                                    label="Password"
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    error={hasPasswordError}
                                    helperText={hasPasswordError ? passwordErrorText : ''}
                                />
                            </div>
                        </form>
                        <CardContent classes={{ root: 'padding-desktop' }}>
                            <TermsOfUseCheckbox
                                hasAgreed={hasAgreed}
                                hasAgreedError={hasAgreedError}
                                onChange={() => setHasAgreed(!hasAgreed)}
                            />
                        </CardContent>
                        <CardActions classes={{ root: 'action-container' }}>
                            <HCaptcha sitekey={H_CAPTCHA_KEY} onVerify={(token, ekey) => setVerified(token)} />
                            <br />
                            <br />
                            <div className="action-buttons-container">
                                <PrimaryButton
                                    disabled={isBlocked}
                                    showActivity={isInProcess}
                                    onClick={() => createAccount(token)}
                                >
                                    {createAccountText}
                                </PrimaryButton>
                            </div>
                            <div className="horizontal-divider">
                                <div className="divider-border" />
                                <div className="divider-text">or</div>
                                <div className="divider-border" />
                            </div>
                            <div className="action-buttons-container button-margin">
                                <TertiaryButton onClick={navToLogin}>Already have an account? Login.</TertiaryButton>
                            </div>
                        </CardActions>
                    </Card>
                </div>
                <ServerErrorAlert open={serverError} handleClose={() => setServerError(false)} />
            </Container>
        </div>
    );
}
