// @flow
import { authenticatedFetch } from '../../api/request';

const checkAssistantCoachInviteToken = async (token: string) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ token: token }),
        headers: { 'Content-Type': 'application/json' }
    };
    return await authenticatedFetch(`/v5/orgs/assistantCoaches/acceptInvite`, options);
};

export default checkAssistantCoachInviteToken;
