import { useState } from 'react';

const H_CAPTCHA_KEY = '66ba7e81-27b3-4067-a728-8a256a8e39d3';
export default function useCaptcha() {
    const [isBlocked, setIsBlocked] = useState(true);
    const [token, setToken] = useState(null);
    const setVerified = captchaToken => {
        setIsBlocked(false);
        setToken(captchaToken);
    };
    return {
        isBlocked,
        setVerified,
        token,
        H_CAPTCHA_KEY
    };
}
