import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Link, Button, Typography, useTheme } from '@mui/material';
import WhiteTHLogo from '../assets/WhiteTHLogo';
import MobileNav from './MobileNav';
// eslint-disable-next-line import/no-cycle
import CONFIG from '../../api/config';
// eslint-disable-next-line import/no-cycle
import Routes from '../../routing/routes';
import useScreenDimensions from '../hooks/useScreenDimensions';

export default function Header() {
    const { pathname } = useLocation();
    const isAuthenticated = useSelector((state: any) => state.authentication.isAuthenticated);
    const { width } = useScreenDimensions();
    const isSmallScreen = width < 680;
    const theme = useTheme();

    if (Routes.CREATE_ACCOUNT.path === pathname) {
        return null;
    }

    const hideTitle = () => {
        let hiddenClass = '';

        const hiddenPaths = [
            Routes.LOGIN.path,
            Routes.CREATE_ACCOUNT.path,
            Routes.FORGOT_PASSWORD.path,
            Routes.ACCEPT_ASSISTANT_COACH.path
        ];
        if (hiddenPaths.indexOf(pathname) > -1) {
            hiddenClass = 'hide';
        }

        return hiddenClass;
    };

    const buttonTextStyle = { textTransform: 'uppercase', color: theme.palette.background.default };

    return (
        <Box data-testid="header" className="header">
            <Box className="menu">
                <Link href={CONFIG.URL_COACHHEROIC}>
                    <WhiteTHLogo className={'whiteTHLogo'} />
                </Link>
                <Typography className={hideTitle()} variant="h1">
                    Settings
                </Typography>
            </Box>
            <Box className="menu">
                {isAuthenticated && !isSmallScreen ? (
                    <Button href={CONFIG.URL_COACHHEROIC} sx={buttonTextStyle}>
                        <ArrowBack style={styles.backArrow} />
                        Back to Coach Heroic
                    </Button>
                ) : null}
                <MobileNav />
            </Box>
        </Box>
    );
}

const styles = {
    backArrow: { fontSize: 24, marginRight: 6 }
};
