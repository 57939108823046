// @flow
import { useState } from 'react';
import useFormManager from '../../../common/hooks/useFormManager';
import useValidatedField from '../../../common/hooks/useValidatedField';
import useUpdateEffect from '../../../common/hooks/useUpdateEffect';
import useSendInvites from './useSendInvites';

const defaultMessage = `Follow these steps and you’ll be set up to coach with me on TrainHeroic!`;

export default function useInviteCoachesFormManager(refreshList: () => void) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(defaultMessage);

    const { showValidationErrors, setShowValidationErrors, notNullValidator } = useFormManager();

    const {
        value: emails,
        setValue: setEmails,
        validated: emailsValidated,
        setValidated: setEmailsValidated,
        errorState: emailsErrorMessage
    } = useValidatedField(notNullValidator);

    const { sending, sent, sendInvites, resetState } = useSendInvites(setIsModalVisible, refreshList);

    const inviteFormSuccessfulOrNotSubmitted = sent === null ? true : sent;

    const onStateChange = () => {
        setEmailsValidated(inviteFormSuccessfulOrNotSubmitted);
    };

    useUpdateEffect(onStateChange, [inviteFormSuccessfulOrNotSubmitted, setEmailsValidated]);

    useUpdateEffect(resetState, [emails]);

    const isInProgress = sent || sending;

    const inviteCoaches = () => {
        const canInvite = emailsValidated && !isInProgress;

        setShowValidationErrors(true);

        if (canInvite) {
            sendInvites(emails, message);
        }
    };

    return {
        isModalVisible,
        setIsModalVisible,
        emails,
        setEmails,
        emailsValidated,
        emailsErrorMessage,
        message,
        setMessage,
        showValidationErrors,
        isInProgress,
        inviteCoaches,
        inviteFormSuccessfulOrNotSubmitted
    };
}
