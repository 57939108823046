// @flow
import React from 'react';
import AssistantCoachesHeader from './AssistantCoachesHeader';
import HeadCoachRow from './HeadCoachRow';
import AssistantCoachRow from './AssistantCoachRow';

type Props = {
    coachList: Array<any>,
    removeCoach: () => {},
    resendInvite: () => {},
    fetchCoachList: () => {},
    deleteInvite: () => {}
};

export default function AssistantCoaches({
    coachList,
    removeCoach,
    resendInvite,
    fetchCoachList,
    deleteInvite
}: Props) {
    const assistantCoaches = coachList.map((coach: any) => (
        <AssistantCoachRow
            key={coach.id}
            coach={coach}
            removeCoach={removeCoach}
            resendInvite={resendInvite}
            fetchCoachList={fetchCoachList}
            deleteInvite={deleteInvite}
        />
    ));

    return (
        <div data-testid="coach-management" className="settings-container">
            <div className="settings-header">
                <AssistantCoachesHeader />
            </div>
            <ul className="settings-list">
                <HeadCoachRow />
                {assistantCoaches}
            </ul>
        </div>
    );
}
