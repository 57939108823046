import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import getBraintreeClientToken from '../../../planManagement/api/getBraintreeClientToken';
import updatePaymentMethod from '../../api/updatePaymentMethod';
import checkAccountStatus from '../../api/checkAccountStatus';

type AccountStatus = {
    isAccountOverdue: boolean,
    overdueAmount: string | null
};

const DEFAULT_STATUS: AccountStatus = {
    isAccountOverdue: false,
    overdueAmount: null
};

export default function usePaymentInfo() {
    const [isLoading, setIsLoading] = useState(false);
    const [accountStatus, setAccountStatus] = useState(DEFAULT_STATUS);
    const [isOpen, setIsOpen] = useState(false);
    const [clientToken, setClientToken] = useState(null);
    const [clientTokenFailed, setClientTokenFailed] = useState(false);
    const [isLoadingInstance, setIsLoadingInstance] = useState(true);
    const [paymentMethodUpdating, setPaymentMethodUpdating] = useState(false);
    const [braintreeInstance, setBraintreeInstance] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [dialogText, setDialogText] = useState({ title: '', subtext: '' });
    const [paymentUpdateCompleted, setPaymentUpdateCompleted] = useState(false);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const processPaymentMethodUpdate = async () => {
        if (!braintreeInstance || !orgId) {
            return;
        }

        setPaymentMethodUpdating(true);
        setErrorMessage(null);
        braintreeInstance.requestPaymentMethod(async (error: string, payload: any) => {
            if (error) {
                setPaymentMethodUpdating(false);
                setErrorMessage('Credit card is invalid');
            } else {
                const nonce = payload.nonce;
                await fetch(`server.test/purchase/${nonce}`);
                const response = await updatePaymentMethod(orgId, nonce);

                if (response?.status && response.status !== 200) {
                    setDialogText({
                        title: 'Failure to update payment',
                        subtext: 'Please try again later or contact support.'
                    });
                } else {
                    setAccountStatus(DEFAULT_STATUS);
                    setDialogText({
                        title: 'Success',
                        subtext:
                            'Successfully updated your primary method of payment.'
                    });
                }

                setPaymentUpdateCompleted(true);
                setPaymentMethodUpdating(false);
            }
        });
    };

    const fetchAccountStatus = useCallback(async () => {
        if (!orgId) {
            return;
        }

        setIsLoading(true);

        const response = await checkAccountStatus(orgId);

        setAccountStatus(response);
        setIsLoading(false);
    }, []);

    const fetchToken = useCallback(async () => {
        setIsLoadingInstance(true);

        const response = await getBraintreeClientToken();

        if (response && !response?.token.success) {
            setClientTokenFailed(true);
            setDialogText({
                title: 'Failure to load payment information',
                subtext: 'Please try again later or contact support.'
            });
        }

        if (response && typeof response?.token === 'string') {
            setClientToken(response.token);
            setClientTokenFailed(false);
        }

        setIsLoadingInstance(false);
    }, []);

    useEffect(() => {
        fetchToken();
        fetchAccountStatus();
    }, []);

    return {
        isLoading,
        accountStatus,
        isOpen,
        setIsOpen,
        isLoadingInstance,
        braintreeInstance,
        setBraintreeInstance,
        clientToken,
        clientTokenFailed,
        setClientTokenFailed,
        paymentMethodUpdating,
        processPaymentMethodUpdate,
        paymentUpdateCompleted,
        setPaymentUpdateCompleted,
        dialogText,
        errorMessage
    };
}
