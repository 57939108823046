const config = {
    APP_ENV: 'production',
    API: 'https://api.trainheroic.com',
    IS_PRODUCTION: true,
    URL_ATHLETE: 'https://athlete.trainheroic.com',
    URL_COACHHEROIC: 'https://coach.trainheroic.com/admin/coach#',
    URL_MARKETPLACE: 'https://marketplace.trainheroic.com/#',
    URL_COACHAPP: 'https://coachapp.trainheroic.com/',
    URL_SUPPORT: 'https://support.trainheroic.com/hc/en-us',
    URL_PRICING: 'https://www.trainheroic.com/pricing/',
    URL_THANKS: 'https://www.trainheroic.com/thanks/',
    LOGIN: 'https://athlete.trainheroic.com/#login',
    REDUX_LOGGER_ENABLED: false,
    ROLLBAR_ENV: 'account-prod',
    SESSION_NAME: 'app-Session',
    AMBASSADOR_ID: '734a3122-d8cf-4c83-88b6-93407a46887b'
};

export default config;
