// @flow
import { useEffect, useState } from 'react';
import useFormManager from '../../../common/hooks/useFormManager';
import useValidatedField from '../../../common/hooks/useValidatedField';
import useUpdateEffect from '../../../common/hooks/useUpdateEffect';
import useAccountCreation from './useAccountCreation';
import useLoadingDialog from './useLoadingDialog';

const DEFAULT_COUNTRY = 'US';

export default function useNewAccountFormManager(isCoach: boolean) {
    const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY);
    const [hasAgreed, setHasAgreed] = useState(false);

    const { showValidationErrors, setShowValidationErrors, notNullValidator } = useFormManager();

    const {
        value: name,
        setValue: setName,
        validated: nameValidated,
        setValidated: setNameValidated,
        errorState: nameErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: email,
        setValue: setEmail,
        validated: emailValidated,
        setValidated: setEmailValidated,
        errorState: emailErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: password,
        setValue: setPassword,
        validated: passwordValidated,
        setValidated: setPasswordValidated,
        errorState: passwordErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: organization,
        setValue: setOrganization,
        validated: organizationValidated,
        setValidated: setOrganizationValidated,
        errorState: organizationErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: expectedAccountSize,
        setValue: setExpectedAccountSize,
        validated: expectedAccountSizeValidated,
        setValidated: setExpectedAccountSizeValidated,
        errorState: expectedAccountSizeErrorMessage
    } = useValidatedField(notNullValidator);

    const {
        value: phoneNumber,
        setValue: setPhoneNumber,
        validated: phoneNumberValidated,
        setValidated: setPhoneNumberValidated,
        errorState: phoneNumberErrorMessage
    } = useValidatedField(notNullValidator);

    const { isOpen, progress, setIsOpen, startProgress } = useLoadingDialog();

    const {
        createNewCoachAccount,
        createNewAthleteAccount,
        creatingInProcess,
        creatingComplete,
        setCreatingComplete,
        setCreatingInProcess,
        serverErrorMessages,
        serverError,
        setServerError
    } = useAccountCreation();

    const createAccountSuccessfulOrNotSubmitted = creatingComplete === null ? true : creatingComplete;

    const onCreateAccountStateChange = () => {
        if (Object.keys(serverErrorMessages).length > 0) {
            setNameValidated(serverErrorMessages.full_name === undefined);
            setEmailValidated(serverErrorMessages.email === undefined);
            setPasswordValidated(serverErrorMessages.password === undefined);
            setOrganizationValidated(serverErrorMessages.organization === undefined);
            setPhoneNumberValidated(serverErrorMessages.phoneNumber === undefined);
            setExpectedAccountSizeValidated(serverErrorMessages.expectedAccountSize === undefined);
        } else {
            setNameValidated(createAccountSuccessfulOrNotSubmitted);
            setEmailValidated(createAccountSuccessfulOrNotSubmitted);
            setPasswordValidated(createAccountSuccessfulOrNotSubmitted);
            setOrganizationValidated(createAccountSuccessfulOrNotSubmitted);
            setPhoneNumberValidated(createAccountSuccessfulOrNotSubmitted);
            setExpectedAccountSizeValidated(createAccountSuccessfulOrNotSubmitted);
        }
    };

    useUpdateEffect(onCreateAccountStateChange, [
        createAccountSuccessfulOrNotSubmitted,
        setNameValidated,
        setEmailValidated,
        setPasswordValidated,
        setOrganizationValidated,
        setPhoneNumberValidated,
        serverErrorMessages
    ]);

    useEffect(() => {
        setCreatingInProcess(false);
        setCreatingComplete(null);
    }, [name, email, password, organization, phoneNumber, setCreatingComplete, setCreatingInProcess]);

    const isInProcess = creatingComplete || creatingInProcess;

    const createAthleteAccount = token => {
        const formFieldsValid = nameValidated && emailValidated && passwordValidated && hasAgreed;

        const canCreateAccount = formFieldsValid && !isInProcess;

        setShowValidationErrors(true);

        if (canCreateAccount) {
            createNewAthleteAccount(name, email, password, token);
        }
    };

    const createAccount = async token => {
        if (!isCoach) {
            return createAthleteAccount(token);
        }

        const formFieldsValid =
            nameValidated &&
            emailValidated &&
            passwordValidated &&
            organizationValidated &&
            phoneNumberValidated &&
            hasAgreed;

        const canCreateAccount = formFieldsValid && !isInProcess;

        setShowValidationErrors(true);

        /* eslint-disable-next-line */
        const formattedNumber = phoneNumber.replace(/[\(\)\-\+ ]+/g, '');

        const expectedAccountSizeAsInt = parseInt(expectedAccountSize);

        const coachUser = {
            fullName: name,
            email,
            password,
            phoneNumber: formattedNumber,
            country: countryCode,
            organization,
            expectedAccountSize: expectedAccountSizeAsInt,
            accountCreationLocation: 'account',
            token
        };

        if (canCreateAccount) {
            startProgress();
            const response = await createNewCoachAccount(coachUser);
            if (response && response.length > 0) {
                setIsOpen(false);
            }
        }
    };

    return {
        name,
        setName,
        nameValidated,
        nameErrorMessage,
        email,
        setEmail,
        emailValidated,
        emailErrorMessage,
        password,
        setPassword,
        passwordValidated,
        passwordErrorMessage,
        hasAgreed,
        setHasAgreed,
        expectedAccountSize,
        setExpectedAccountSize,
        expectedAccountSizeValidated,
        setExpectedAccountSizeValidated,
        expectedAccountSizeErrorMessage,
        showValidationErrors,
        isInProcess,
        isOpen,
        progress,
        createAccount,
        createAccountSuccessfulOrNotSubmitted,
        serverErrorMessages,
        organization,
        setOrganization,
        organizationValidated,
        organizationErrorMessage,
        setCountryCode,
        phoneNumber,
        setPhoneNumber,
        phoneNumberValidated,
        phoneNumberErrorMessage,
        serverError,
        setServerError
    };
}
