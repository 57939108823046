// @flow
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import useScreenDimensions from '../../common/hooks/useScreenDimensions';
import { compareDeviceWidth } from '../../common/utils/deviceSizes';

type Props = {
    coach: any,
    isModalOpen: boolean,
    removeCoach: () => {},
    handleClose: () => {}
};

export default function RemoveCoachModal({ coach, isModalOpen, removeCoach, handleClose }: Props) {
    const { width } = useScreenDimensions();
    const { isGreaterThan675 } = compareDeviceWidth(width);

    return (
        <Dialog
            fullScreen={!isGreaterThan675}
            maxWidth="sm"
            data-testid="dialog"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            open={isModalOpen}
        >
            <DialogTitle>
                <h2 className="invite-title">Are you sure?</h2>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`This is a permanent action to remove ${coach.fullName} from your
                    org.`}
                    <br />
                    Do you want to&nbsp;continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <TertiaryButton data-testid="cancel-button" onClick={handleClose}>
                    Cancel
                </TertiaryButton>
                <PrimaryButton data-testid="confirm-delete-button" onClick={() => removeCoach(coach.id)}>
                    Yes, delete this coach
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}
