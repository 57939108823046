import React, { Children } from 'react';
import { Box } from '@mui/material';

interface Props {
    selectedStep: number;
    children: React.ReactNode;
}

export default function Checkout({ selectedStep, children }: Props) {
    return (
        <>
            {Children.map(children, (child, index) => {
                const key = `checkout-step-${index}`;
                return (
                    <Box key={key} hidden={selectedStep !== index} id={key}>
                        {child}
                    </Box>
                );
            })}
        </>
    );
}
