import React from 'react';

interface Props {
    className?: string;
}

export default function BlackTHTrainHeroicLogo({ className }: Props) {
    return (
        <svg className={className} width="250px" height="33px" viewBox="0 0 300 46" version="1.1">
            <title>Logos / TH TrainHeroic / TH TrainHeroic Black</title>
            <g
                id="Logos-/-TH-TrainHeroic-/-TH-TrainHeroic-Black"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <polygon
                    id="Fill-4"
                    fill="#050310"
                    points="76.4658622 8.90985959 57.0883864 8.90985959 56.455883 12.3651264 64.0167437 12.3651264 59.6409557 37.2666756 63.901442 37.2666756 68.27723 12.3651264 75.8333587 12.3651264"
                ></polygon>
                <path
                    d="M92.0461722,17.3240005 C91.7685647,18.8606211 91.0816439,20.1033878 90.0044953,21.0179151 C88.9279777,21.9316539 87.43079,22.3949114 85.5542581,22.3949114 L80.5997531,22.3949114 L82.3583019,12.4048274 L87.3841015,12.4048274 C89.0243516,12.4048274 90.2483483,12.7286502 91.0218637,13.3669897 C91.787808,13.9994932 92.1603698,14.8827897 92.1603698,16.0673535 C92.1603698,16.4468555 92.1217256,16.8709956 92.0461722,17.3240005 M96.5702279,15.7889573 C96.5702279,13.7124218 95.8233691,12.0254831 94.3507876,10.7749876 L94.3506298,10.7748298 C92.8927173,9.53742601 90.7228047,8.90981227 87.9011454,8.90981227 L78.7263758,8.90981227 L73.6816484,37.2667861 L77.9419769,37.2667861 L79.9707198,25.9296749 L83.8304102,25.9296749 L88.444846,37.2667861 L93.4682797,37.2667861 L88.5374344,25.5613718 C90.7021419,25.0459051 92.4649494,24.0662346 93.781219,22.6463354 C95.1686255,21.1505674 96.0517643,19.3975392 96.4080799,17.4244755 C96.5172301,16.6583735 96.5702279,16.1233482 96.5702279,15.7889573"
                    id="Fill-6"
                    fill="#050310"
                ></path>
                <path
                    d="M104.059794,27.645715 L110.942251,14.7503741 L113.260747,27.645715 L104.059794,27.645715 Z M109.590965,9.06904224 L94.3733432,37.2667072 L98.9097019,37.2667072 L102.21118,31.1009818 L113.891515,31.1009818 L115.005258,37.2667072 L119.467167,37.2667072 L114.218177,9.06904224 L109.590965,9.06904224 Z"
                    id="Fill-8"
                    fill="#050310"
                ></path>
                <polygon
                    id="Fill-10"
                    fill="#050310"
                    points="126.540227 8.90985959 121.561432 36.8960066 121.495342 37.2666756 125.756144 37.2666756 130.800871 8.90985959"
                ></polygon>
                <polygon
                    id="Fill-12"
                    fill="#050310"
                    points="152.91297 8.87003238 149.138455 30.2077955 138.902625 9.04811127 138.816346 8.87003238 134.734254 8.87003238 129.689684 37.2667545 133.950013 37.2667545 137.763172 15.8906626 147.960831 37.0880447 148.046795 37.2667545 152.129202 37.2667545 157.174087 8.87003238"
                ></polygon>
                <polygon
                    id="Fill-14"
                    fill="#050310"
                    points="178.953656 9.02681751 177.004568 20.0057204 166.423464 20.0057204 168.372552 9.02681751 161.57038 9.02681751 156.637958 36.9512915 163.439972 36.9512915 165.468715 25.3756902 176.049819 25.3756902 174.021076 36.9512915 180.823406 36.9512915 185.755671 9.02681751"
                ></polygon>
                <polygon
                    id="Fill-16"
                    fill="#050310"
                    points="202.900394 25.3360838 203.815236 20.2046041 194.706083 20.2046041 195.700265 14.3969293 206.00313 14.3969293 206.957722 9.02680174 189.852842 9.02680174 184.920577 36.9512757 202.025141 36.9512757 202.97989 31.5813059 192.67734 31.5813059 193.791082 25.3360838"
                ></polygon>
                <path
                    d="M221.596075,9.02683329 C228.437995,9.02683329 231.182681,12.9649163 230.307429,17.8178422 C229.710731,21.3183779 227.204692,24.6200142 222.669911,25.8929077 L222.669911,25.8929077 L227.164944,36.9513072 L219.487677,36.9513072 L215.549437,26.4099516 L213.91865,26.4099516 L212.049059,36.9513072 L205.246887,36.9513072 L210.179467,9.02683329 Z M220.203779,14.5958603 L215.987142,14.5958603 L214.753839,21.5969318 L218.970476,21.5969318 C221.51642,21.5969318 222.948465,20.2841323 223.346264,18.096396 C223.744063,15.9881565 222.749565,14.5958603 220.203779,14.5958603 L220.203779,14.5958603 Z"
                    id="Combined-Shape"
                    fill="#050310"
                ></path>
                <path
                    d="M244.309541,30.9447961 C240.371458,30.9447961 238.22347,28.5580026 238.22347,24.7790707 C238.22347,19.4488492 241.604603,14.8743195 247.054227,14.8743195 C250.912971,14.8743195 253.10055,17.2212069 253.10055,21.0001388 C253.10055,26.2907697 249.719574,30.9447961 244.309541,30.9447961 M247.929479,8.62909747 C238.263218,8.62909747 231.421298,15.5902629 231.421298,25.4951718 C231.421298,32.4563373 236.11523,37.2297665 243.275453,37.2297665 C253.060959,37.2297665 259.862973,30.1491983 259.862973,20.2444471 C259.862973,13.3228722 255.208947,8.62909747 247.929479,8.62909747"
                    id="Fill-20"
                    fill="#050310"
                ></path>
                <polygon
                    id="Fill-22"
                    fill="#050310"
                    points="264.994705 9.02681751 260.062125 36.9512915 266.864297 36.9512915 271.796878 9.02681751"
                ></polygon>
                <path
                    d="M284.486332,30.9447961 C280.309443,30.9447961 278.002462,27.8023109 278.877556,22.949385 C279.712745,18.0964591 283.094035,14.9935646 287.270924,14.9935646 C289.89605,14.9935646 291.805705,16.1869613 292.521649,18.4941003 L300.000016,18.4941003 C299.482972,12.4477777 295.107184,8.70859416 288.464163,8.70859416 C280.230104,8.70859416 273.427932,14.5957656 271.956139,22.949385 C270.484346,31.3026889 275.217869,37.150112 283.451928,37.150112 C290.094949,37.150112 295.783379,33.4508346 298.448726,27.404512 L290.970201,27.404512 C289.418912,29.711651 287.072024,30.9447961 284.486332,30.9447961"
                    id="Fill-24"
                    fill="#050310"
                ></path>
                <path
                    d="M44.9177903,5.68434189e-14 L30.9801595,5.68434189e-14 L28.5550373,13.769489 C28.5227023,13.9527731 28.3644976,14.0870026 28.1783744,14.0890531 L21.3161066,14.1650797 C21.073831,14.1677611 20.8888119,13.9496184 20.9307685,13.7111284 L23.1151924,1.30838301 C23.2353838,0.625720965 22.7102956,5.68434189e-14 22.0170655,5.68434189e-14 L8.07722641,5.68434189e-14 L-4.73194579e-05,45.8588639 L14.3319123,45.8588639 C14.8737201,45.8588639 15.3371354,45.469898 15.4311434,44.93645 L17.8717233,31.080524 C17.9285066,30.7581208 17.8414388,30.4270423 17.6333909,30.1741987 L12.0201991,23.3512061 C11.8817108,23.1829065 12.001429,22.929432 12.219414,22.929432 L33.2454997,22.929432 C33.9396761,22.929432 34.465553,23.5560993 34.3452039,24.2397078 L33.3920322,29.6498991 C33.2980243,30.1835049 32.8344513,30.5724708 32.2926435,30.5724708 L25.9199744,30.5724708 C25.7322739,30.5724708 25.5715454,30.7073313 25.5390527,30.8921926 L22.9028858,45.8588639 L37.125222,45.8588639 C37.7302801,45.8588639 38.247955,45.4243136 38.3530042,44.8284039 L46.01781,1.31074898 C46.1383169,0.626825085 45.6121245,5.68434189e-14 44.9177903,5.68434189e-14"
                    id="Fill-1"
                    fill="#050310"
                ></path>
            </g>
        </svg>
    );
}
