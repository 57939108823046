import React from 'react';
import { Box, Typography } from '@mui/material';
import BlackTHTrainHeroicLogo from '../../common/assets/BlackTHTrainHeroicLogo';

export default function ReceiptFooter() {
    return (
        <Box data-testid="receipt-footer">
            <Typography textAlign="center">
                Questions on your subscription, or the app? Please email{' '}
                <a href="mailto:support@trainheroic.com">support@trainheroic.com</a>
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" marginBottom={10} marginTop={5}>
                <a href="http://trainheroic.com">
                    <BlackTHTrainHeroicLogo />
                </a>
                <Box marginBottom={2}>
                    <Typography textAlign="center">
                        Made with love, sweat, and deliberate practice in Denver,&nbsp;CO
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
