// @flow
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

interface Props {
    hasAgreed: bool;
    hasAgreedError: bool;
    onChange: () => void;
}

export default function TermsOfUseCheckbox({hasAgreed, hasAgreedError, onChange}: Props) {
       return (
        <div className="terms-checkbox-container">
            <FormControl error={hasAgreedError}>
                <FormControlLabel
                    required
                    control={<Checkbox checked={hasAgreed} onChange={onChange} />}
                    label={<div className="terms-container narrow body1">
                        <span>
                            I have read and agree to the &nbsp;
                            <a
                                href="https://legal.trainheroic.com/terms-of-use.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="terms-link"
                            >
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://legal.trainheroic.com/privacy-policy.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="terms-link"
                            >
                                Privacy&nbsp;Policy
                            </a>
                            .
                        </span>
                    </div>}  
                />
                {hasAgreedError && <FormHelperText error >Please check the box to continue</FormHelperText>}
            </FormControl>
        </div>
    );
}
