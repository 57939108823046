import React from 'react';
import Button from '@mui/material/Button';

export default function TertiaryButton(props: any) {
    return (
        <Button rel="noopener noreferrer" variant="text" color="info" {...props}>
            {props.children}
        </Button>
    );
}
