import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import InvoiceRow from './InvoiceRow';
import InvoicesHeader from './InvoicesHeader';
import useInvoices, { Invoice } from './hooks/useInvoices';
import CardWithBoxShadow from '../../common/components/CardWithBoxShadow';

export default function Invoices() {
    const { invoices, isLoading } = useInvoices();
    return (
        <Container maxWidth="lg">
            <Box className="management-header">
                <Typography variant="h1" className="title">
                    Payment History
                </Typography>
            </Box>
            <CardWithBoxShadow>
                <Box data-testid="invoices-table" className="settings-container invoice-list">
                    <Box className="settings-header">
                        <InvoicesHeader />
                    </Box>
                    <Box className="settings-list">
                        {invoices.map((invoice: Invoice) => (
                            <InvoiceRow
                                key={invoice.id}
                                id={invoice.id}
                                date={invoice.dateCreated}
                                totalTransactionAmount={invoice.totalTransactionAmount}
                                item={invoice.note}
                                last4={invoice.last4}
                                isRefund={Boolean(invoice.isRefund)}
                            />
                        ))}
                        {isLoading && <Box className="settings-row">Loading invoices...</Box>}
                        {invoices.length === 0 && !isLoading && (
                            <Box className="settings-row">There aren&apos;t any invoices to view at this time.</Box>
                        )}
                    </Box>
                </Box>
            </CardWithBoxShadow>
        </Container>
    );
}
