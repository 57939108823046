import React from 'react';
import { useParams } from 'react-router';
import { Box, Container, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import TertiaryButton from '../../common/components/TertiaryButton';
import { navigate } from '../../state/navigation/navigation';
import ReceiptHeader from './ReceiptHeader';
import ReceiptDetail from './ReceiptDetail';
import ReceiptFooter from './ReceiptFooter';
import useReceipt from './hooks/useReceipt';

interface RouteParams {
    id?: string;
}

export default function Receipt() {
    const { id } = useParams<RouteParams>();
    const receipt = useReceipt(id);

    if (!receipt) {
        return (
            <Typography className="receipt-loading-dialog" data-testid="receipt-loading-dialog">
                Loading receipt information...
            </Typography>
        );
    }

    return (
        <Container data-testid="receipt-container">
            <Box marginTop={15}>
                <Box className="back-action">
                    <TertiaryButton onClick={() => navigate('/billing')}>
                        <ChevronLeft color="primary" />
                        Back to Invoices
                    </TertiaryButton>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box width="80%" maxWidth={650}>
                        <ReceiptHeader />
                        <ReceiptDetail receipt={receipt} />
                        <ReceiptFooter />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}
