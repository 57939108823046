import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import HighlightsBullets from './HighlightsBullets';
import { PADDING_INCREMENT } from '../../account/constants';

const StyledContainer = styled(Box)({
    backgroundColor: '#000000',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: PADDING_INCREMENT * 6,
    paddingRight: PADDING_INCREMENT * 4,
    paddingLeft: PADDING_INCREMENT * 4,
    paddingBottom: PADDING_INCREMENT * 6,
    minHeight: '30vh'
});

const Title = styled(Typography)({
    color: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: PADDING_INCREMENT * 4,
    fontStyle: 'normal',
    lineHeight: '133%'
});

export default function Footer() {
    return (
        <StyledContainer>
            <Title variant="h3">Highlights</Title>
            <HighlightsBullets />
        </StyledContainer>
    );
}
