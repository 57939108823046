// @flow
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

type Props = {
    isOpen: boolean,
};

export default function SuccessAlert({ isOpen }: Props) {
    const redirectToTrainHeroic = () => {
        window.location.replace('http://www.trainheroic.com');
    };

    return (
        <div>
            <Dialog data-testid="dialog" open={isOpen} onClose={redirectToTrainHeroic}>
                <DialogTitle>Parent or legal guardian consent has been granted</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your child’s account is now activated and you will receive a confirmation email&nbsp;shortly.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={redirectToTrainHeroic} color="primary" variant="contained">
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
