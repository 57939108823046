import { useEffect, useState } from 'react';

export default function useValidatedField(getErrorMessage: (text: string) => string | null) {
    const [value, setValue] = useState('');
    const [validated, setValidated] = useState(false);
    const [errorState, setErrorState] = useState<string | null>(null);

    useEffect(() => {
        const errorMessage = getErrorMessage(value);
        const isValueValid = errorMessage === null;

        setValidated(isValueValid);
        setErrorState(errorMessage);
    }, [value, getErrorMessage]);

    return { value, setValue, validated, setValidated, errorState };
}
