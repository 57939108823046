// @flow
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import getMinorInformation from '../api/getMinorInformation';
import { getQueryParams } from '../../common/utils/locationParams';
import useFormManager from '../../common/hooks/useFormManager';
import useValidatedField from '../../common/hooks/useValidatedField';
import useUpdateEffect from '../../common/hooks/useUpdateEffect';
import grantParentGuardianConsent from '../api/grantParentGuardianConsent';

export const RESPONSE_TYPES = {
    INVALID_TOKEN: 'invalid-token',
    INVALID_ACCOUNT: 'invalid-account',
    VALID: 'valid',
    LOADING: 'loading'
};

export default function useGrantParentalConsent() {
    const [isLoading, setIsLoading] = useState(true);
    const [minorInformation, setMinorInformation] = useState({});
    const [inProcess, setInProcess] = useState(false);
    const [submitted, setSubmitted] = useState(null);
    const [serverError, setServerError] = useState(false);
    const [serverErrorMessages, setServerErrorMessages] = useState({});
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    const [hasSigned, setHasSigned] = useState(false);
    const [isInvalidAlertOpen, setIsInvalidAlertOpen] = useState(false);
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);

    const { showValidationErrors, setShowValidationErrors } = useFormManager();

    const {
        value: firstName,
        setValue: setFirstName,
        validated: firstNameValidated,
        setValidated: setFirstNameValidated,
        errorState: firstNameErrorMessage
    } = useValidatedField((text: string) =>
        text.length === 0 ? 'Please enter parent or guardian’s first name' : null
    );

    const {
        value: lastName,
        setValue: setLastName,
        validated: lastNameValidated,
        setValidated: setLastNameValidated,
        errorState: lastNameErrorMessage
    } = useValidatedField((text: string) => (text.length === 0 ? 'Please enter parent or guardian’s last name' : null));

    const {
        value: relationship,
        setValue: setRelationship,
        validated: relationshipValidated,
        setValidated: setRelationshipValidated,
        errorState: relationshipErrorMessage
    } = useValidatedField((text: string) => (text.length === 0 ? 'Please enter relationship to child' : null));

    const queryParams = getQueryParams(useLocation());
    const emailCommunicationKey = queryParams.emailCommunicationKey ? queryParams.emailCommunicationKey : null;
    const successfulOrNotSubmitted = submitted === null ? true : submitted;

    const onFormStateChange = () => {
        if (Object.keys(serverErrorMessages).length > 0) {
            setFirstNameValidated(serverErrorMessages.firstName === undefined);
            setLastNameValidated(serverErrorMessages.lastName === undefined);
            setRelationshipValidated(serverErrorMessages.relationship === undefined);
        } else {
            setFirstNameValidated(successfulOrNotSubmitted);
            setLastNameValidated(successfulOrNotSubmitted);
            setRelationshipValidated(successfulOrNotSubmitted);
        }
    };

    useUpdateEffect(onFormStateChange, [
        successfulOrNotSubmitted,
        setFirstNameValidated,
        setLastNameValidated,
        setRelationshipValidated,
        serverErrorMessages
    ]);

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            const response = await getMinorInformation(emailCommunicationKey);
            if (response.status === 404) {
                setIsInvalidAlertOpen(true);
            } else if (!response.status) {
                setMinorInformation(response);
            } else {
                setServerError(true);
            }

            setIsLoading(false);
        };

        load();
    }, [emailCommunicationKey]);

    const handleEnterKey = event => {
        const enterKeyPressed = event.key === 'Enter' || event.keyCode === 13 || event.which === 13;

        if (enterKeyPressed) {
            onSubmit();
        }
    };

    useEffect(() => {
        setInProcess(false);
        setSubmitted(null);
    }, [firstName, lastName, relationship, setSubmitted, setInProcess]);

    const isInProcess = submitted || inProcess;

    const grantConsent = async (firstName: string, lastName: string, relationship: string) => {
        setSubmitted(null);
        setInProcess(true);
        const response = await grantParentGuardianConsent(emailCommunicationKey, firstName, lastName, relationship);
        if (!response.status) {
            handleSuccessfulResponse();
        } else if (Array.isArray(response)) {
            handleUserInputError(response);
        } else {
            handleServerError();
        }

        setInProcess(false);
    };

    const handleSuccessfulResponse = async () => {
        setInProcess(false);
        setIsSuccessAlertOpen(true);
        setSubmitted(true);
    };

    const handleUserInputError = (response: []) => {
        setSubmitted(false);
        const errorsToDisplay = convertServerResponse(response);
        setServerErrorMessages(errorsToDisplay);
    };

    const handleServerError = () => {
        setServerError(true);
        setSubmitted(false);
    };

    const convertServerResponse = (errorMessages: []) =>
        errorMessages.reduce((acc, error) => {
            const errorKeys = Object.keys(error);
            const errorValues = Object.values(error);

            if (errorKeys.length) {
                if (!acc[errorKeys[0]]) {
                    acc[errorKeys[0]] = errorValues[0];
                }
            }
            return acc;
        }, {});

    const onSubmit = useCallback(() => {
        const formFieldsValid =
            firstNameValidated && lastNameValidated && relationshipValidated && hasAgreedToTerms && hasSigned;
        setShowValidationErrors(true);
        if (formFieldsValid && !isInProcess) {
            grantConsent(firstName, lastName, relationship);
        }
    }, [
        firstNameValidated,
        lastNameValidated,
        relationshipValidated,
        firstName,
        lastName,
        relationship,
        hasAgreedToTerms,
        hasSigned
    ]);

    return {
        isLoading,
        minorInformation,
        firstName,
        setFirstName,
        firstNameValidated,
        firstNameErrorMessage,
        lastName,
        setLastName,
        lastNameValidated,
        lastNameErrorMessage,
        relationship,
        setRelationship,
        relationshipValidated,
        relationshipErrorMessage,
        showValidationErrors,
        isInProcess,
        onSubmit,
        successfulOrNotSubmitted,
        serverErrorMessages,
        serverError,
        setServerError,
        handleEnterKey,
        hasAgreedToTerms,
        setHasAgreedToTerms,
        hasSigned,
        setHasSigned,
        isInvalidAlertOpen,
        isSuccessAlertOpen
    };
}
