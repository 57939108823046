// @flow
import { authenticatedFetch } from '../../api/request';

const validateEmails = async (emails: string) => {
    const path = '/v5/emails/validate';
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ emails })
    };
    return await authenticatedFetch(path, requestOptions);
};

export default validateEmails;
