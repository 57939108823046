// @flow

export const athleteTypes = {
    label: 'Which option best describes your athletes?',
    selections: [
        {
            value: 'competitive-athletes',
            label: 'Competitive Adult Athletes'
        },
        {
            value: 'student-athletes',
            label: 'Student or Youth Athletes'
        },
        {
            value: 'tactical',
            label: 'Tactical (Military, Police, Fire, EMT)'
        },
        {
            value: 'private-clients',
            label: 'Private Training Clients/General Population'
        }
    ]
};

export const athleteTypesPartTwo = {
    'competitive-athletes': {
        label: `Please select the option that best describes your athletes’ sport`,
        selections: [
            {
                value: 'weightlifting',
                label: 'WeightLifting, Powerlifting, Strongman'
            },
            {
                value: 'crossfit',
                label: 'Crossfit'
            },
            {
                value: 'other',
                label: 'Other Sport'
            }
        ]
    },
    'student-athletes': {
        label: 'Please select the option that best describes your role',
        selections: [
            {
                value: 'college-sc-coach',
                label: 'College S&C Coach'
            },
            {
                value: 'hs-sc-coach',
                label: 'High School S&C Coach'
            },
            {
                value: 'sport-coach',
                label: 'Sport Coach'
            }
        ]
    },
    tactical: {
        label: 'Are you training active duty military personnel?',
        selections: [
            {
                value: 'active-duty-yes',
                label: 'Yes'
            },
            {
                value: 'active-duty-no',
                label: 'No'
            }
        ]
    },
    'private-clients': {
        label: 'Do you primarily train 1:1 or in group settings (classes)?',
        selections: [
            {
                value: '1:1',
                label: '1:1'
            },
            {
                value: 'group',
                label: 'Group'
            },
            {
                value: 'both',
                label: 'Both'
            }
        ]
    }
};

export const athleteSettings = {
    label: 'How do you intend to train your athletes with TrainHeroic?',
    selections: [
        {
            value: 'in-person',
            label: 'In Person'
        },
        {
            value: 'remotely',
            label: 'Remotely'
        }
    ]
};

export const athleteTotals = {
    label: 'How many athletes do you train?',
    selections: [
        {
            value: 'less-than-10',
            label: 'Less than 10'
        },
        {
            value: '10-30',
            label: '10-30'
        },
        {
            value: '31-100',
            label: '31-100'
        },
        {
            value: '101+',
            label: '101+'
        }
    ]
};

export const coachPlanHighlights = [
    'Risk-free 14 day trial and plans start at just $9.99',
    'Reach more clients by selling your programs in the TrainHeroic Marketplace',
    'Keep clients engaged with the top Athlete app experience',
    'Our expert team will help get you started and we’re here to help as you grow',
    'Coach in any context: remote, in-person, groups, 1:1'
];

export const coachPlanHighlightsSingleColumn = [
    'Risk-free 14 day trial and plans start at just $9.99',
    'Keep clients engaged with the top Athlete app experience',
    'Coach in any context: remote, in-person, groups, 1:1',
    'Reach more clients by selling your programs in the TrainHeroic Marketplace',
    'Our expert team will help get you started and we’re here to help as you grow'
];

export const PADDING_INCREMENT = 8;
