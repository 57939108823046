import React from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

interface Props {
    activeStep: number;
    steps: string[];
}

export default function CheckoutStepper({ activeStep, steps }: Props) {
    return (
        <Box width="100%">
            <Stepper activeStep={activeStep}>
                {steps.map((label: string) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}
