import React from 'react';
import DropIn from 'braintree-web-drop-in-react';
import { Box, CardContent, CircularProgress, Link, Typography } from '@mui/material';
import FailureDialog from '../../common/components/FailureDialog';
import SuccessDialog from './SuccessDialog';
import { BRAINTREE_OPTIONS } from '../constants';
import CONFIG from '../../api/config';

type Props = {
    isLoading: boolean;
    clientToken: string | null;
    setBraintreeInstance: (instance: any) => void;
    handleClose: () => void;
    paymentSuccess: boolean;
    paymentFailed: boolean;
    removeHorizontalPadding?: boolean;
};

export default function BraintreeDropInCard({
    isLoading,
    clientToken,
    setBraintreeInstance,
    handleClose,
    paymentFailed,
    paymentSuccess,
    removeHorizontalPadding = false
}: Props) {
    return (
        <>
            <Box
                className={`${
                    removeHorizontalPadding ? 'outer-container' : 'card-content padding-desktop'
                } padding-bottom-desktop`}
            >
                {isLoading ? (
                    <CardContent
                        classes={{
                            root: 'padding-desktop card-content'
                        }}
                    >
                        <Box className="order-summary card-loading-container">
                            <CircularProgress data-testid="circularProgress" size={32} color="inherit" />
                        </Box>
                    </CardContent>
                ) : (
                    <Box
                        data-testid="dropIn"
                        style={removeHorizontalPadding ? { paddingLeft: 0, paddingRight: 0 } : {}}
                    >
                        <DropIn
                            options={{
                                ...BRAINTREE_OPTIONS,
                                authorization: clientToken
                            }}
                            onInstance={(instance: any) => setBraintreeInstance(instance)}
                        />
                    </Box>
                )}
            </Box> 
            <SuccessDialog
                title={'Success!'}
                subtext={'Your account has been successfully upgraded.'}
                isOpen={paymentSuccess}
                handleClose={handleClose}
            />
            <FailureDialog
                title={'Failed to Process Payment'}
                subtext={
                    <Box>
                        <Typography>
                            Please try again later or
                            <Box>
                                <Link href={CONFIG.URL_SUPPORT} className="contact-support">
                                    {' '}
                                    contact support{' '}
                                </Link>
                            </Box>
                            with questions.
                        </Typography>
                    </Box>
                }
                isOpen={paymentFailed}
                handleClose={handleClose}
            />
        </>
    );
}
