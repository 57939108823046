import Rollbar from 'rollbar';

import CONFIG from './api/config';

export const rollbar = new Rollbar({
    accessToken: '33c8c5a5a11f4c51ad6f7182501a8ea5',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: CONFIG.ROLLBAR_ENV
    }
});

export const rollbarSetUserContext = userId => {
    rollbar.configure({
        payload: {
            person: { id: userId }
        }
    });
};