// @flow
import React from 'react';
import { Container } from '@mui/material';
import ZapierTokenRow from './ZapierTokenRow';

export default function IntegrationApiTokens() {
    return (
        <div className="outer-container with-nav">
            <Container maxWidth="lg">
                <div className="zapier-container">
                    <img
                        src="https://static.trainheroic.com/images/ZapierDiagram2021.png"
                        width="100%"
                        alt="Zapier Integrations Diagram"
                    />
                    <ZapierTokenRow />
                </div>
            </Container>
        </div>
    );
}
