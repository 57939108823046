import { useEffect, useRef } from 'react';

export default function useUpdateEffect(callback, dependencies) {
    const effectRef = useRef(false);

    useEffect(() => {
        if (effectRef.current) {
            return callback();
        }

        effectRef.current = true;
        /* eslint-disable-next-line */
    }, dependencies);
}
