// @flow
import React from 'react';
import CoachSettingsRowTemplate from './CoachSettingsRowTemplate';

export default function AssistantCoachesHeader(props: {}) {
    const headerStyles = {
        fontWeight: '700'
    };

    return (
        <CoachSettingsRowTemplate
            firstColumn={<div>Coach</div>}
            secondColumn={<div>Role</div>}
            thirdColumn={<div>Status</div>}
            fourthColumn={<div>Price</div>}
            actionColumn={<div>Actions</div>}
            rowStyles={headerStyles}
        />
    );
}
