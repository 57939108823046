// @flow
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import validateEmails from '../../api/validateEmails';
import inviteCoachesToOrg from '../../api/inviteCoachesToOrg';

export default function useSendInvites(
    setIsModalVisible: () => {},
    refreshList: () => {}
) {
    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(null);

    const sendInvites = async (emails: string, message: string) => {
        setSent(null);
        setSending(true);

        const validatedEmails = await validateEmails(emails);

        if (validatedEmails[0].emails) {
            setSending(false);
            setSent(false);
            return;
        }

        const invitation = {
            emails: validatedEmails,
            message
        };

        const response = await inviteCoachesToOrg(orgId, invitation);

        setSending(false);
        setSent(true);
        setIsModalVisible(false);
        refreshList();
    };

    const resetState = useCallback(() => {
        setSending(false);
        setSent(null);
    }, [setSent, setSending]);

    return {
        sending,
        sent,
        sendInvites,
        resetState
    };
}
