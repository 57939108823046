import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@mui/material';
import PrimaryButton from '../../common/components/PrimaryButton';

type Props = {
    title: string,
    subtext: string,
    isOpen: boolean,
    handleClose: any
};

export default function SuccessDialog({
    title,
    subtext,
    isOpen,
    handleClose
}: Props) {
    return (
        <Dialog
            data-testid="dialog"
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={isOpen}
        >
            <DialogTitle classes={{ root: 'failure-title' }}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText classes={{ root: 'failure-subtext' }}>
                    {subtext}
                </DialogContentText>
            </DialogContent>
            <div className="failure-button-container">
                <PrimaryButton
                    data-testid="dialog-button"
                    onClick={handleClose}
                    classes={{ root: 'failure-button' }}
                >
                    Got it
                </PrimaryButton>
            </div>
        </Dialog>
    );
}
