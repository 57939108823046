// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import CoachSettingsRowTemplate from './CoachSettingsRowTemplate';

const HeadCoachRow = () => {
    const headCoach = useSelector((state: any) => state.user?.headCoach);

    const fullName = `${headCoach.nameFirst} ${headCoach.nameLast}`;

    return (
        <CoachSettingsRowTemplate
            firstColumn={
                <div>
                    {fullName} <span className="italic">(You)</span>
                </div>
            }
            secondColumn={<div>Head Coach</div>}
            thirdColumn={<div>Active</div>}
        />
    );
};

export default HeadCoachRow;
