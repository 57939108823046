import { unauthenticatedFetch } from '../../api/request';

export default async function requestResetPasswordEmail(email: string) {
    return await unauthenticatedFetch(
        '/public/v5/users/account/sendResetPasswordEmail',
        {
            method: 'POST',
            body: JSON.stringify({ email })
        }
    );
}
