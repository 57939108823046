import React from 'react';
import { Box, Card, CardActions, CardHeader, Grid2, styled, Typography } from '@mui/material';
import TertiaryButton from '../../common/components/TertiaryButton';
import CONFIG from '../../api/config';
import useAssistantCoachSettings from '../../coachManagement/components/hooks/useAssistantCoachSettings';

type Props = {
    currentAthletes: number;
    invitedAthletes: number;
    coachCount: number;
};

const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
    marginX: 2,
    textAlign: 'center',
    '& .MuiCardHeader-title': {
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 500,
        letterSpacing: '0.15px',
        lineHeight: '160%'
    }
}));

const ManageButton = styled(TertiaryButton)(({ theme }) => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export default function AthleteCounts({ currentAthletes, invitedAthletes, coachCount }: Props) {
    const { availableLicenseCount } = useAssistantCoachSettings();

    let assistantCoachText = '$9.99 per month';
    if (availableLicenseCount > 0) {
        assistantCoachText = `${availableLicenseCount} remaining licenses`;
    }

    return (
        <Box>
            <Grid2 className="athlete-count-container" gap={1} paddingX={1} width="98%">
                <Card classes={{ root: 'card' }} elevation={2}>
                    <CustomCardHeader title="Attached Athletes" />
                    <Box className="athlete-count">
                        {currentAthletes}
                        <span className="asterisk">*</span>
                    </Box>
                    <Box className="invited-count">Invited Athletes: {invitedAthletes}</Box>
                    <CardActions classes={{ root: 'action-container' }}>
                        <ManageButton href={`${CONFIG.URL_COACHHEROIC}/athletes`}>Manage Athletes</ManageButton>
                    </CardActions>
                </Card>
                <Card classes={{ root: 'card' }} elevation={2}>
                    <CustomCardHeader title="Assistant Coaches" />
                    <Box className="athlete-count">{coachCount}</Box>
                    <Box className="invited-count">{assistantCoachText}</Box>
                    <CardActions classes={{ root: 'action-container' }}>
                        <ManageButton href="/assistant-coaches">Manage Coaches</ManageButton>
                    </CardActions>
                </Card>
            </Grid2>
            <Box paddingX={2} width="48%">
                <Typography variant="subtitle1">
                    *Does not include athletes who purchased from the marketplace.
                </Typography>
            </Box>
        </Box>
    );
}
