import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { Receipt } from './hooks/useReceipt';
import { FEATURES } from '../../account/api/getFeaturesForUserId';
import FeatureFlag from '../../common/components/FeatureFlag';

interface Props {
    receipt: Receipt;
}

export default function ReceiptDetail({ receipt }: Props) {
    const {
        cardType,
        coachLicenseCost,
        customerName,
        email,
        last4Digits,
        numberOfAssistantCoaches,
        org,
        product,
        totalAssistantCoachLicenseCost,
        tax,
        totalCost,
        transactionDate
    } = receipt;
    const formattedTransactionDate = moment(transactionDate).format('MMMM D, YYYY');

    const isPlanTransaction =
        coachLicenseCost !== null && numberOfAssistantCoaches !== null && totalAssistantCoachLicenseCost !== null;

    return (
        <Box paddingX={3} paddingY={2} marginBottom={5} borderRadius={4} bgcolor="#f7f7f7">
            <Box marginBottom={3}>
                <Typography fontWeight={700}>Product Information</Typography>
                <Typography>Organization: {org}</Typography>
            </Box>
            <Box marginBottom={3}>
                <Typography fontWeight={700}>Transaction Information</Typography>
                <Typography>Merchant: TrainHeroic</Typography>
                <Typography>Product: Coach Plan {product}</Typography>
                {isPlanTransaction && (
                    <>
                        <Typography>Coach License Cost: {coachLicenseCost}</Typography>
                        <Typography># of Assistant Coaches: {numberOfAssistantCoaches}</Typography>
                        <Typography>Assistant Coach Licenses Cost: {totalAssistantCoachLicenseCost}</Typography>
                    </>
                )}
                <FeatureFlag feature={FEATURES.AVALARA_TAX_INTEGRATION_V1}>
                    <Typography>Tax: {tax}</Typography>
                </FeatureFlag>
                <Typography>Total Cost: {totalCost}</Typography>
                <Typography>Transaction Date: {formattedTransactionDate}</Typography>
            </Box>
            <Box marginBottom={3}>
                <Typography fontWeight={700}>Payment Information</Typography>
                <Typography>Card Type: {cardType}</Typography>
                <Typography>Credit Card Ends With: {last4Digits}</Typography>
            </Box>
            <Typography fontWeight={700}>Customer Information</Typography>
            <Typography>Name: {customerName}</Typography>
            <Typography>Email: {email}</Typography>
        </Box>
    );
}
