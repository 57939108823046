// @flow
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import TertiaryButton from '../../common/components/TertiaryButton';
import PrimaryButton from '../../common/components/PrimaryButton';

const RemoveDialog = ({ deleteCallback }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        deleteCallback();
    };

    return (
        <div>
            <TertiaryButton style={{ color: 'red' }} onClick={handleClickOpen}>
                Delete
            </TertiaryButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="dialog-title">
                    Remove Zapier Integration
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will disconnect your TrainHeroic account with Zapier and your Zaps will no longer work. Are
                        you sure you want to do this?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
                    <PrimaryButton onClick={handleDelete} autoFocus>
                        Delete
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RemoveDialog;
