// @flow
import { urlFormatter } from '../../api/request';

async function auth(url, body) {
    try {
        let date = new Date();
        let response = await fetch(urlFormatter(`${url}?t=${date.getTime()}`), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        let responseJson;
        try {
            responseJson = await response.json();
        } catch (e) {
            return response;
        }

        return responseJson;
    } catch (e) {
        console.error(e);
        return e;
    }
}

export default async function authenticateTH(email: string, password: string) {
    return await auth('/auth', {
        email,
        password
    });
}
