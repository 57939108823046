// @flow
import { authenticatedFetch } from '../../api/request';

type Invitation = {
    emails: Array<string>,
    message: string
};

const inviteCoachesToOrg = async (orgId: number, invitation: Invitation) => {
    const path = `/v5/orgs/${orgId}/assistantCoaches/invite`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(invitation)
    };
    return await authenticatedFetch(path, requestOptions);
};

export default inviteCoachesToOrg;
