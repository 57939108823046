// @flow
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import processPlanDowngrade from '../../api/processPlanDowngrade';
import useBraintreeDropIn from './useBraintreeDropIn';
import getUser from '../../api/getUser';

export default function usePlanSelection() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [loadPaymentForm, setLoadPaymentForm] = useState(false);
    const [downgradeFailed, setDowngradeFailed] = useState(false);
    const [downgradeSuccess, setDowngradeSuccess] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [numDaysRemainingInTrial, setNumDaysRemainingInTrial] = useState(0);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const {
        setBraintreeInstance,
        isPaymentProcessing,
        setIsPaymentProcessing,
        clientToken,
        isLoading,
        processBraintree,
        paymentSuccess,
        setPaymentSuccess,
        paymentFailed,
        setPaymentFailed,
        errorMessage
    } = useBraintreeDropIn();

    const handleDowngradeFlow = async (newCoachLicenseId: number) => {
        setIsPaymentProcessing(true);

        const response = await processPlanDowngrade(orgId, newCoachLicenseId);

        if (response.status && response.status !== 200) {
            setDowngradeFailed(true);
        } else {
            setDowngradeSuccess(true);
        }

        setIsPaymentProcessing(false);
    };

    useEffect(() =>{
        const fetchUser = async () => {
            const user = await getUser();
            if(user.isTrial){
                setIsTrial(true);
                setNumDaysRemainingInTrial(user.numDaysRemainingInTrial);
            }
        };
        fetchUser();
    }, []);

    return {
        menuOpen,
        setMenuOpen,
        loadPaymentForm,
        setLoadPaymentForm,
        handleDowngradeFlow,
        setBraintreeInstance,
        isPaymentProcessing,
        clientToken,
        isLoading,
        processBraintree,
        paymentSuccess,
        setPaymentSuccess,
        setPaymentFailed,
        paymentFailed,
        downgradeFailed,
        setDowngradeFailed,
        downgradeSuccess,
        setDowngradeSuccess,
        isTrial,
        numDaysRemainingInTrial,
        errorMessage
    };
}
