import { authenticatedFetch } from '../../api/request';

export const FEATURES = {
    MARKETPLACE_COACH_PURCHASE_FLOW_V1: 'marketplace_coach_purchase_flow_v1',
    TEST_FEATURE: 'test_feature',
    AVALARA_TAX_INTEGRATION_V1: 'avalara_tax_integration_v1'
};

export default function getFeaturesForUserId(userId: number) {
    return authenticatedFetch(`/v5/users/${userId}/features`);
}
