// @flow
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

type Props = {
    open: boolean,
    handleClose: () => any,
    title: string
};

export default function ServerErrorAlert({ open, handleClose, title }: Props) {
    return (
        <div>
            <Dialog data-testid="dialog" open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please try again later, we are currently not able to
                        process your request.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
