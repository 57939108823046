import React from 'react';
import { Box, CardActions, CardHeader, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import CardWithBoxShadow from '../../common/components/CardWithBoxShadow';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import FailureDialog from '../../common/components/FailureDialog';
import Loading from '../../common/components/Loading';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import SuccessOrFailureDialog from '../../planManagement/components/SuccessDialog';
import { navigateToCoachHeroic } from '../../state/navigation/navigation';
import BraintreeCard from './BraintreeCard';
import usePaymentInfo from './hooks/usePaymentInfo';

export default function PaymentInfo() {
    const {
        isLoading,
        accountStatus,
        isOpen,
        setIsOpen,
        isLoadingInstance,
        clientToken,
        clientTokenFailed,
        setClientTokenFailed,
        braintreeInstance,
        setBraintreeInstance,
        processPaymentMethodUpdate,
        paymentUpdateCompleted,
        setPaymentUpdateCompleted,
        dialogText,
        errorMessage
    } = usePaymentInfo();

    const { isAccountOverdue, overdueAmount } = accountStatus;

    const isHeadCoach = useSelector((state: any) => state.user.headCoach);

    if (!isHeadCoach) {
        return navigateToCoachHeroic();
    }

    if (isLoading) {
        return <Loading />;
    }

    const getButtonText = () => {
        return isAccountOverdue ? 'Save and Process Payment' : 'Save Card';
    };

    return (
        <Box className="outer-container with-nav">
            <Container maxWidth="lg">
                <Box className="management-header">
                    <header>
                        <h1 className="title">Payment Information</h1>
                    </header>
                </Box>
                <Box className="form-container cancel-plan">
                    <CardWithBoxShadow data-testid="card" classes={{ root: 'form-card card' }}>
                        <CardHeader
                            title="Update your payment method"
                            classes={{
                                root: 'padding-desktop',
                                title: 'card-title'
                            }}
                        />
                        <BraintreeCard
                            isLoadingInstance={isLoadingInstance}
                            clientToken={clientToken}
                            setBraintreeInstance={setBraintreeInstance}
                        />
                        {isAccountOverdue && (
                            <Box className="outstanding-balance padding-desktop padding-bottom-desktop">
                                <p>
                                    You have an outstanding balance of {overdueAmount}. This will be charged to your
                                    card once you update and submit your payment information.
                                </p>
                            </Box>
                        )}
                        {errorMessage && (
                            <Box className="outstanding-balance padding-desktop padding-bottom-desktop">
                                <p>{errorMessage}</p>
                            </Box>
                        )}
                        {!isLoading && !isLoadingInstance && braintreeInstance && (
                            <CardActions classes={{ root: 'action-container' }}>
                                <Box className="action-buttons-container">
                                    <PrimaryButton onClick={() => setIsOpen(true)}>{getButtonText()}</PrimaryButton>
                                </Box>
                                {!isAccountOverdue && (
                                    <Box className="action-buttons-container">
                                        <TertiaryButton onClick={() => braintreeInstance.clearSelectedPaymentMethod()}>
                                            Cancel Changes
                                        </TertiaryButton>
                                    </Box>
                                )}
                            </CardActions>
                        )}
                    </CardWithBoxShadow>
                </Box>
            </Container>
            <ConfirmDialog
                title={'Do you want to update your card?'}
                subtext={'Do you want to set this card as your primary method of payment?'}
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                proceed={() => {
                    processPaymentMethodUpdate();
                    setIsOpen(false);
                }}
                proceedButtonText={'Update Card'}
            />
            <SuccessOrFailureDialog
                title={dialogText.title}
                subtext={dialogText.subtext}
                isOpen={paymentUpdateCompleted}
                handleClose={() => setPaymentUpdateCompleted(false)}
            />
            <FailureDialog
                title={dialogText.title}
                subtext={dialogText.subtext}
                isOpen={clientTokenFailed}
                handleClose={() => setClientTokenFailed(false)}
            />
        </Box>
    );
}
