// @flow
import { useCallback, useState } from 'react';

export default function useFormManager() {
    const [showValidationErrors, setShowValidationErrors] = useState(false);

    const notNullValidator = useCallback(
        (text: string) => (text.length === 0 ? 'Missing required entry' : null),
        []
    );

    return {
        showValidationErrors,
        setShowValidationErrors,
        notNullValidator
    };
}
