import React from 'react';
import { Box } from '@mui/material';
import PaymentPage from './PaymentPage';
import PlanSelectionPage from './PlanSelectionPage';
import useSubscriptionPlanCheckout from './hooks/useSubscriptionPlanCheckout';
import CheckoutStepper from './CheckoutStepper';
import ReviewPurchasePage from './ReviewPurchasePage';
import Footer from '../../common/components/Footer';
import Checkout from './Checkout';

export default function SubscriptionPlansCheckout() {
    const {
        handlePaymentPreview,
        maxAthleteCount,
        planPreview,
        isLoadingSubscriptionOptions,
        subscriptionOptions,
        currentPlan,
        selectedPlan,
        setSelectedPlan,
        hasErrorLoadingOptions,
        setHasErrorLoadingOptions,
        clientToken,
        isLoadingBraintreeDropIn,
        setBraintreeInstance,
        steps,
        activeStep,
        handleBack,
        handleReviewPurchase,
        isCreatePaymentMethodLoading
    } = useSubscriptionPlanCheckout();

    return (
        <>
            <Box className="outer-container with-nav">
                <Box marginTop={8} alignSelf="center">
                    <CheckoutStepper steps={steps} activeStep={activeStep} />
                </Box>
                <Checkout selectedStep={activeStep}>
                    <PlanSelectionPage
                        handlePaymentPreview={handlePaymentPreview}
                        currentPlan={currentPlan}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        hasErrorLoadingOptions={hasErrorLoadingOptions}
                        setHasErrorLoadingOptions={setHasErrorLoadingOptions}
                        subscriptionOptions={subscriptionOptions}
                        isLoading={isLoadingSubscriptionOptions}
                    />
                    <PaymentPage
                        maxAthleteCount={maxAthleteCount}
                        planPreview={planPreview}
                        clientToken={clientToken}
                        isLoading={isLoadingBraintreeDropIn}
                        setBraintreeInstance={setBraintreeInstance}
                        handleReviewPurchase={handleReviewPurchase}
                        isCreatePaymentMethodLoading={isCreatePaymentMethodLoading}
                        handleBack={handleBack}
                    />
                    <ReviewPurchasePage
                        maxAthleteCount={maxAthleteCount}
                        planPreview={planPreview}
                        isLoading={isLoadingBraintreeDropIn}
                    />
                </Checkout>
            </Box>
            <Box marginTop={10} alignSelf="center">
                <Footer />
            </Box>
        </>
    );
}
