import React from 'react';
import { CardHeader, CardContent, styled, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { UpgradePreview } from '../types';

const Row = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    paddingRight: 8,
    paddingLeft: 8
});
const ItemLabel = styled(Typography)({ flex: 1 });
const Price = styled(Typography)({ flex: 1, textAlign: 'right' });

const BoldLabel = styled(ItemLabel)({ fontWeight: 700 });
const BoldPrice = styled(Price)({ fontWeight: 700 });

const Divider = styled(Box)({
    height: '0px',
    border: 1,
    borderColor: '#EEE',
    borderStyle: 'solid',
    flexShrink: 0,
    marginTop: 20,
    marginBottom: 20,
    marginRight: 8,
    marginLeft: 8
});

interface Props {
    maxAthleteCount: number;
    planPreview: UpgradePreview;
    showHeader?: boolean;
}

export default function PaymentSummary({ maxAthleteCount, planPreview, showHeader = true }: Props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {
        coachLicenseAmount,
        proratedCoachLicenseAmount,
        assistantCoachLicenseCount,
        assistantCoachLicenseAmount,
        subtotalDueNextBillingDate,
        totalDueNextBillingDate,
        totalDueToday
    } = planPreview;

    const nextBillingDate = useSelector((state: any) => {
        const date = moment(state.user.headCoach?.nextBillingDate);
        return date.isValid() ? date.format('MMMM Do, YYYY') : null;
    });

    return (
        <>
            {showHeader && (
                <CardHeader
                    title="Summary"
                    classes={{
                        root: 'padding-desktop'
                    }}
                    slotProps={{ title: { variant: 'h5' } }}
                />
            )}
            <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <Row data-testid="athletes-range-row">
                    <Box flexDirection="column" display="flex" flex={1}>
                        <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between">
                            <ItemLabel variant="subtitle2">{`Up to ${maxAthleteCount} Athletes`}</ItemLabel>
                            <Price variant="subtitle2">{`$${coachLicenseAmount}/month`}</Price>
                        </Box>
                        {proratedCoachLicenseAmount > 0 && (
                            <Typography variant="caption" display="flex" sx={{ textWrap: 'pretty' }}>
                                {`You will be charged an additional $${proratedCoachLicenseAmount.toFixed(
                                    2
                                )} plus taxes for this upgrade for the remainder of this billing cycle.`}
                            </Typography>
                        )}
                    </Box>
                </Row>
                <Divider />
                {assistantCoachLicenseCount > 0 && (
                    <>
                        <Row data-testid="assistant-coaches-row">
                            <ItemLabel variant="subtitle2">{`${assistantCoachLicenseCount} Assistant ${
                                assistantCoachLicenseCount === 1 ? 'Coach' : 'Coaches'
                            }`}</ItemLabel>
                            <Price variant="subtitle2">{`$${assistantCoachLicenseAmount}/month`}</Price>
                        </Row>
                        <Divider />
                        <Row data-testid="subtotal-row">
                            <ItemLabel variant="subtitle2">{'Subtotal'}</ItemLabel>
                            <Price variant="subtitle2">{`$${subtotalDueNextBillingDate}/month`}</Price>
                        </Row>
                        <Divider />
                    </>
                )}
                <Row data-testid="total-row">
                    <BoldLabel variant="subtitle2">{'Total'}</BoldLabel>
                    <BoldPrice variant="subtitle2">{`$${totalDueToday.toFixed(2)}`}</BoldPrice>
                </Row>
                {proratedCoachLicenseAmount > 0 && (
                    <>
                        <Divider />
                        <Row data-testid="total-next-billing-cycle-row">
                            <Box flexDirection="column" display="flex" flex={1}>
                                <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between">
                                    <ItemLabel variant="subtitle2">{'Total Due Next Billing Cycle'}</ItemLabel>
                                    <Price variant="subtitle2">{`$${totalDueNextBillingDate}`}</Price>
                                </Box>
                                {proratedCoachLicenseAmount > 0 && (
                                    <Typography variant="caption" display="flex" alignSelf="flex-end" textAlign="right">
                                        {`Starting ${nextBillingDate}`}
                                    </Typography>
                                )}
                            </Box>
                        </Row>
                    </>
                )}
                {isSmallScreen && <Divider />}
            </CardContent>
        </>
    );
}
