// @flow
import React, { useEffect } from 'react';
import {
    Container,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    CardMedia
} from '@mui/material';
import useAcceptAssistantCoachInvite, {
    RESPONSE_TYPES
} from './hooks/useAcceptAssistantCoachInvite';
import { compareDeviceWidth } from '../../common/utils/deviceSizes';
import DownloadButtonGroup from '../../common/components/DownloadButtonGroup';
import PrimaryButton from '../../common/components/PrimaryButton';
import AssistantCoachLoginCreateAccount from './AssistantCoachLoginCreateAccount';
import useAuthentication from '../../account/components/hooks/useAuthentication';

export default function AcceptAssistantCoachInvite() {
    const {
        isAuthenticated,
        isLoading,
        response,
        width,
        redirectToCoach,
        displaySpecialText
    } = useAcceptAssistantCoachInvite();

    const { checkTokenPublic, logoutUser } = useAuthentication();
    const { isGreaterThan370, isGreaterThan540 } = compareDeviceWidth(width);
    const phoneMaskScreen = isGreaterThan540 ? 'Desktop' : 'Mobile';

    useEffect(() => {
        checkTokenPublic();
    }, [checkTokenPublic]);

    if (isLoading) {
        return null;
    }

    if (!isAuthenticated && displaySpecialText) {
        const specialText =
            'Please create a new account with a different email address to accept this assistant coach invitation.';
        return <AssistantCoachLoginCreateAccount instructions={specialText} />;
    }

    if (!isAuthenticated) {
        return <AssistantCoachLoginCreateAccount />;
    }

    if (!isLoading && response.status === RESPONSE_TYPES.INVALID_ACCOUNT) {
        return logoutUser();
    }

    if (!isLoading && response.status === RESPONSE_TYPES.INVALID_TOKEN) {
        return (
            <Container maxWidth="lg" classes={{ root: 'header-space' }}>
                <div className="order-container">{response.message}</div>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" classes={{ root: 'header-space' }}>
            <div className="order-container">
                <Card
                    data-testid="card"
                    classes={{
                        root: 'card'
                    }}
                >
                    <CardHeader
                        title="Success!"
                        subheader={`You are now connected to ${response.orgName}`}
                        classes={{
                            root: 'padding-desktop',
                            content: 'divider',
                            title: 'card-title',
                            subheader: 'card-subtext black-subtext spacing'
                        }}
                    />
                    <CardContent
                        classes={{
                            root: 'padding-desktop card-content'
                        }}
                    >
                        <div className="order-summary divider">
                            <ul className="order-detail-list">
                                <li>
                                    <div className="subscription-detail">
                                        <p>
                                            Your account is setup and ready to
                                            go! <br />
                                            <br />
                                            Download the app or use our Desktop
                                            experience to start coaching.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {isGreaterThan540 && (
                            <div
                                className="order-total"
                                style={{ padding: 20 }}
                            >
                                <PrimaryButton onClick={redirectToCoach}>
                                    Click to Start Coaching
                                </PrimaryButton>
                            </div>
                        )}
                    </CardContent>
                </Card>

                <Card data-testid="card" className="card">
                    <div className="download-card">
                        <CardHeader
                            title="Download the TrainHeroic&nbsp;app"
                            subheader="You can create sessions, and check in on your athletes right from your phone"
                            classes={{
                                root: 'download-header',
                                title: 'card-title download-color',
                                subheader: 'card-subtext download-color'
                            }}
                        />
                        <CardMedia
                            image={require(`../assets/BannerImage${phoneMaskScreen}.jpg`)}
                            classes={{ root: 'download' }}
                        />
                    </div>
                    <CardActions
                        disableSpacing={!isGreaterThan370}
                        classes={{ root: 'actions-container' }}
                    >
                        <DownloadButtonGroup />
                    </CardActions>
                </Card>
            </div>
        </Container>
    );
}
