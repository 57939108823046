import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getBraintreeClientToken from '../../api/getBraintreeClientToken';
import processPlanUpgrade from '../../api/processPlanUpgrade';
import createPaymentMethodApi from '../../api/createPaymentMethod';

export default function useBraintreeDropIn() {
    const [clientToken, setClientToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [isCreatePaymentMethodLoading, setIsCreatePaymentMethodLoading] = useState(false);
    const [hasCreatePaymentMethodFailure, setHasCreatePaymentMethodFailure] = useState(false);
    const [braintreeInstance, setBraintreeInstance] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const processBraintree = async (newCoachLicenseId: number) => {
        if (!braintreeInstance) {
            return;
        }

        setIsPaymentProcessing(true);
        setErrorMessage(null);
        braintreeInstance.requestPaymentMethod(async (error: string, payload: any) => {
            if (error) {
                setIsPaymentProcessing(false);
                setErrorMessage('Credit card is invalid');
            } else {
                const nonce = payload.nonce;
                await fetch(`server.test/purchase/${nonce}`);
                const response = await processPlanUpgrade(nonce, orgId, newCoachLicenseId);

                if (response.status && response.status !== 200) {
                    setPaymentFailed(true);
                } else {
                    setPaymentSuccess(true);
                    setPaymentFailed(false);
                }

                setIsPaymentProcessing(false);
            }
        });
    };

    const createPaymentMethod = async (): Promise<string | void> => {
        if (!braintreeInstance) {
            return;
        }

        setIsCreatePaymentMethodLoading(true);
        setErrorMessage(null);

        try {
            const { nonce } = await braintreeInstance.requestPaymentMethod();
            const response = await createPaymentMethodApi(orgId, nonce);

            setPaymentMethodId(response.paymentMethodId);
            setIsCreatePaymentMethodLoading(false);

            return response.paymentMethodId;
        } catch (e) {
            setHasCreatePaymentMethodFailure(true);
        }
    };

    useEffect(() => {
        const fetchToken = async () => {
            const response = await getBraintreeClientToken();

            if (response?.token) {
                setClientToken(response.token);
            }

            setIsLoading(false);
        };

        fetchToken();
    }, []);

    return {
        setBraintreeInstance,
        isPaymentProcessing,
        setIsPaymentProcessing,
        clientToken,
        isLoading,
        processBraintree,
        paymentSuccess,
        setPaymentSuccess,
        isCreatePaymentMethodLoading,
        hasCreatePaymentMethodFailure,
        createPaymentMethod,
        paymentMethodId,
        paymentFailed,
        setPaymentFailed,
        errorMessage
    };
}
