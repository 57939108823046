import React from 'react';
import { Box, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

type Props = {
    id: number;
    date: string;
    totalTransactionAmount: string;
    last4: string;
    isRefund: boolean;
    item: string;
};

const SUPPORTED_DETAILED_RECEIPT_TYPES = ['plan', 'plan upgraded'];

export default function InvoiceRow({ id, date, totalTransactionAmount, item, last4, isRefund }: Props) {
    const validateIsDetailedReceiptType = (item: string) => {
        return item && SUPPORTED_DETAILED_RECEIPT_TYPES.includes(item.toLowerCase());
    };

    const match = useRouteMatch();
    const formattedDate = moment(date).format('MMMM D, YYYY');
    const isDetailedReceiptType = validateIsDetailedReceiptType(item);

    return (
        <Box className="settings-row">
            <Box className="settings-left">
                <Box className="description-column">
                    <Typography>{formattedDate}</Typography>
                </Box>
                <Box className="description-column">
                    <Typography>{isRefund ? `-$${totalTransactionAmount}` : `$${totalTransactionAmount}`}</Typography>
                </Box>
            </Box>
            <Box className="settings-left">
                <Box className="description-column">
                    <Typography>{isRefund ? 'REFUND' : item}</Typography>
                </Box>
                <Box className="description-column">
                    <Typography>{isRefund ? '-' : last4}</Typography>
                </Box>
            </Box>
            <Box className="settings-right">
                <Box className="description-column">
                    {isDetailedReceiptType ? (
                        <Link className="invoice-date" to={`${match.url}/invoice/${id}`}>
                            <Visibility className="link-icon" />
                        </Link>
                    ) : (
                        <Box className="invoice-not-supported-type" data-testid="invoice-not-supported-type-empty" />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
