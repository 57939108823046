// @flow
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getAllCoaches from '../../api/getAllCoaches';
import deleteCoach from '../../api/deleteCoach';
import resendInvitation from '../../api/resendInvitation';
import deleteInvitation from '../../api/deleteInvitation';

const DELETE_COACH_FAILURE = 'Failure to remove coach.';
const INVITE_RESEND_SUCCESS = 'Invite resent successfully.';
const INVITE_RESEND_FAILURE = 'Failure to resend invitation.';
const INVITE_SUBTEXT = 'Please try again later.';

export default function useAssistantCoachSettings() {
    const [coachList, setCoachList] = useState([]);
    const [availableLicenseCount, setAvailableLicenseCount] = useState(0);
    const [hasAvailableLicenses, setHasAvailableLicenses] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalSubtext, setModalSubtext] = useState(null);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    const fetchCoachList = useCallback(async () => {
        if (!orgId) {
            return;
        }

        setIsLoading(true);

        const response = await getAllCoaches(orgId);

        if (response.status) {
            // error
            console.log({ response });
        }

        let count = 0;
        for (let i = 0; i < response.length; i++) {
            if (response[i].isAvailable) {
                count += 1;
            }
        }
        setAvailableLicenseCount(count);
        const hasAvailable = count > 0;
        setHasAvailableLicenses(hasAvailable);
        setCoachList(response);
        setIsLoading(false);
    }, [orgId]);

    useEffect(() => {
        fetchCoachList();
    }, [orgId, fetchCoachList]);

    const removeCoach = async (coachId: number) => {
        const response = await deleteCoach(orgId, coachId);

        if (response.status && response.status !== 200) {
            setModalTitle(DELETE_COACH_FAILURE);
            setModalSubtext(INVITE_SUBTEXT);
            setIsOpen(true);
        }

        return fetchCoachList();
    };

    const resendInvite = async (inviteId: number) => {
        const response = await resendInvitation(orgId, inviteId);

        if (response.status && response.status !== 200) {
            setModalTitle(INVITE_RESEND_FAILURE);
            setModalSubtext(INVITE_SUBTEXT);
        } else {
            setModalTitle(INVITE_RESEND_SUCCESS);
        }

        setIsOpen(true);
        return fetchCoachList();
    };

    const deleteInvite = async (inviteId: number) => {
        const response = await deleteInvitation(orgId, inviteId);
        return fetchCoachList();
    };

    return {
        isLoading,
        coachList,
        hasAvailableLicenses,
        availableLicenseCount,
        removeCoach,
        resendInvite,
        deleteInvite,
        fetchCoachList,
        modalTitle,
        modalSubtext,
        isOpen,
        setIsOpen
    };
}
