// @flow
import React from 'react';
import {
    Container,
    CardActions,
} from '@mui/material';
import moment from 'moment';
import PrimaryButton from '../../common/components/PrimaryButton';
import useGrantParentalConsent from '../hooks/useGrantParentalConsent';
import FormTextField from '../../common/components/FormTextField';
import TermsOfUseCheckbox from './TermsOfUseCheckbox';
import DigitalSignatureCheckbox from './DigitalSignatureCheckbox';
import GrantParentalConsentFooter from './GrantParentalConsentFooter';
import GrantParentalConsentBody from './GrantParentalConsentBody';
import DisabledDateOfBirthInput from './DisabledDateOfBirthInput';
import SuccessAlert from './SuccessAlert';
import AlreadyHasConsentAlert from './AlreadyHasConsentAlert';
import ServerErrorAlert from '../../common/components/ServerErrorAlert';

export default function GrantParentalConsent() {
    const {
        isLoading,
        minorInformation,
        firstName,
        setFirstName,
        firstNameValidated,
        firstNameErrorMessage,
        lastName,
        setLastName,
        lastNameValidated,
        lastNameErrorMessage,
        relationship,
        setRelationship,
        relationshipValidated,
        relationshipErrorMessage,
        hasAgreedToTerms,
        setHasAgreedToTerms,
        hasSigned,
        setHasSigned,
        showValidationErrors,
        isInProcess,
        onSubmit,
        successfulOrNotSubmitted,
        serverErrorMessages,
        serverError,
        setServerError,
        handleEnterKey,
        isInvalidAlertOpen,
        isSuccessAlertOpen
    } = useGrantParentalConsent();

    const hasFirstNameError = showValidationErrors && !firstNameValidated;
    const hasLastNameError = showValidationErrors && !lastNameValidated;
    const hasRelationshipError = showValidationErrors && !relationshipValidated;

    const firstNameErrorText = successfulOrNotSubmitted
        ? firstNameErrorMessage
        : serverErrorMessages.firstName;
    const lastNameErrorText = successfulOrNotSubmitted
        ? lastNameErrorMessage
        : serverErrorMessages.lastName;
    const relationshipErrorText = successfulOrNotSubmitted
        ? relationshipErrorMessage
        : serverErrorMessages.relationship;

    if (isLoading) {
        return null;
    }

    return (
        <Container maxWidth="lg" classes={{ root: 'header-space' }}>
            <div className="form-container">
                <div className="h4">
                    TrainHeroic Accounts for Children
                </div>
                <GrantParentalConsentBody />
                <div className="h5">
                    {`Verifiable Parental Consent for ${minorInformation.firstName} ${minorInformation.lastName}`}
                </div>
                <div className="overline todays-date">
                    {moment(new Date().toString()).format('ll')}
                </div>
                <form className="">
                    <div className="parent-form-input-row">
                        <div className="form-input-row-item form-input-row-item-left">
                            <FormTextField
                                onKeyDown={event =>
                                    handleEnterKey(event)
                                }
                                required={true}
                                type="text"
                                label="Parent or guardian’s first name"
                                value={firstName}
                                onChange={event => setFirstName(event.target.value)}
                                error={hasFirstNameError}
                                helperText={hasFirstNameError ? firstNameErrorText : ''}
                                variant="outlined"
                            />
                        </div>
                        <div className="form-input-row-item">
                            <FormTextField
                                onKeyDown={event =>
                                    handleEnterKey(event)
                                }
                                required={true}
                                type="text"
                                label="Parent or guardian’s last name"
                                value={lastName}
                                onChange={event => setLastName(event.target.value)}
                                error={hasLastNameError}
                                helperText={hasLastNameError ? lastNameErrorText : ''}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className="form-input-item">
                        <FormTextField
                            onKeyDown={event =>
                                handleEnterKey(event)
                            }
                            required={true}
                            type="text"
                            label="Relationship to child"
                            value={relationship}
                            onChange={event =>
                                setRelationship(event.target.value)
                            }
                            error={hasRelationshipError}
                            helperText={
                                hasRelationshipError ? relationshipErrorText : ''
                            }
                            variant="outlined"
                        />
                    </div>
                    <div className="form-input-item">
                        <FormTextField
                            disabled
                            type="text"
                            label="Child’s login email"
                            value={minorInformation.email}
                            variant="outlined"
                        />
                    </div>
                    <DisabledDateOfBirthInput dateOfBirth={minorInformation.dateOfBirth}/>
                    <GrantParentalConsentFooter />
                    <TermsOfUseCheckbox
                        hasAgreed={hasAgreedToTerms}
                        hasAgreedError={showValidationErrors && !hasAgreedToTerms}
                        onChange={() => setHasAgreedToTerms(!hasAgreedToTerms)}
                    />
                    <DigitalSignatureCheckbox
                        hasSigned={hasSigned}
                        hasSignedError={showValidationErrors && !hasSigned}
                        onChange={() => setHasSigned(!hasSigned)}
                    />
                </form>
                <CardActions classes={{ root: 'action-container' }}>
                    <div className="action-buttons-container">
                        <PrimaryButton
                            showActivity={isInProcess}
                            onClick={onSubmit}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </CardActions>
            </div>
            <SuccessAlert isOpen={isSuccessAlertOpen} />
            <AlreadyHasConsentAlert isOpen={isInvalidAlertOpen} />
            <ServerErrorAlert
                title="There was an error submitting your form."
                open={serverError}
                handleClose={() => setServerError(false)}
            />
        </Container>
    );
}
