// @flow
import ACTIONS from './constants';

const authentication = (
    state: any = { isAuthenticated: false, token: null },
    action: any = {}
) => {
    switch (action.type) {
        case ACTIONS.AUTHENTICATION_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                token: null
            };
        case ACTIONS.AUTHENTICATION_SET_SESSION_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case ACTIONS.AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            };
        case ACTIONS.LOGOUT_USER:
            return {
                ...state,
                isAuthenticated: false,
                token: null
            };
        default:
            return state;
    }
};

export default authentication;
