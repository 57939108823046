import React from 'react';
import { Box, Typography } from '@mui/material';
import BlackTHLogo from '../../common/assets/BlackTHLogo';

export default function ReceiptHeader() {
    return (
        <Box marginBottom={2} display="flex" flexDirection="column" alignItems="center">
            <Box marginBottom={2}>
                <BlackTHLogo height={50} />
            </Box>
            <Typography variant="h3" textAlign="center">
                Receipt from TrainHeroic
            </Typography>
        </Box>
    );
}
