// @flow
import React from 'react';
import { Container, Card, CardHeader, CardActions, Box } from '@mui/material';
import FormTextField from '../../common/components/FormTextField';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import useLoginFormManager from './hooks/useLoginFormManager';
import { navigate } from '../../state/navigation/navigation';

const AUTHENTICATION_FAILURE_MESSAGE = 'Email or password is incorrect';

interface Props {
    navigateToAccount: () => void;
    navigateToPasswordReset: () => void;
    onLogin: () => void;
}

export default function Login({ navigateToAccount, navigateToPasswordReset, onLogin }: Props) {
    const {
        email,
        setEmail,
        emailValidated,
        emailErrorMessage,
        password,
        setPassword,
        passwordValidated,
        passwordErrorMessage,
        authenticateWithForm,
        showValidationErrors,
        isInProgress,
        authenticated,
        authFormSuccessfulOrNotSubmitted
    } = useLoginFormManager();

    const navToAccount = navigateToAccount || (() => navigate('/create-account'));
    const navToPasswordReset = navigateToPasswordReset || (() => navigate('/forgot-password'));
    const hasEmailError = showValidationErrors && !emailValidated;
    const hasPasswordError = showValidationErrors && !passwordValidated;

    const emailErrorText = authFormSuccessfulOrNotSubmitted ? emailErrorMessage : null;
    const passwordErrorText = authFormSuccessfulOrNotSubmitted ? passwordErrorMessage : AUTHENTICATION_FAILURE_MESSAGE;

    const handleLoginViaEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const enterKeyPressed = event.key === 'Enter' || event.keyCode === 13 || event.which === 13;

        if (enterKeyPressed) {
            authenticateWithForm();
        }
    };

    if (authenticated && onLogin) {
        onLogin();
    } else if (authenticated) {
        navigate('/assistant-coaches');
        return null;
    }

    const inputSlotProps = {
        inputLabel: { style: { fontSize: '16px' } }
    };

    return (
        <Box className="outer-container header-space">
            <Container maxWidth="lg">
                <Box className="form-container">
                    <Card data-testid="card" classes={{ root: 'form-card card' }}>
                        <CardHeader
                            title="Login"
                            classes={{
                                root: 'padding-desktop',
                                title: 'card-title'
                            }}
                        />
                        <form className="form-padding padding-desktop">
                            <Box className="form-input" paddingTop={1}>
                                <FormTextField
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                                        handleLoginViaEnterKey(event)
                                    }
                                    required={true}
                                    type="email"
                                    label="Email"
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setEmail(event?.target?.value)
                                    }
                                    error={hasEmailError}
                                    helperText={hasEmailError ? emailErrorText : ''}
                                    slotProps={inputSlotProps}
                                />
                            </Box>
                            <Box className="form-input">
                                <FormTextField
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                                        handleLoginViaEnterKey(event)
                                    }
                                    required={true}
                                    type="password"
                                    label="Password"
                                    value={password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setPassword(event.target.value)
                                    }
                                    error={hasPasswordError}
                                    helperText={hasPasswordError ? passwordErrorText : ''}
                                    slotProps={inputSlotProps}
                                />
                            </Box>
                        </form>
                        <CardActions classes={{ root: 'action-container' }}>
                            <Box className="action-buttons-container">
                                <PrimaryButton showActivity={isInProgress} onClick={authenticateWithForm}>
                                    Log in
                                </PrimaryButton>
                            </Box>
                            <Box className="action-buttons-container">
                                <TertiaryButton onClick={navToPasswordReset}>Forgot Password</TertiaryButton>
                            </Box>
                            <Box className="horizontal-divider">
                                <Box className="divider-border" />
                                <Box className="divider-text">or</Box>
                                <Box className="divider-border" />
                            </Box>
                            <Box className="action-buttons-container button-margin">
                                <TertiaryButton onClick={navToAccount}>Create Account</TertiaryButton>
                            </Box>
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </Box>
    );
}
