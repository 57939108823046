// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import PrimaryButton from '../../common/components/PrimaryButton';
import TertiaryButton from '../../common/components/TertiaryButton';
import FailureDialog from '../../common/components/FailureDialog';
import cancelSubscription from '../api/cancelSubscription';
import { navigate, navigateToCoachHeroic } from '../../state/navigation/navigation';
import CONFIG from '../../api/config';

export default function CancelPlan() {
    const [isInProgress, setIsInProgress] = useState(false);
    const [planCancelled, setPlanCancelled] = useState(false);
    const [cancelFailed, setCancelFailed] = useState(false);

    const { nextBillingDate } = useSelector((state: any) => state.router.location.state);

    const orgId = useSelector((state: any) => state.user.headCoach?.orgId);

    if (!orgId) {
        return navigateToCoachHeroic();
    }

    let billingDate = null;
    if (nextBillingDate) {
        billingDate = moment(nextBillingDate).format('MMM Do, YYYY');
    }

    const cancelPlan = async () => {
        setIsInProgress(true);
        const response = await cancelSubscription(orgId);

        if (response?.status && response.status !== 200) {
            setCancelFailed(true);
        } else {
            setPlanCancelled(true);
        }

        setIsInProgress(false);
    };

    return (
        <div className="outer-container with-nav">
            <Container maxWidth="lg">
                <div className="back-action">
                    <TertiaryButton onClick={() => navigate('/subscription-plans')}>
                        <ChevronLeft color="primary" />
                        Back to Subscription Plans
                    </TertiaryButton>
                </div>
                {planCancelled ? (
                    <div className="form-container cancel-plan">
                        <Card data-testid="card" classes={{ root: 'card' }}>
                            <CardHeader
                                title="Your plan has been cancelled"
                                classes={{
                                    root: 'padding-desktop',
                                    title: 'card-title'
                                }}
                            />
                            <CardContent>
                                <div className="success-content">
                                    <p>
                                        If this has been done unintentionally, <br />
                                        please contact support at{' '}
                                        <a href="mailto:support@trainheroic.com">support@trainheroic.com</a>
                                    </p>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                ) : (
                    <div className="form-container cancel-plan">
                        <Card data-testid="card" classes={{ root: 'card' }}>
                            <CardHeader
                                title="Cancel Paid Plan"
                                classes={{
                                    root: 'padding-desktop',
                                    title: 'card-title'
                                }}
                            />
                            <CardContent
                                classes={{
                                    root: 'padding-desktop card-content'
                                }}
                            >
                                <div className="cancel-content">
                                    <p>
                                        We’re sorry to see you go. This will downgrade you to a cancelled&nbsp;license
                                        at the end of your current billing&nbsp;cycle
                                        {billingDate
                                            ? ` on 
                                        ${billingDate}`
                                            : ''}
                                        .
                                    </p>
                                </div>
                                <div className="cancel-content">
                                    <p>You will lose the ability to:</p>
                                    <ul className="lost-access">
                                        <li>Publish programming for athletes</li>
                                        <li>Message with athletes</li>
                                        <li>Review athlete video and training results</li>
                                    </ul>
                                </div>
                            </CardContent>
                            <CardActions classes={{ root: 'action-container' }}>
                                <div className="action-buttons-container">
                                    <PrimaryButton showActivity={isInProgress} onClick={cancelPlan}>
                                        Yes, Cancel My Plan
                                    </PrimaryButton>
                                </div>
                                <div className="action-buttons-container">
                                    <TertiaryButton onClick={() => navigate('/subscription-plans')}>
                                        No, I don’t want to cancel
                                    </TertiaryButton>
                                </div>
                            </CardActions>
                        </Card>
                    </div>
                )}
            </Container>
            <FailureDialog
                title="Failed to Cancel Plan"
                subtext={
                    <div>
                        <p>
                            Please try again later or
                            <span>
                                <a href={CONFIG.URL_SUPPORT} className="contact-support">
                                    {' '}
                                    contact support{' '}
                                </a>
                            </span>
                            with questions.
                        </p>
                    </div>
                }
                isOpen={cancelFailed}
                handleClose={() => {
                    setCancelFailed(false);
                    return navigate('/subscription-plans');
                }}
            />
        </div>
    );
}
