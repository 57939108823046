// @flow
import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import PrimaryButton from '../../common/components/PrimaryButton';
import useZapierTokens from '../hooks/useZapierTokens';
import TertiaryButton from '../../common/components/TertiaryButton';
import RemoveDialog from './RemoveDialog';

export default function ZapierTokenRow() {
    const { removeZapierToken, zapierTokens, generateZapierToken } = useZapierTokens();
    const [toastIsOpen, setToastIsOpen] = useState(false);
    let tokenEdit;
    if (zapierTokens.length === 0) {
        tokenEdit = <PrimaryButton onClick={generateZapierToken}>Generate Zapier Magic Pass Key</PrimaryButton>;
    } else {
        const zapierToken = zapierTokens[0];

        const copyToClipboard = () => {
            const tempField = document.createElement('textarea');
            tempField.innerText = zapierToken.token;
            document.body.appendChild(tempField);
            tempField.select();
            document.execCommand('copy');
            tempField.remove();
            setToastIsOpen(true);
        };

        tokenEdit = (
            <div className="integration-active-token">
                <div className="token">
                    <div className="token-title">Copy and Paste Magic Pass Key</div>
                    <div>{zapierToken.token}</div>
                </div>
                <TertiaryButton onClick={copyToClipboard}>Copy</TertiaryButton>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={toastIsOpen}
                    autoHideDuration={3000}
                    onClose={() => setToastIsOpen(false)}
                    message="Magic Pass Copied!!"
                />
                <RemoveDialog
                    deleteCallback={() => {
                        removeZapierToken(zapierToken);
                    }}
                />
            </div>
        );
    }

    return (
        <div className="integration-block">
            <span className="integration-title">Automate workflows by connecting with Zapier</span>
            <p className="integration-about">
                When certain events happen in TrainHeroic, we&apos;ll send that info to Zapier. There, you can automate
                emails (and more!) to make tedious tasks a thing of the past.
                {/*See our{' '}<a href="https://www.trainheroic.com">Zapier Guide</a> for more help getting started.*/}
            </p>
            {tokenEdit}
            <TertiaryButton href="https://zapier.com/apps/trainheroic/integrations">Go to Zapier</TertiaryButton>
        </div>
    );
}
