// @flow
import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import PrimaryButton from '../../common/components/PrimaryButton';
import useScreenDimensions from '../../common/hooks/useScreenDimensions';
import { compareDeviceWidth } from '../../common/utils/deviceSizes';

type Props = {
    title: string,
    subtext?: string | null,
    isOpen: boolean,
    handleClose: () => {}
};

export default function RemoveCoachModal({
    title,
    subtext,
    isOpen,
    handleClose
}: Props) {
    const { width } = useScreenDimensions();
    const { isGreaterThan675 } = compareDeviceWidth(width);

    return (
        <Dialog
            fullScreen={!isGreaterThan675}
            maxWidth="sm"
            data-testid="dialog"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            open={isOpen}
        >
            <DialogTitle>
                <h2 className="invite-title">{title}</h2>
            </DialogTitle>
            {subtext && (
                <DialogContent>
                    <DialogContentText>{subtext}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <PrimaryButton onClick={handleClose}>OK</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}
