// @flow
import React from 'react';
import Button from '@mui/material/Button';

export default function SecondaryButton(props: any) {
    return (
        <Button rel="noopener noreferrer" variant="contained" color="secondary" {...props}>
            {props.children}
        </Button>
    );
}
