// @flow
import React from 'react';
import FormTextField from '../../common/components/FormTextField';

interface DateOfBirthInputProps {
    dateOfBirth: string;
}

export default function DisabledDateOfBirthInput({
    dateOfBirth
}: DateOfBirthInputProps) {
    const dateParts = dateOfBirth?.split('-');

    return (
        <div>
            <div className="h6">Child's date of birth</div>
            <div className="date-of-birth-form-input-row">
                <div className="form-input-row-item">
                    <FormTextField
                        disabled
                        type="text"
                        label="MM"
                        value={dateParts ? dateParts[1]: ''}
                        variant="outlined"
                    />
                </div>
                <div className="form-input-row-item form-input-row-item-middle">
                    <FormTextField
                        disabled
                        type="text"
                        label="DD"
                        value={dateParts ? dateParts[2]: ''}
                        variant="outlined"
                    />
                </div>
                <div className="form-input-row-item">
                    <FormTextField
                        disabled
                        type="text"
                        label="YYYY"
                        value={dateParts ? dateParts[0]: ''}
                        variant="outlined"
                    />
                </div>
            </div>
        </div>
    );
}
